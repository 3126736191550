import React, { useState, useEffect, useRef, useCallback, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import genericStyles from '../GenericComponents/GenericStyles.module.css';
import styles from './MobileBrowseValues.module.css';
import { generateQuery } from '../CC/utils';
import { useCCContext } from '../CC/CCContext';
import GenericCheckbox from '../GenericComponents/GenericCheckbox';
import GenericIconButton from '../GenericComponents/GenericIconButton';
import * as ccUtils from '../CC/utils';

interface MobileBrowseValueProps {
  field?: string;
  sort?: 'alpha' | 'count';
  onSelect?: (values: string[]) => void;
  searchText: string;
  onValueSelected: (value: string) => void;
}

interface MobileBrowseValueDataItem {
  value: string;
  count: string;
}

const MobileBrowseValues: React.FC<MobileBrowseValueProps> = ({
  field = "",
  sort = 'count',
  onSelect = undefined,
  searchText = "",
  onValueSelected
}) => {

  const { t } = useTranslation();
  const instanceRef = useRef({});
  const { cc, setCc, first, setFirst, recordsPerPage, setRecordsPerPage, viewMode, setViewMode, browseMode, setBrowseMode, data, setData } = useCCContext();
  const [currentQuery, setCurrentQuery] = useState("");
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [browseValueData, setBrowseValueData] = useState<MobileBrowseValueDataItem[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const observer = useRef<IntersectionObserver | null>(null);
  const [currentSort, setCurrentSort] = useState(sort);
  const [localSelectedValues, setLocalSelectedValues] = useState<string[]>([]);
  const previousParams = useRef<{ page: number, field: string, searchText: string, sort: 'alpha' | 'count' } | null>(null);
  const lastFetchedInputRef = useRef('');

  const shouldFetchData = (page: number, field: string, searchText: string, sort: 'alpha' | 'count') => {
    return true;
  };

  const lastElementRef = useCallback((node: HTMLDivElement | null) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [hasMore]);

  const fetchData = async (page: number) => {
    console.log("fetching");
    lastFetchedInputRef.current = searchText;
    if (shouldFetchData(page, field, searchText, currentSort)) {
      previousParams.current = { page, field, searchText, sort: currentSort };
      const pageSize = 100;
      const query = generateQuery(cc, { excludeCustomSearchField: field });
      setCurrentQuery(query);

      const postData = {
        action: "get",
        command: "facets",
        fields: `${field}:${pageSize * (page - 1) + 1}-${pageSize * page}:*${searchText}*`,
        query: query,
        sort: currentSort,
        responseformat: "json"
      };

      try {
        const data = await ccUtils.postWithCache(process.env.REACT_APP_BASE_URL as string, postData, instanceRef);
        console.log(data);
        let newData: MobileBrowseValueDataItem[] = Array.isArray(data.facets?.facet?.value) ? data.facets.facet.value : [data.facets?.facet?.value].filter(Boolean);
        console.log(newData);
        if (searchText === lastFetchedInputRef.current) {
          setBrowseValueData(prev => {
            const updatedData = [...prev];
            newData.forEach(item => {
              if (!updatedData.find(i => i.value === item.value)) {
                updatedData.push(item);
              }
            });
            return updatedData;
          });
          setHasMore(data.facets?.facet?.count > pageSize * page);
        } else {
          console.log('Newer user input available');
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleAlphaSort = () => {
    setPage(1);
    setCurrentSort('alpha');
  };

  const handleCountSort = () => {
    setPage(1);
    setCurrentSort('count');
  };

  const handleCheckboxChange = (checked: boolean, value: string) => {
    onValueSelected(value);
  };

  useEffect(() => {
    console.log('cc changed');
    const query = generateQuery(cc, { excludeSimpleSearch: true });
    if (currentQuery != query) {
      setBrowseValueData([]);
      fetchData(1);
    } else {
      console.log("query the same");
    }
  }, [cc]);

  useEffect(() => {
    setBrowseValueData([]);
    fetchData(1);
  }, [field]);

  useEffect(() => {
    if (page > 1) {
      fetchData(page);
    }
  }, [page, searchText, currentSort]);

  useEffect(() => {
    setBrowseValueData([]);
    fetchData(1);
  }, [searchText, currentSort]);

  useEffect(() => {
    setLocalSelectedValues(selectedValues);
  }, [selectedValues]);

  return (
    <div>
      <>
        <div className={styles.popupoverlay}></div>
        <div className={styles.popup}>
          <div className={styles.main}>
            <div className={styles.browseValueContainer}>
              <div className={styles.controls}>
                <div className={styles.sortButtons}>
                  <GenericIconButton isActive={currentSort === 'alpha'} icon='regular/sort-alpha-down.svg' width='30px' height='30px' onClick={() => handleAlphaSort()} />
                  <GenericIconButton isActive={currentSort === 'count'} icon='regular/sort-amount-down.svg' width='30px' height='30px' onClick={() => handleCountSort()} />
                </div>
              </div>
              <div className={styles.selectedItems}>
                {localSelectedValues.map((selectedValue) => (
                  <div className={styles.facetValue} key={selectedValue}>
                    <GenericCheckbox
                      id={`browseValue_${field}_${selectedValue}`}
                      name={`browseValue_${field}_${selectedValue}`}
                      value={selectedValue}
                      checked={true}
                      onChange={handleCheckboxChange}
                      label={selectedValue}
                    />
                  </div>
                ))}
              </div>
              <div className={styles.content} >
                {browseValueData.filter(item => !localSelectedValues.includes(item.value)).map((item) => (
                  <div className={styles.facetValue} key={item.value} ref={browseValueData.indexOf(item) === browseValueData.length - 1 ? lastElementRef : null}>
                    <div onClick={() => { handleCheckboxChange(true, item.value) }}>
                      {`${item.value} (${item.count})`}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default MobileBrowseValues;
