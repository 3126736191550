import { CC, ccConfig } from './CC';
import { ICCContext } from '../CC/CCContext';

const CACHE_NAME = 'api-cache';
const MAX_CACHE_AGE = 10 * 60 * 1000; // 10 minutes in milliseconds
const MAX_CACHE_SIZE: number = 20 * 1024 * 1024;  // 20MB

/*

  https://88.99.164.61/pod23/?action=search&field=/Record/ObjectNumber&label=N%C2%B0%20de%20gestion&value=71.1976.66.1%20Oc

  // Replacing GET fetch
  const data = await fetchWithCache(url);

  // Replacing POST fetch
  const termData = await ccUtils.postWithCache(process.env.REACT_APP_BASE_URL as string, termPayload);

  const data = await ccUtils.postWithCache(process.env.REACT_APP_BASE_URL as string, {
    action: "get",
    command: "facets",
    fields: `/Record/ThesTerm/TermPathPart/CN:1-200:regexp(^${queryCN}..?.?.?$)`,
    query: query,
    sort: "alpha",
    responseformat: "json"
  });

*/

function hashString(s: string): string {
  const chrsz: number = 8;
  const hexcase: number = 0;

  function safe_add(x: number, y: number): number {
    const lsw = (x & 0xFFFF) + (y & 0xFFFF);
    const msw = (x >> 16) + (y >> 16) + (lsw >> 16);
    return (msw << 16) | (lsw & 0xFFFF);
  }

  function S(X: number, n: number): number { return (X >>> n) | (X << (32 - n)); }
  function R(X: number, n: number): number { return (X >>> n); }
  function Ch(x: number, y: number, z: number): number { return ((x & y) ^ ((~x) & z)); }
  function Maj(x: number, y: number, z: number): number { return ((x & y) ^ (x & z) ^ (y & z)); }
  function Sigma0256(x: number): number { return (S(x, 2) ^ S(x, 13) ^ S(x, 22)); }
  function Sigma1256(x: number): number { return (S(x, 6) ^ S(x, 11) ^ S(x, 25)); }
  function Gamma0256(x: number): number { return (S(x, 7) ^ S(x, 18) ^ R(x, 3)); }
  function Gamma1256(x: number): number { return (S(x, 17) ^ S(x, 19) ^ R(x, 10)); }

  function core_sha256(m: number[], l: number): number[] {
    const K: number[] = [
      0x428a2f98, 0x71374491, 0xb5c0fbcf, 0xe9b5dba5, 0x3956c25b, 0x59f111f1, 0x923f82a4, 0xab1c5ed5,
      0xd807aa98, 0x12835b01, 0x243185be, 0x550c7dc3, 0x72be5d74, 0x80deb1fe, 0x9bdc06a7, 0xc19bf174,
      0xe49b69c1, 0xefbe4786, 0x0fc19dc6, 0x240ca1cc, 0x2de92c6f, 0x4a7484aa, 0x5cb0a9dc, 0x76f988da,
      0x983e5152, 0xa831c66d, 0xb00327c8, 0xbf597fc7, 0xc6e00bf3, 0xd5a79147, 0x06ca6351, 0x14292967,
      0x27b70a85, 0x2e1b2138, 0x4d2c6dfc, 0x53380d13, 0x650a7354, 0x766a0abb, 0x81c2c92e, 0x92722c85,
      0xa2bfe8a1, 0xa81a664b, 0xc24b8b70, 0xc76c51a3, 0xd192e819, 0xd6990624, 0xf40e3585, 0x106aa070,
      0x19a4c116, 0x1e376c08, 0x2748774c, 0x34b0bcb5, 0x391c0cb3, 0x4ed8aa4a, 0x5b9cca4f, 0x682e6ff3,
      0x748f82ee, 0x78a5636f, 0x84c87814, 0x8cc70208, 0x90befffa, 0xa4506ceb, 0xbef9a3f7, 0xc67178f2];
    let HASH: number[] = [0x6a09e667, 0xbb67ae85, 0x3c6ef372, 0xa54ff53a,
      0x510e527f, 0x9b05688c, 0x1f83d9ab, 0x5be0cd19];
    const W: number[] = new Array(64);
    let a, b, c, d, e, f, g, h;
    let i, j, T1, T2;

    m[l >> 5] |= 0x80 << (24 - l % 32);
    m[((l + 64 >> 9) << 4) + 15] = l;

    for (i = 0; i < m.length; i += 16) {
      a = HASH[0];
      b = HASH[1];
      c = HASH[2];
      d = HASH[3];
      e = HASH[4];
      f = HASH[5];
      g = HASH[6];
      h = HASH[7];

      for (j = 0; j < 64; j++) {
        if (j < 16) W[j] = m[j + i];
        else W[j] = safe_add(safe_add(safe_add(Gamma1256(W[j - 2]), W[j - 7]), Gamma0256(W[j - 15])), W[j - 16]);

        T1 = safe_add(safe_add(safe_add(safe_add(h, Sigma1256(e)), Ch(e, f, g)), K[j]), W[j]);
        T2 = safe_add(Sigma0256(a), Maj(a, b, c));

        h = g;
        g = f;
        f = e;
        e = safe_add(d, T1);
        d = c;
        c = b;
        b = a;
        a = safe_add(T1, T2);
      }

      HASH[0] = safe_add(a, HASH[0]);
      HASH[1] = safe_add(b, HASH[1]);
      HASH[2] = safe_add(c, HASH[2]);
      HASH[3] = safe_add(d, HASH[3]);
      HASH[4] = safe_add(e, HASH[4]);
      HASH[5] = safe_add(f, HASH[5]);
      HASH[6] = safe_add(g, HASH[6]);
      HASH[7] = safe_add(h, HASH[7]);
    }
    return HASH;

    return HASH;
  }

  function str2binb(s: string): number[] {
    const bin: number[] = [];
    const mask: number = (1 << chrsz) - 1;
    for (let i = 0; i < s.length * chrsz; i += chrsz) {
      bin[i >> 5] |= (s.charCodeAt(i / chrsz) & mask) << (24 - i % 32);
    }
    return bin;
  }

  function binb2hex(binarray: number[]): string {
    const hex_tab: string = hexcase ? "0123456789ABCDEF" : "0123456789abcdef";
    let str = "";
    for (let i = 0; i < binarray.length * 4; i++) {
      str += hex_tab.charAt((binarray[i >> 2] >> ((3 - i % 4) * 8 + 4)) & 0xF) +
        hex_tab.charAt((binarray[i >> 2] >> ((3 - i % 4) * 8)) & 0xF);
    }
    return str;
  }

  return binb2hex(core_sha256(str2binb(s), s.length * chrsz));
}


/*
Can only be used in ssl or on localhost
async function hashString(input: string): Promise<string> {
  const encoder = new TextEncoder();
  const data = encoder.encode(input);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
}
*/

/*
// Helper function to get cached response
const getCachedResponse = async (request: Request, bodyHash: string): Promise<Response | undefined> => {
  const cacheKey = request.method === 'POST' 
    ? new Request(`${request.url}?bodyHash=${bodyHash}`) 
    : request;

  const cache = await caches.open(CACHE_NAME);
  const cachedResponse = await cache.match(cacheKey);
  
  if (!cachedResponse) return;

  const date = cachedResponse.headers.get('x-cached-on');

  if (date && (new Date().getTime() - new Date(date).getTime()) < MAX_CACHE_AGE) {
    return cachedResponse;
  }
};

// Helper function to set response to cache
const setCache = async (request: Request, bodyHash: string, response: Response) => {
  const cacheKey = request.method === 'POST' 
    ? new Request(`${request.url}?bodyHash=${bodyHash}`) 
    : request;

  // 1. Clone the response
  const clonedResponse = response.clone();

  // 2. Create a new headers object based on the original headers
  const newHeaders = new Headers(clonedResponse.headers);

  // 3. Append the custom header to this new headers object
  newHeaders.append('x-cached-on', new Date().toISOString());

  // 4. Create a new Response object using the cloned response's body and the modified headers
  const responseWithCustomHeader = new Response(clonedResponse.body, {
    status: clonedResponse.status,
    statusText: clonedResponse.statusText,
    headers: newHeaders
  });

  const cache = await caches.open(CACHE_NAME);
  await cache.put(cacheKey, responseWithCustomHeader);
  
};

// Fetch with cache for POST request
export const postWithCache = async (url: string, payload: any) => {
  const requestBody = JSON.stringify(payload);
  const bodyHash = await hashString(requestBody);

  // Use this for checking the cache
  const cacheRequest = new Request(`${url}?bodyHash=${bodyHash}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: requestBody
  });

  const cachedResponse = await getCachedResponse(cacheRequest, bodyHash);

  if (cachedResponse) {
    //console.log("YEP CACHED");
    return cachedResponse.json();
  } 

  //console.log("NOT CACHED");

  // Make a new Request object for the actual fetch
  const fetchRequest = new Request(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: requestBody
  });

  const response = await fetch(fetchRequest);
  await setCache(cacheRequest, bodyHash, response.clone());  // Clone the response for caching
  return response.json();  // Use the original response here
};

*/

/*
// Fetch with cache for GET request
export const getWithCache = async (url: string) => {

  const request = new Request(url);
  
  // Since GET requests don't have a body, we pass an empty string as the bodyHash
  const cachedResponse = await getCachedResponse(request, "");
  
  if (cachedResponse) return cachedResponse.json();

  const response = await fetch(request);

  // Pass an empty string as the bodyHash for the setCache function
  await setCache(request, "", response.clone());  // Clone the response for caching

  return response.json();  // Use the original response here
};
*/

type CachedItem = {
  timestamp: number;  // This will now be updated on access as well for LRU
  response: any;
  size: number;
};

const memoryCache: Record<string, CachedItem> = {};
let totalCacheSizeInBytes: number = 0;

const getCachedResponseFromMemory = (requestKey: string): any | undefined => {
  const cachedItem = memoryCache[requestKey];

  if (!cachedItem) return;

  const currentTime = new Date().getTime();
  if ((currentTime - cachedItem.timestamp) < MAX_CACHE_AGE) {
    cachedItem.timestamp = currentTime;  // Update the timestamp for LRU
    return cachedItem.response;
  }
};

const evictLRU = (): void => {
  const sortedKeys = Object.keys(memoryCache).sort((a, b) => memoryCache[a].timestamp - memoryCache[b].timestamp);
  while (totalCacheSizeInBytes > MAX_CACHE_SIZE && sortedKeys.length) {
    const oldestKey = sortedKeys.shift()!;
    totalCacheSizeInBytes -= memoryCache[oldestKey].size;
    delete memoryCache[oldestKey];
  }
};

const setCacheInMemory = (requestKey: string, response: any): void => {
  const responseString = JSON.stringify(response);
  const responseSize = new Blob([responseString]).size;

  // Check if the new item itself exceeds the MAX_CACHE_SIZE
  if (responseSize > MAX_CACHE_SIZE) {
    console.warn("The item size exceeds the cache limit.");
    return;
  }

  memoryCache[requestKey] = {
    timestamp: new Date().getTime(),
    response,
    size: responseSize
  };

  totalCacheSizeInBytes += responseSize;

  if (totalCacheSizeInBytes > MAX_CACHE_SIZE) {
    evictLRU();  // Evict least recently used items if over the limit
  }
};

type OngoingRequest = {
  promise: Promise<any>;
  reject: (reason?: any) => void;
};

// Map to keep track of ongoing requests by callerId
const ongoingRequests: Map<object, OngoingRequest> = new Map();

export const postWithCache = async (url: string, payload: any, callerRef: object): Promise<any> => {
  const requestBody = JSON.stringify(payload);
  const bodyHash = await hashString(requestBody);
  const requestKey = `${url}?bodyHash=${bodyHash}`;

  const cachedResponse = getCachedResponseFromMemory(requestKey);

  if (cachedResponse) {
    return cachedResponse;
  }

  if (ongoingRequests.has(callerRef)) {
    ongoingRequests.get(callerRef)?.reject(new Error('Request superseded by a new one'));
  }

  let ongoingRequest: OngoingRequest = {
    promise: null as any, // We'll assign this shortly
    reject: (reason?: any) => { } // This is a placeholder that will be overwritten
  };

  const fetchPromise = new Promise<any>((resolve, reject) => {
    // Now we assign the real reject function
    ongoingRequest.reject = reject;

    (async () => {
      try {
        const fetchRequest = new Request(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: requestBody
        });
        const response = await fetch(fetchRequest);
        const responseJSON = await response.json();
        setCacheInMemory(requestKey, responseJSON);
        resolve(responseJSON);
      } catch (error) {
        reject(error);
      }
    })();
  });

  // Assign the promise to the ongoingRequest object
  ongoingRequest.promise = fetchPromise;

  // Now add the ongoingRequest to the map
  ongoingRequests.set(callerRef, ongoingRequest);

  try {
    const result = await fetchPromise;
    return result;
  } catch (error) {
    throw error;  // re-throw the error for the caller to handle
  } finally {
    // Cleanup: Remove the request from the ongoingRequests map once it's done
    ongoingRequests.delete(callerRef);
  }
};

export const getCacheSize = (): number => {
  return totalCacheSizeInBytes;
};

export const fetchMonumentImage = async (monumentName: string): Promise<string> => {
  const query = `sitename=${encodeURIComponent(monumentName)}`;
  const url = `${process.env.REACT_APP_BASE_URL}/?action=get&command=search&query=${query}&range=1-1&responseformat=json`;

  const cachedResponse = getCachedResponseFromMemory(url);
  if (cachedResponse) {
    return constructImageUrl(cachedResponse);
  }

  const response = await fetch(url);
  const data = await response.json();

  setCacheInMemory(url, data);

  return constructImageUrl(data);
};

const constructImageUrl = (data: any): string => {
  const siteImage = data.records.record.data.record.siteimage;
  const imageUrl = `ccImageProxy.ashx?filename=images/${encodeURIComponent(siteImage)}&width=600&_height=160&borderwidth=0&borderheight=0&bordercolor=e8e8e8&bg=f8f8f8&passepartoutwidth=0&passepartoutheight=0&passepartoutcolor=f8f8f8&cache=yes`;

  return imageUrl;
};

export const fetchMonumentHtml = async (monumentName: string): Promise<any> => {
  const query = `sitename=${encodeURIComponent(monumentName)}`;
  const url = `${process.env.REACT_APP_BASE_URL}/?action=get&command=search&query=${query}&range=1-10&responseformat=json`;

  const cachedResponse = getCachedResponseFromMemory(url);
  if (cachedResponse) {
    return cachedResponse;
  }

  const response = await fetch(url);
  const data = await response.json();

  setCacheInMemory(url, data);
  
  return data;
};

export const generateQuery = (cc: CC, { excludeFacet, excludeSimpleSearch, excludeCustomSearch, excludeCustomSearchField }: { excludeFacet?: string, excludeSimpleSearch?: boolean, excludeCustomSearch?: boolean, excludeCustomSearchField?: string } = {}) => {
  let query = "*=*";
  const simpleSearchQueries: string[] = [];

  const combineQueries = (queries: string[], operator: 'and' | 'or'): string => {
    if (queries.length === 0) return "";
    if (queries.length === 1) return queries[0];
    return queries.reduce((acc, curr) => `${operator}(${acc};${curr})`);
  };

  if (!excludeSimpleSearch && cc.Searches.length > 0) {
    cc.Searches.forEach(simpleSearch => {
      if (simpleSearch.searchValues?.length > 0) {
        const field = ccConfig.simpleSearchFields[simpleSearch.field as keyof typeof ccConfig.simpleSearchFields]?.field;
        if (field) {
          const values = simpleSearch.searchValues.map(({ source, text }) => {
            if (source === 'typeahead') {
              return `${field}="${text}"`;
            } else if (source === 'facet') {
              return `${field}=[${text}]`;
            } else {
              if ((text.startsWith('"') && text.endsWith('"')) || (text.startsWith("'") && text.endsWith("'"))) {
                return `${field}=${text}`;
              } else {
                return `${field}=${text}`;
              }
            }
          });
          
          simpleSearchQueries.push(combineQueries(values, 'or'));
        }
      }
    });

    if (simpleSearchQueries.length > 0) {
      const operator = cc.matchAllCriteria ? 'and' : 'or';
      query = combineQueries(simpleSearchQueries, operator);
    }
  }

  // Add custom search criteria
  if (!excludeCustomSearch && cc.customSearchCriteria.length > 0) {
    const customSearchQueries: string[] = [];

    cc.customSearchCriteria.forEach(criterion => {
      if (criterion.field !== excludeCustomSearchField) {
        const field = criterion.field;
        const values = criterion.searchValues.map(value => `${field}=${value}`);
        customSearchQueries.push(combineQueries(values, 'or'));
      }
    });

    const operator = cc.matchAllCriteria ? 'and' : 'or';
    const customSearchQuery = combineQueries(customSearchQueries, operator);
    if (!cc.matchAllCriteria && query === "*=*" && customSearchQueries.length > 0) {
      query = customSearchQuery;
    } else if (customSearchQueries.length > 0) {
      query = `${operator}(${query};${customSearchQuery})`;
    }
  }

  if (cc.filters.length > 0) {
    const groupMap = new Map<string, string[]>(); 

    cc.filters.forEach(filter => {
      const filterGroup = ccConfig.filters[filter].group;
      const filterQuery = ccConfig.filters[filter].query;

      if (groupMap.has(filterGroup)) {
        groupMap.get(filterGroup)?.push(filterQuery);
      } else {
        groupMap.set(filterGroup, [filterQuery]);
      }
    });

    let filterQueryParts: string[] = [];
    groupMap.forEach((queryParts, group) => {
      filterQueryParts.push(combineQueries(queryParts, 'or'));
    });

    const filterQuery = combineQueries(filterQueryParts, 'and');
    query = `and(${query};${filterQuery})`;
  }

  if (Object.keys(cc.facets).length > 0) {
    const facetQueryParts: string[] = [];

    Object.entries(cc.facets).forEach(([facetField, facetValues]) => {
      if (facetField !== excludeFacet && facetValues.length > 0) {
        const encodedFacetValues = facetValues.map(value => value);
        const queryField = ccConfig.facets[facetField as keyof typeof ccConfig.facets].query;
        const facetSubQuery = `${queryField}=[${encodedFacetValues.join(']|[')}]`;
        facetQueryParts.push(facetSubQuery);
      }
    });

    if (facetQueryParts.length > 0) {
      const facetQuery = combineQueries(facetQueryParts, 'and');
      query = `and(${query};${facetQuery})`;
    }
  }

  if (Object.keys(cc.hierarchicalFacets).length > 0) {
    const hierarchicalFacetQueryParts: string[] = [];

    Object.entries(cc.hierarchicalFacets).forEach(([facetField, hierarchicalFacetValues]) => {
      if (facetField !== excludeFacet && hierarchicalFacetValues.length > 0) {
        const hierarchicalFacetSubQuery = `/Record/mqb_thesterm/ThesTerms/CN=${hierarchicalFacetValues.map(value => `[${value.CN}]`).join('|')}`;
        console.log('subquery: ' + hierarchicalFacetSubQuery);
        hierarchicalFacetQueryParts.push(hierarchicalFacetSubQuery);
      }
    });

    if (hierarchicalFacetQueryParts.length > 0) {
      const hierarchicalFacetQuery = combineQueries(hierarchicalFacetQueryParts, 'and');
      query = `and(${query};${hierarchicalFacetQuery})`;
    }
  }

  query = `and(recordtype=objects;${query})`;

  if (query === "") {
    query = "*=*";
  }
  //console.log('query: ' + query);
  return query;
};

/*
export const generateUrl = (cc: CC, first: number, last: number) => {
  const query = generateQuery(cc);
  const range = `&range=${first}-${last}`;
  return `${process.env.REACT_APP_BASE_URL}/?action=get&command=search&query=${query}${range}&fields=*&responseformat=json`;
};
*/

export const generatePayload = (cc: CC, sort: string, first: number, last: number) => {
  const query = generateQuery(cc);
  return {
    action: "get",
    command: "search",
    query: query,
    range: `${first}-${last}`,
    fields: "*",
    sort: ccConfig.sort[sort].field,
    responseformat: "json"
  };
};

// Ensure the `searchValues` parameter is always an array
export const ensureArray = (input: string | string[]): string[] => {
  return Array.isArray(input) ? input : [input];
};

export const activateFilter = (context: ICCContext, filterName: string) => {
  const { cc, setCc, setFirst } = context;
  if (!cc.filters.includes(filterName)) {
    setCc(prevCc => ({
      ...prevCc,
      filters: [...prevCc.filters, filterName]
    }));
    setFirst(1);
  }
};

// Deactivate a filter based on filter name
export const deactivateFilter = (context: ICCContext, filterName: string) => {
  const { cc, setCc, setFirst } = context;
  setCc(prevCc => ({
    ...prevCc,
    filters: prevCc.filters.filter(filter => filter !== filterName)
  }));
  setFirst(1);
};

// Clear a facet
export const clearFacet = (context: ICCContext, facetName: string) => {
  const { cc, setCc, setFirst } = context;
  const updatedFacets = { ...cc.facets };
  console.log(updatedFacets);
  delete updatedFacets[facetName];
  setCc(prevCc => ({
    ...prevCc,
    facets: updatedFacets
  }));
  setFirst(1);
};

export const addFacetValue = (
  context: ICCContext,
  facetName: string,
  facetValue: string | string[]) => {

  const { cc, setCc, setFirst } = context;
  const valuesToAdd = ensureArray(facetValue);

  const updatedFacets = { ...cc.facets };

  if (updatedFacets[facetName]) {
    // Filter the values to add to only include those that are not already present
    const uniqueValuesToAdd = valuesToAdd.filter(value => !updatedFacets[facetName].includes(value));
    updatedFacets[facetName].push(...uniqueValuesToAdd); // spread the array to push its individual items
  } else {
    updatedFacets[facetName] = valuesToAdd;
  }

  setCc(prevCc => ({
    ...prevCc,
    facets: updatedFacets
  }));
  setFirst(1);
};

export const removeFacetValue = (
  context: ICCContext,
  facetName: string,
  facetValue: string | string[]) => {

  const { cc, setCc, setFirst } = context;
  const valuesToRemove = ensureArray(facetValue);

  const updatedFacets = { ...cc.facets };

  if (updatedFacets[facetName]) {
    // Filter the existing facet values to exclude the ones we want to remove
    updatedFacets[facetName] = updatedFacets[facetName].filter(value => !valuesToRemove.includes(value));

    // If after removing, there are no more values for this facet, delete the facet key
    if (updatedFacets[facetName].length === 0) {
      delete updatedFacets[facetName];
    }
  }

  setCc(prevCc => ({
    ...prevCc,
    facets: updatedFacets
  }));
  setFirst(1);
};


// Clear all search criteria to revert back to the initial state
export const clearSearch = (context: ICCContext) => {
  const { cc, setCc, setFirst } = context;
  const initialCC = new CC();
  setCc(initialCC);
  setFirst(1);
};

// Add a new custom search criterion
export const addCustomSearch = (
  context: ICCContext,
  field: string,
  fieldLabel: string,
  searchValues: string | string[],
  description: string = ''
) => {
  const { cc, setCc, setFirst } = context;
  
  const criterion = {
    field,
    fieldLabel,
    searchValues: ensureArray(searchValues),
    description
  };

  // Check if an identical criterion already exists in customSearchCriteria
  const exists = cc.customSearchCriteria.some(existingCriterion => 
    existingCriterion.field === criterion.field &&
    existingCriterion.fieldLabel === criterion.fieldLabel &&
    JSON.stringify(existingCriterion.searchValues) === JSON.stringify(criterion.searchValues) &&
    existingCriterion.description === criterion.description
  );

  // Only add the criterion if it doesn't already exist
  if (!exists) {
    setCc(prevCc => ({
      ...prevCc,
      customSearchCriteria: [...prevCc.customSearchCriteria, criterion]
    }));
    setFirst(1);
  }
};


// Replace all search criteria with a new criterion
export const customSearch = (
  context: ICCContext,
  field: string,
  fieldLabel: string,
  searchValues: string | string[],
  description: string = ''
) => {
  const { cc, setCc, setFirst } = context;
  const criterion = {
    field,
    fieldLabel,
    searchValues: ensureArray(searchValues),
    description
  };

  const freshCC = new CC(); // Create a fresh instance
  freshCC.customSearchCriteria = [criterion]; // Update only the customSearchCriteria
  console.log(freshCC.customSearchCriteria);
  setCc(freshCC); // Set the CC instance to the freshCC
  setFirst(1);
};

export function isEqual(obj1: any, obj2: any): boolean {
  // If both are of different types
  if (typeof obj1 !== typeof obj2) return false;

  // If either of them is null or undefined, only return true if both are.
  if (obj1 === null || obj2 === null || obj1 === undefined || obj2 === undefined) {
    return obj1 === obj2;
  }

  // If both are objects
  if (typeof obj1 === 'object' && obj1 !== null && typeof obj2 === 'object' && obj2 !== null) {
    const keys1: string[] = Object.keys(obj1);
    const keys2: string[] = Object.keys(obj2);

    // If they have different number of keys, they are not equal
    if (keys1.length !== keys2.length) return false;

    // Check equality for each key
    for (let key of keys1) {
      if (!keys2.includes(key) || !isEqual(obj1[key], obj2[key])) return false;
    }

    return true;
  }

  // If both are arrays
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) return false;

    for (let i = 0; i < obj1.length; i++) {
      if (!isEqual(obj1[i], obj2[i])) return false;
    }

    return true;
  }

  // For all other types, rely on strict equality
  return obj1 === obj2;
}

// Define the type of the props for better type-safety
type GenerateSearchHyperlinkProps = {
  children: React.ReactNode;
  context: ICCContext;
  field: string;
  value: string;
  label?: string;
  description?: string;
};

export const generateSearchHyperlink = ({
  children,
  context,
  field,
  value,
  label = "",
  description = ""
}: GenerateSearchHyperlinkProps) => {
  return (
    <a
      href={`${window.location.pathname}?action=search&field=${field}&label=${label}&value=${value}&description=${description}`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        customSearch(context, field, label, value, description);
      }}
      onMouseDown={e => e.preventDefault()} // prevent the link from being "followed" on left-click
    >
      {children}
    </a>
  );
};


type GenerateStateHyperlinkProps = {
  children: React.ReactNode;
  cc: CC;
};

const hashObject = (obj: object): string => {
  // Here, we'll generate a hash of the object. 
  // This is a simple hash and can be improved or replaced by a more advanced hashing function.
  let str = JSON.stringify(obj);
  let hash = 0, i, chr;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return "state_" + hash.toString();
};

const storeStateOnServer = async (hash: string, obj: CC) => {
  const data = JSON.stringify(obj);
  await fetch("ccState.ashx?action=store&hash=" + hash, {
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain'
    },
    body: data
  });
};

//https://88.99.164.61/pod23/?state={"Searches":[{"identifier":"simpleSearch","field":"All search fields","searchValues":[{"source":"freetext","text":"trois"}]}],"matchAllCriteria":false}
//https://88.99.164.61/pod23/?state={"Searches":[{"identifier":"simpleSearch","field":"All search fields","searchValues":[{"source":"freetext","text":"masque"}]},{"identifier":"advancedSearch0","field":"All search fields"},{"identifier":"advancedSearch1","field":"Title"},{"identifier":"advancedSearch2","field":"Inventory number"},{"identifier":"advancedSearch3","field":"Description"},{"identifier":"advancedSearch4","field":"Conservator"}],"matchAllCriteria":true}
// http://88.99.164.61/pod23?state=state_-1874972213

//http://localhost:3000?state={"Searches":[{"identifier":"simpleSearch","field":"All search fields","searchValues":[{"source":"freetext","text":"trois"}]}],"matchAllCriteria":false}
//http://localhost:3000?state={"Searches":[{"identifier":"simpleSearch","field":"All search fields","searchValues":[{"source":"freetext","text":"masque"}]},{"identifier":"advancedSearch0","field":"All search fields"},{"identifier":"advancedSearch1","field":"Title"},{"identifier":"advancedSearch2","field":"Inventory number"},{"identifier":"advancedSearch3","field":"Description"},{"identifier":"advancedSearch4","field":"Conservator"}],"matchAllCriteria":true}
// http://88.99.164.61/pod23?state=state_-1874972213

//https://88.99.164.61/pod23/?state=state_1917958478
export const getStateFromServer = async (hash: string): Promise<CC> => {
  const response = await fetch("ccState.ashx?action=retrieve&hash=" + hash);

  // Ensure the response is ok before proceeding
  if (!response.ok) {
    throw new Error('Network response was not ok.');
  }

  // Read the response body as text
  const textData = await response.text();

  //console.log('Received state', textData);

  // Parse the text data as JSON
  const data = JSON.parse(textData);
  //console.log(data as CC);

  return data as CC;
};

export const deepMerge = (target: any, source: any): any => {
  if (typeof target !== 'object' || target === null) {
    return source;
  }

  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      if (typeof source[key] === 'object' && source[key] !== null && target.hasOwnProperty(key)) {
        target[key] = deepMerge(target[key], source[key]);
      } else {
        target[key] = source[key];
      }
    }
  }
  return target;
};

export const convertStateToCC = (curState: any) => {
  const defaultCCObj = new CC();
  return deepMerge(defaultCCObj, curState);
}

const pruneEmpty = (obj: any): any => {
  // Return null if obj is null or undefined
  if (obj == null) return null;

  // For Arrays, remove any null/undefined entries and recursively prune others
  if (Array.isArray(obj)) {
    let newArr = obj.map(value => pruneEmpty(value)).filter(value => value != null);
    return newArr.length === 0 ? null : newArr;
  }

  // For Objects, remove any keys with null/undefined values and recursively prune others
  if (typeof obj === 'object') {
    const newObj: { [key: string]: any } = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let prunedValue = pruneEmpty(obj[key]);
        if (prunedValue != null) {
          newObj[key] = prunedValue;
        }
      }
    }
    return Object.keys(newObj).length === 0 ? null : newObj;
  }

  // For all other types, return the value as-is
  return obj;
};

export const GenerateStateHyperlink = (cc: CC) => {
  const urlParams = new URLSearchParams(window.location.search);
  const monumentParam = urlParams.get('monument');

  let prunedCC = pruneEmpty(cc);
  let param = JSON.stringify(prunedCC);
  console.log(param);

  if (param.length > 256) {
    let hash = hashObject(prunedCC);
    storeStateOnServer(hash, prunedCC);
    param = hash;
    console.log(param);
  }

  let newUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}${window.location.pathname}?`;

  // selected monument is in facet values
  //if (monumentParam) {
  //  newUrl += `monument=${encodeURIComponent(monumentParam)}&state=${param}`;
  //} else {
    newUrl += `state=${param}`;
  //}
  
  return newUrl;
};



