import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import genericStyles from '../GenericComponents/GenericStyles.module.css';
import styles from './SearchBox.module.css';
import { ccConfig, CC, Search, SearchType } from '../CC/CC';
import { CCProvider, useCCContext } from '../CC/CCContext';
import GenericIconButton from '../GenericComponents/GenericIconButton';
import BrowseValues from './BrowseValues';
import * as ccUtils from '../CC/utils';

interface TypeAheadItem {
  word: string;
}

interface SearchBoxProps {
  Field?: string;
  Fields?: "simple" | "advanced";
  showMapCallback?: (showMap: boolean) => void;
  showSearchButton?: boolean;
  id: string;
  searchTriggered?: boolean;
}

interface SearchValue {
  source: string;
  text: string;
}

function getDefaultField(fieldsType: "simple" | "advanced"): string {
  const fields = fieldsType === "simple" ? ccConfig.simpleSearchFields : ccConfig.advancedSearchFields;
  return Object.keys(fields)[0];
}

function SearchBox({ Field, Fields, id, showMapCallback, showSearchButton = false, searchTriggered }: SearchBoxProps) {
  const { t } = useTranslation();
  const instanceRef = useRef({});
  const { cc, setCc, first, setFirst, recordsPerPage, setRecordsPerPage, viewMode, setViewMode, browseMode, setBrowseMode, data, setData } = useCCContext();

  const existingSearch = useExistingSearch(cc, id, Field);

  const searchFields = Fields === "simple" ? ccConfig.simpleSearchFields : ccConfig.advancedSearchFields;
  const [selectedField, setSelectedField] = useState<string>(existingSearch.field || getDefaultField(Fields || "simple"));
  const [searchValues, setSearchValues] = useState<SearchValue[]>(existingSearch.searchValues);
  const [inputValue, setInputValue] = useState<string>("");
  const modalSearchValuesRef = useRef<HTMLDivElement>(null);
  const [modalSearchValuesPosition, setModalSearchValuesPosition] = useState<{ x: number, y: number } | null>(null);
  const [typeAheadResults, setTypeAheadResults] = useState<TypeAheadItem[]>([]);
  const [typeAheadShowing, setTypeAheadShowing] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);
  const [isBrowseValuesModalOpen, setIsBrowseValuesModalOpen] = useState(false);

  const typeAheadRef = useRef<HTMLDivElement>(null);
  const inputTextBoxRef = useRef<HTMLInputElement>(null);
  const lastFetchedInputRef = useRef('');

  useEffect(() => {
    if (searchTriggered) {
      searchUserTypedText();
    }
  }, [searchTriggered]);


  function useExistingSearch(cc: any, id: string, Field?: string): SearchType {
    if (!cc || !cc.Searches) {
      // Handle the error scenario, possibly log it or return a default value
      return new Search(id, Field || "", []);
    }
    return cc.Searches.find((search: Search) => search.identifier === id) || new Search(id, Field || "", []);
  }

  const showSearchValues = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    let x = 0;
    let y = 0;

    const target = event.target as HTMLElement;
    const rect = target.getBoundingClientRect();

    // Try to find a parent modal of the clicked element
    const parentModal = target.closest('.parentModalClass');
    if (parentModal) {
      const modalRect = parentModal.getBoundingClientRect();
      x = rect.left - modalRect.left + window.scrollX;
      y = rect.bottom + 2 - modalRect.top + window.scrollY;
    } else {
      x = rect.left + window.scrollX;
      y = rect.bottom + 2 + window.scrollY;
    }

    setModalSearchValuesPosition({
      x: x,
      y: y
    });
  };

  const clearSearch = () => {
    setSearchValues([]);
    setInputValue("");
    /*
    setCc({
      ...cc,
      simpleSearchField: "",
      simpleSearchValue: [],
    });
    */
    setFirst(1);
  };

  const openBrowseValuesModal = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    console.log('openBrowseValuesModal');
    event.preventDefault();
    setIsBrowseValuesModalOpen(true);
  };

  const closeBrowseValuesModal = (update: boolean, values?: string[]) => {
    console.log('closeBrowseValuesModal');
    if (update && values) {
      console.log('handleLocalSelectedBrowseValues');
      console.log(values);
      const newValues = values.map(value => ({ source: 'facet', text: value }));
      const updatedSearchValues = searchValues.filter(value => value.source !== 'facet' || values.includes(value.text));
      const uniqueNewValues = newValues.filter(newValue => !updatedSearchValues.some(value => value.text === newValue.text));
      setSearchValues([...updatedSearchValues, ...uniqueNewValues]);

      setFirst(1);
    }
    setIsBrowseValuesModalOpen(false);
  };

  const handleLocalSelectedBrowseValues = (values: string[]) => {
    console.log('handleLocalSelectedBrowseValues');
    console.log(values);
    const newValues = values.map(value => ({ source: 'facet', text: value }));
    const updatedSearchValues = searchValues.filter(value => value.source !== 'facet' || values.includes(value.text));
    const uniqueNewValues = newValues.filter(newValue => !updatedSearchValues.some(value => value.text === newValue.text));
    setSearchValues([...updatedSearchValues, ...uniqueNewValues]);

    setFirst(1);
  };

  useEffect(() => {
    if (!ccUtils.isEqual(searchValues, existingSearch.searchValues)) {
      setSearchValues(existingSearch.searchValues);
    }
  }, [existingSearch.searchValues]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalSearchValuesRef.current && !modalSearchValuesRef.current.contains(event.target as Node) && modalSearchValuesPosition) {
        setModalSearchValuesPosition(null);
      }

      // Check for clicks outside of the typeAheadRef
      if (typeAheadRef.current && !typeAheadRef.current.contains(event.target as Node) && typeAheadShowing) {
        setHighlightedIndex(null);
        setTypeAheadShowing(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalSearchValuesPosition, typeAheadShowing]);

  const handleDeleteValue = (index: number) => {
    if (searchValues.length <= 1) {
      setModalSearchValuesPosition(null);
    }
    const newSearchValue = [...searchValues];
    newSearchValue.splice(index, 1);
    setSearchValues(newSearchValue);

    setFirst(1);
  };

  useEffect(() => {
    // Find the index of the existing search action with the given id
    // console.log("values: "+JSON.stringify(searchValues))
    const searchIndex = cc.Searches.findIndex(search => search.identifier === id);

    // Create a new search action with the updated field
    const updatedSearch = new Search(id, selectedField, searchValues);

    // Update the cc.Searches array with the new search action
    if (searchIndex !== -1) {
      // Replace the existing search action with the updated one
      cc.Searches[searchIndex] = updatedSearch;
    } else {
      // Add the new search action to the array
      cc.Searches.push(updatedSearch);
    }

    setCc({
      ...cc,
      Searches: [...cc.Searches]
    });

  }, [selectedField, searchValues]);


  const handleFieldChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newField = event.target.value;

    // Update the local state with the new field
    setSelectedField(newField);

    setFirst(1);
  };

  const handleTypeAheadSelectItem = (event: React.SyntheticEvent, word: string) => {
    event.preventDefault();
    setSearchValues([...searchValues, { source: 'typeahead', text: word }]);
    setInputValue("");
    setFirst(1);
    setHighlightedIndex(null);
    setTypeAheadShowing(false);
    /*
    setCc({
      ...cc,
      simpleSearchField: selectedField,
      simpleSearchValue: [...searchValues, { source: 'typeahead', text: word }]
    });
    */
  };

  const handleSearchButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    searchUserTypedText();
    if (viewMode == "homepage") {
      setViewMode('list');
    }
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Update the inputValue state with the current value in the textbox
    setInputValue(event.target.value);
  };

  const searchUserTypedText = () => {

    if (inputValue.trim().length > 0) {
      // Add the new search value to the local state
      const newValue = { source: 'freetext', text: inputValue.trim() };
      //setSearchValues(prevSearchValues => [...prevSearchValues, newValue]);

      // Find the index of the existing search action with the given id
      const searchIndex = cc.Searches.findIndex(search => search.identifier === id);

      // Create a new search action with the updated search values
      //const updatedSearch = new Search(id, selectedField, [...searchValues, newValue]);
      const updatedSearch = new Search(id, selectedField, [...(searchValues || []), newValue]);

      // Update the cc.Searches array with the new search action

      if (searchIndex !== -1) {
        cc.Searches[searchIndex] = updatedSearch;
      } else {
        cc.Searches.push(updatedSearch);
      }

      // Reset the inputValue for the next search
      setInputValue("");

      setHighlightedIndex(null);
      setTypeAheadShowing(true);

      // Reset the pagination
      setFirst(1);
    }

  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (typeAheadShowing) {
      switch (event.key) {
        case "ArrowDown":
          setHighlightedIndex((prevIndex) => (prevIndex === null || prevIndex === typeAheadResults.length - 1) ? 0 : prevIndex + 1);
          event.preventDefault();
          break;
        case "ArrowUp":
          setHighlightedIndex((prevIndex) => (prevIndex === null || prevIndex === 0) ? typeAheadResults.length - 1 : prevIndex - 1);
          event.preventDefault();
          break;
        case "Enter":
          if (highlightedIndex !== null) {
            handleTypeAheadSelectItem(event, typeAheadResults[highlightedIndex].word);
            event.preventDefault();
          } else if (inputValue.trim()) {
            searchUserTypedText();
          }
          break;
        case "Escape":
          setTypeAheadShowing(false);
          setHighlightedIndex(null);
          break;
        default:
          setTypeAheadShowing(true);
          break;
      }
    } else if (event.key === "Enter" && inputValue.trim()) {
      event.preventDefault();
      searchUserTypedText();
    } else {
      setHighlightedIndex(null);
      setTypeAheadShowing(true);
    }
  };

  useEffect(() => {
    if (inputValue) {
      lastFetchedInputRef.current = inputValue;
      let adaptedInputValue = inputValue.trim();
      const query = ccUtils.generateQuery(cc, { excludeSimpleSearch: true });
      let queryField = "";
      if (!Fields || Fields === "simple") {
        queryField = ccConfig.simpleSearchFields[selectedField as keyof typeof ccConfig.simpleSearchFields]?.field;
      }
      if ((!Fields && (!queryField || queryField === "")) || Fields === "advanced") {
        queryField = ccConfig.advancedSearchFields[selectedField as keyof typeof ccConfig.advancedSearchFields]?.field;
      }

      const postData = {
        action: "get",
        command: "scanconcepts",
        fields: queryField,
        query: query,
        mask: "regexp(.*"+adaptedInputValue+".*)",
        range: "1-10",
        responseformat: "json"
      };

      ccUtils.postWithCache(process.env.REACT_APP_BASE_URL as string, postData, instanceRef)
        .then(data => {
          if (inputValue === lastFetchedInputRef.current) {
            if (data.word.word && Array.isArray(data.word.word)) {
              setTypeAheadResults(data.word.word);
            } else {
              setTypeAheadResults([]);
              setTypeAheadShowing(false);
            }
          }
        }).catch(error => {
          console.error(error);
        });
    } else {
      setTypeAheadResults([]);
      setTypeAheadShowing(false);
    }
  }, [inputValue]);

  const renderTypeAheadResults = () => {
    let x = 0;
    let y = 0;

    if (inputTextBoxRef.current) {
      const rect = inputTextBoxRef.current.getBoundingClientRect();

      // Try to find a parent modal of the input box
      const parentModal = inputTextBoxRef.current.closest('.parentModalClass');
      if (parentModal) {
        const modalRect = parentModal.getBoundingClientRect();
        x = rect.left - modalRect.left + window.scrollX;
        y = rect.bottom + 2 - modalRect.top + window.scrollY;
      } else {
        x = rect.left + window.scrollX;
        y = rect.bottom + 2 + window.scrollY;
      }
    }

    return (
      <div
        className={styles.typeAheadResults}
        ref={typeAheadRef}
        style={{
          position: 'absolute',
          top: y + 'px',
          left: x + 'px'
        }}
      >
        {typeAheadResults.map((item: TypeAheadItem, index: number) => (
          <div
            key={index}
            className={`${styles.typeAheadItem} ${highlightedIndex === index ? styles.highlightedItem : ''}`}
            onClick={(event) => handleTypeAheadSelectItem(event, item.word)}
          >
            {item.word}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.SearchBox}>
      {Field ? (
        <></>
      ) : (
        <label>
          <select className={genericStyles.genericSelect} value={selectedField} onChange={handleFieldChange}>
            {Object.keys(searchFields).map((key: string, index: number) => (
              <option key={index} value={key}>{t(key)}</option>
            ))}
          </select>
        </label>
      )}
      <div className={styles.searchValueContainer}>
        <div className={`${styles.searchValuesCount} ${Array.isArray(searchValues) && searchValues.length > 0 ? styles.redDot : ''}`}>
          {
            Array.isArray(searchValues) && searchValues.length > 0 && (
              <div onClick={showSearchValues}>
                {`${searchValues.length} ${searchValues.length === 1 ? t('query') : t('queries')}`}
                <div className={styles.searchValuesbutton} >
                  <GenericIconButton
                    icon='regular/chevron-double-down.svg'
                    hoverEnabled={false}
                    width='8px'
                    height='8px'
                  />
                </div>
              </div>
            )
          }
        </div>
        <div
          className={styles.searchValues}
          style={{
            display: modalSearchValuesPosition ? 'block' : 'none',
            top: modalSearchValuesPosition?.y,
            left: modalSearchValuesPosition?.x
          }}
          ref={modalSearchValuesRef}
        >
          {Array.isArray(searchValues) && searchValues.length > 0 && searchValues.map((value, index) => (
            <div key={index} className={`${styles.searchValue} ${styles[value.source]}`} onClick={() => handleDeleteValue(index)}>
              {value.text}
              <span className={styles.deleteValue}>x</span>
            </div>
          ))}
        </div>
        <div className={styles.textBox}>
          <input
            ref={inputTextBoxRef}
            className={styles.SimpleSearchTextbox}
            type="text"
            placeholder={t("searchbox_new_criterium")}
            value={inputValue}
            onChange={handleValueChange}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
      <GenericIconButton icon='light/times.svg' width='18px' height='18px' onClick={() => clearSearch()} />
      {showSearchButton &&
        <button className={genericStyles.GenericButton} onClick={handleSearchButtonClick}>
          {t("searchbox_search_button")}
        </button>
      }
      {ccConfig.simpleSearchFields[selectedField]?.browse && (
        <>
          <button className={genericStyles.GenericButton} onClick={openBrowseValuesModal}>
          {t("searchbox_browse_button")}
          </button>

          {isBrowseValuesModalOpen &&
            <BrowseValues
              field={ccConfig.simpleSearchFields[selectedField]?.field}
              sort='alpha'
              searchValues={searchValues.filter(value => value.source === 'facet').map(value => value.text)}
              onSelect={handleLocalSelectedBrowseValues}
              isOpen={isBrowseValuesModalOpen}
              onClose={closeBrowseValuesModal}
            />
          }
        </>
      )}
      {typeAheadShowing && renderTypeAheadResults()}
    </div>
  );
}

export default SearchBox;
