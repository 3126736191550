import React, { createContext, useContext, useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CC, ccConfig } from './CC';
import * as ccUtils from './utils';

export type User = {
  userDetails: {
    email: string;
    realname: string;
    roles: string[];
  };
  token: string;
  tokenExpires: string;
  albums: Album[];
};

export type Album = {
  albumId: string;
  albumName: string;
  albumPath: string;
  albumParent: string;
  albumText: string;
  childNodes?: Album[];
  nodeType: 'text' | 'annotatedImage';
};

export interface ICCContext {
  cc: CC;
  setCc: React.Dispatch<React.SetStateAction<CC>>;
  sort: string;
  setSort: React.Dispatch<React.SetStateAction<string>>;
  first: number;
  setFirst: React.Dispatch<React.SetStateAction<number>>;
  recordsPerPage: number;
  setRecordsPerPage: React.Dispatch<React.SetStateAction<number>>;
  oldRecordsPerPage: number;
  setOldRecordsPerPage: React.Dispatch<React.SetStateAction<number>>;
  viewMode: string;
  setViewMode: React.Dispatch<React.SetStateAction<string>>;
  browseMode: string;
  setBrowseMode: React.Dispatch<React.SetStateAction<string>>;
  data: { [key: string]: any };
  setData: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  isReady: boolean;
  setReadyState: (state: boolean) => void;
}

export const CCContext = createContext<ICCContext | undefined>(undefined);

interface CCProviderProps {
  children: React.ReactNode;
}

export const CCProvider: React.FC<CCProviderProps> = ({ children }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const defaultCCObj = new CC();
  let startUPCCObj = defaultCCObj;

  const state = queryParams.get('state');
  const monument = queryParams.get('monument');
  const theme = queryParams.get('theme');
  const id = queryParams.get('id');

  let startupViewMode = "homepage";

  if (id) {
    startupViewMode = 'list';
  }

  if (state) {
    startupViewMode = "list";

    if (state.startsWith('state_')) {
      ccUtils.getStateFromServer(state)
        .then(retrievedCcState => {
          startUPCCObj = ccUtils.deepMerge(defaultCCObj, retrievedCcState);
        })
        .catch(error => {
          console.error("Error retrieving state from server:", error);
        });
    } else {
      try {
        startUPCCObj = ccUtils.deepMerge(defaultCCObj, JSON.parse(state) as CC);
      } catch (error) {
        console.warn("Failed to parse state:", error);
        startUPCCObj = defaultCCObj;
      }
    }
  }

  const checkMonumentExists = async (monument: string) => {
    const query = `/record/webdep=[${encodeURIComponent(monument)}]`;
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}?action=get&command=search&query=${query}&fields=*&range=0-0&responseFormat=json`);
    const result = await response.json();
    return result?.request?.count > 0;
  };

  const initializeCC = () => {
    const updatedCustomSearchCriteria = startUPCCObj.customSearchCriteria || [];
    const updatedFacets = startUPCCObj.facets || {};

    if (monument) {
      updatedFacets.department = updatedFacets.department || [];
      updatedFacets.department.push(monument);
      /*
      const query = `/record/webdep=[${encodeURIComponent(monument)}]`;
      fetch(`${process.env.REACT_APP_BASE_URL}?action=get&command=search&query=${query}&fields=*&range=0-0&responseFormat=json`)
        .then(response => response.json())
        .then(result => {
          if (result?.request?.count > 0) {
            updatedFacets.department = updatedFacets.department || [];
            if (!updatedFacets.department.includes(monument)) {
              updatedFacets.department.push(monument);
            }
          }
        })
        .catch(error => console.error("Error checking monument:", error));
        */
    }

    if (theme) {
      if (theme === 'tapisseries') {
        const newCriterion = {
          field: '/record/theme',
          fieldLabel: 'Thème',
          searchValues: ["[thème: Tapisseries]"],
          description: 'La collection des tapisseries'
        };

        if (!updatedCustomSearchCriteria.some(
          criterion => criterion.field === newCriterion.field && 
                       JSON.stringify(criterion.searchValues) === JSON.stringify(newCriterion.searchValues)
        )) {
          updatedCustomSearchCriteria.push(newCriterion);
        }
      }
    }

    if (id) {
      const newCriterion = {
        field: '/record/objectnumber',
        fieldLabel: 'Object Number',
        searchValues: [id],
        description: ''
      };

      if (!updatedCustomSearchCriteria.some(
        criterion => criterion.field === newCriterion.field && 
                     JSON.stringify(criterion.searchValues) === JSON.stringify(newCriterion.searchValues)
      )) {
        updatedCustomSearchCriteria.push(newCriterion);
      }
    }

    startUPCCObj = {
      ...startUPCCObj,
      facets: updatedFacets,
      customSearchCriteria: updatedCustomSearchCriteria
    };

    return startUPCCObj;
  };

  const initializedCC = initializeCC();

  const instanceRef = useRef({});
  const navigate = useNavigate();
  const location = useLocation();
  const initialCC = initializedCC;
  const [cc, setCc] = useState(initialCC);
  const [lastUrl, setLastUrl] = useState<string | null>(null);
  const [sort, setSort] = useState<string>(Object.keys(ccConfig.sort)[0]);
  const [first, setFirst] = useState<number>(1);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(24);
  const [oldRecordsPerPage, setOldRecordsPerPage] = useState<number>(24);
  const [viewMode, setViewMode] = useState<string>(startupViewMode);
  const [browseMode, setBrowseMode] = useState<string>("paginate");
  const [user, setUser] = useState<any | null>(null);
  const [data, setData] = useState<{ [key: string]: any }>({
    records: {
      record: []
    }
  });
  const [isReady, setIsReady] = useState(false);
  const prevCcPushed = useRef("");

  const setReadyState = (state: boolean) => {
    setIsReady(state);
  };

  const pushState = (cc: CC, sort: string, first: number, recordsPerPage: number, viewMode: string, browseMode: string) => {
    const state = { cc, sort, first, recordsPerPage, viewMode, browseMode };
    const queryParams = new URLSearchParams(window.location.search);

    if (prevCcPushed.current !== JSON.stringify(state)) {
      prevCcPushed.current = JSON.stringify(state);

      navigate(`${window.location.pathname}?${queryParams.toString()}`, { state });
    }
  };

  useEffect(() => {
    if (isReady) pushState(cc, sort, first, recordsPerPage, viewMode, browseMode);
  }, [cc, sort, first, recordsPerPage, viewMode, browseMode]);

  useEffect(() => {
    if (isReady && location?.state && JSON.stringify(location.state) !== JSON.stringify({ cc, sort, first, recordsPerPage, viewMode, browseMode })) {
      prevCcPushed.current = JSON.stringify(location.state);
      setCc(location.state.cc);
      setSort(location.state.sort);
      setFirst(location.state.first);
      setRecordsPerPage(location.state.recordsPerPage);
      setViewMode(location.state.viewMode);
      setBrowseMode(location.state.browseMode);
    }
  }, [location]);

  const search = async (cc: CC) => {
    const payload = ccUtils.generatePayload(cc, sort, first, first + recordsPerPage - 1);
    try {
      const responseData = await ccUtils.postWithCache(process.env.REACT_APP_BASE_URL as string, payload, instanceRef);
      setData(responseData);
      setIsReady(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    search(cc);
  }, [cc, first, recordsPerPage]);

  return (
    <CCContext.Provider
      value={{ cc, setCc, sort, setSort, first, setFirst, recordsPerPage, setRecordsPerPage, oldRecordsPerPage, setOldRecordsPerPage, viewMode, setViewMode, browseMode, setBrowseMode, data, setData, user, setUser, isReady, setReadyState }}
    >
      {children}
    </CCContext.Provider>
  );
};

export const useCCContext = () => {
  const context = useContext(CCContext);
  if (!context) {
    throw new Error('useCCContext must be used within a CCProvider');
  }
  return context;
};
