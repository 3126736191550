import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CCProvider, useCCContext } from '../CC/CCContext';
import RecordIcon from '../Record/RecordIcon';
import RecordPopup from './RecordPopup';

function Icons() {
    const { t } = useTranslation();
    const [selectedRecord, setSelectedRecord] = useState<any | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const ctx = useCCContext();
    const { data } = useCCContext();

    useEffect(() => {
        setIsModalOpen(false);
    }, [ctx.cc, ctx.first]);

    if (!data.records || !data.records.record) {
        return <div>{t("no_records_found")}</div>;
    }

    const records = Array.isArray(data.records.record) ? data.records.record : [data.records.record];

    const selectRecord = (recordNumber: number) => {
        setSelectedRecord(recordNumber);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleLinkClick = (event: React.MouseEvent, index: number) => {
        event.preventDefault();
        selectRecord(ctx.first + index);
    };

    return (
        <div>
            {records.map((record: any, index: number) => (
                <RecordIcon
                    key={index}
                    record={record}
                    index={index}
                    handleLinkClick={handleLinkClick}
                />
            ))}
            {isModalOpen && (
                <RecordPopup recordNumber={selectedRecord} onClose={closeModal} />
            )}
        </div>
    );
}

export default Icons;
