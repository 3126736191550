import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './AlbumEditor.module.css';
import GenericIcon from '../GenericComponents/GenericIcon';
import genericStyles from '../GenericComponents/GenericStyles.module.css';
import GenericIconButton from '../GenericComponents/GenericIconButton';
import { useCCContext, Album } from '../CC/CCContext';
import EditorComponent from './EditorComponent';
import AnnotationControl from './AnnotationControl';

type AlbumEditorProps = {
    onClose?: () => void;
};

type NodeType = 'text' | 'annotatedImage';

type AlbumWithNodeType = Album & { nodeType: NodeType, albumPath: string };

const arrayToTree = (arr: AlbumWithNodeType[]): AlbumWithNodeType[] => {
    const tree: AlbumWithNodeType[] = [];
    const lookup: { [key: string]: AlbumWithNodeType } = {};

    arr.forEach(album => {
        album.childNodes = [];
        lookup[album.albumId] = album;
    });

    arr.forEach(album => {
        if (!album.albumParent) {
            tree.push(album);
        } else if (lookup[album.albumParent]) {
            lookup[album.albumParent].childNodes?.push(album);
        }
    });

    return tree;
};

const AlbumEditor: React.FC<AlbumEditorProps> = ({ onClose }) => {
    const { user, setUser } = useCCContext();
    const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
    const [curAlbum, setCurAlbum] = useState<AlbumWithNodeType | null>(null);
    const [editedAlbum, setEditedAlbum] = useState<AlbumWithNodeType | null>(null);
    const [curAlbumChanged, setCurAlbumChanged] = useState<boolean>(false);
    const [annotations, setAnnotations] = useState<{ imageName: string; annotations: any[] }>({ imageName: '', annotations: [] });
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const setupEditor = (editor: any) => {
        // Editor setup code...
    };

    const createNewAlbum = async (parentId?: string, nodeType: NodeType = 'text') => {
        // Create new album logic...
        const url = `${process.env.REACT_APP_USERMANAGEMENT_URL}`;
        const postData = {
            action: 'createalbum',
            albumParent: parentId,
            nodeType: nodeType,
            token: user.token
        };

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        });

        if (!response.ok) {
            console.error("Failed to create new album.");
            return;
        }

        const responseData = await response.json();

        const newAlbum: AlbumWithNodeType = {
            albumId: responseData.albumId,
            albumName: responseData.albumName,
            albumParent: responseData.albumParent,
            albumPath: '',
            albumText: '',
            childNodes: [],
            nodeType: nodeType
        };

        setUser(prevUser => {
            if (!prevUser) {
                return null;
            }
            return { ...prevUser, albums: [...prevUser.albums, newAlbum] };
        });
    };

    const toggleNodeExpansion = (albumId: string) => {
        setExpandedNodes(prevIds => {
            if (prevIds.includes(albumId)) {
                return prevIds.filter(id => id !== albumId);
            } else {
                return [...prevIds, albumId];
            }
        });
    };

    const moveNode = (draggedNodeId: string, targetNodeId: string) => {
        setUser(prevUser => {
            if (!prevUser) {
                return null;
            }

            const updatedAlbums = prevUser.albums.map(album => {
                if (album.albumId === draggedNodeId) {
                    return { ...album, albumParent: targetNodeId };
                }
                return album;
            });

            return { ...prevUser, albums: updatedAlbums };
        });
    };

    const handleDragStart = (e: React.DragEvent<HTMLDivElement>, node: AlbumWithNodeType) => {
        e.dataTransfer.setData("text/plain", node.albumId);
    };

    const handleTreeDrop = async (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        const draggedNodeId = e.dataTransfer.getData("text/plain");

        if (!draggedNodeId) {
            return;
        }

        moveNode(draggedNodeId, "");

        const url = `${process.env.REACT_APP_USERMANAGEMENT_URL}`;

        const postData = {
            action: 'changealbum',
            albumId: draggedNodeId,
            albumParent: "",
            token: user.token
        };

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        });

        if (response.ok) {
            console.log("Album details saved successfully!");
        } else {
            console.error("Failed to save album details.");
        }
    };

    const handleDrop = async (e: React.DragEvent<HTMLDivElement>, targetNode: AlbumWithNodeType) => {
        e.preventDefault();
        e.stopPropagation();

        const draggedNodeId = e.dataTransfer.getData("text/plain");

        if (!draggedNodeId) {
            return;
        }

        moveNode(draggedNodeId, targetNode.albumId);
        const url = `${process.env.REACT_APP_USERMANAGEMENT_URL}`;

        const postData = {
            action: 'changealbum',
            albumId: draggedNodeId,
            albumParent: targetNode.albumId,
            token: user.token
        };

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        });

        if (response.ok) {
            console.log("Album details saved successfully!");
        } else {
            console.error("Failed to save album details.");
        }
    };

    type TreeNodeProps = {
        node: AlbumWithNodeType;
        onNodeClick: (node: AlbumWithNodeType) => void;
        expandedNodes: string[];
        toggleNodeExpansion: (albumPath: string) => void;
    };

    const TreeNode: React.FC<TreeNodeProps> = ({ node, onNodeClick, expandedNodes, toggleNodeExpansion }) => {
        const isExpanded = expandedNodes.includes(node.albumId);
        const [showContextMenu, setShowContextMenu] = useState(false);
        const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
        const menuRef = useRef<HTMLDivElement>(null);
        const [contextMenuNode, setContextMenuNode] = useState<AlbumWithNodeType | null>(null);
        const [overNode, setOverNode] = useState<string | null>(null);

        const handleRightClick = (e: React.MouseEvent, node: AlbumWithNodeType) => {
            e.preventDefault();
            const rect = e.currentTarget.getBoundingClientRect();
            setContextMenuPosition({ x: rect.left - 10, y: rect.bottom - 30 });
            setShowContextMenu(true);
            setContextMenuNode(node);
        };

        const handleToggle = () => {
            toggleNodeExpansion(node.albumId);
        };

        const renderContextMenu = () => {
            const { x, y } = contextMenuPosition;

            const contextMenuItems = [
                {
                    label: 'New album',
                    onClick: () => {
                        setShowContextMenu(false);
                        createNewAlbum(contextMenuNode?.albumId);
                    }
                },
                {
                    label: 'New annotated image',
                    onClick: () => {
                        setShowContextMenu(false);
                        createNewAlbum(contextMenuNode?.albumId, 'annotatedImage');
                    }
                }
            ];

            return (
                <div
                    ref={menuRef}
                    className={styles.contextMenu}
                    style={{ position: 'absolute', top: y + 'px', left: x + 'px' }}
                >
                    {contextMenuItems.map((item, index) => (
                        <div
                            key={index}
                            className={styles.contextMenuItem}
                            onClick={item.onClick}
                        >
                            {item.label}
                        </div>
                    ))}
                </div>
            );
        };

        const handleOutsideClick = (e: MouseEvent) => {
            if (showContextMenu && e.target && !menuRef.current?.contains(e.target as Node)) {
                setShowContextMenu(false);
            }
        };

        useEffect(() => {
            if (showContextMenu) {
                document.addEventListener('mousedown', handleOutsideClick);
            } else {
                document.removeEventListener('mousedown', handleOutsideClick);
            }
            return () => {
                document.removeEventListener('mousedown', handleOutsideClick);
            };
        }, [showContextMenu]);

        return (
            <div>
                <div>
                    <div
                        className={styles.expandButtonContainer}
                        onClick={node.childNodes && node.childNodes.length > 0 ? handleToggle : undefined}
                    >
                        {isExpanded ? (
                            <div className={styles.expandButton}>
                                <GenericIcon icon="regular/minus.svg" />
                            </div>
                        ) : (
                            node.childNodes && node.childNodes.length > 0 && (
                                <div className={styles.expandButton}>
                                    <GenericIcon icon="regular/plus.svg" />
                                </div>
                            )
                        )}
                    </div>

                    <div
                        className={`${styles.nodeText} ${curAlbum && curAlbum.albumId === node.albumId ? styles.selectedNode : ''}`}
                        onClick={() => onNodeClick(node)}
                        onContextMenu={(e) => handleRightClick(e, node)}
                        draggable={true}
                        onDragStart={(e: React.DragEvent<HTMLDivElement>) => handleDragStart(e, node)}
                        onDragOver={(e: React.DragEvent<HTMLDivElement>) => e.preventDefault()}
                        onDragEnter={() => setOverNode(node.albumId)}
                        onDragLeave={() => setOverNode(null)}
                        onDrop={(e: React.DragEvent<HTMLDivElement>) => handleDrop(e, node)}
                        style={overNode === node.albumId ? { backgroundColor: 'lightgray' } : {}}
                    >
                        {node.albumName}
                    </div>

                    {showContextMenu && renderContextMenu()}
                </div>
                {isExpanded && node.childNodes && node.childNodes.map(childNode => (
                    <div key={childNode.albumId} className={styles.children} style={{ marginLeft: '20px' }}>
                        <TreeNode
                            node={childNode}
                            onNodeClick={handleNodeClick}
                            expandedNodes={expandedNodes}
                            toggleNodeExpansion={toggleNodeExpansion}
                        />
                    </div>
                ))}
            </div>
        );
    };

    // Ensure that user.albums contains nodeType
    const albumsWithNodeType: AlbumWithNodeType[] = user.albums.map(album => ({
        ...album,
        nodeType: album.nodeType || 'text' // default to 'text' if nodeType is not set
    }));

    const treeData = arrayToTree(albumsWithNodeType);

    useEffect(() => {
        if (curAlbum && curAlbum.nodeType === 'annotatedImage' && curAlbum.albumText) {
            try {
                const parsedAnnotations = JSON.parse(curAlbum.albumText);
                setAnnotations(parsedAnnotations);
            } catch (error) {
                console.error("Failed to parse annotations:", error);
                setAnnotations({ imageName: '', annotations: [] });
            }
        } else {
            setAnnotations({ imageName: '', annotations: [] });
        }
    }, [curAlbum]);

    const handleNodeClick = async (clickedAlbum: AlbumWithNodeType) => {
        console.log(`Clicked on node: ${clickedAlbum.albumName}`);

        if (curAlbumChanged && !window.confirm("You have unsaved changes. Do you really want to switch albums?")) {
            return;
        }

        const response = await fetch(`${process.env.REACT_APP_USERMANAGEMENT_URL}?action=getalbum&albumId=${clickedAlbum.albumId}&token=${user.token}`);
        if (response.ok) {
            const downloadedAlbum = await response.json();
            const updatedAlbum = { ...downloadedAlbum, nodeType: downloadedAlbum.nodeType || 'text' };
            setCurAlbum(updatedAlbum);
            setEditedAlbum(updatedAlbum);
            setCurAlbumChanged(false);
        } else {
            console.error("Failed to fetch album details.");
        }
    };

    const handleClose = () => {
        if (curAlbumChanged && !window.confirm("You have unsaved changes. Do you really want to close the editor?")) {
            return;
        }
        if (onClose) onClose();
    };

    const handleSave = async () => {
        if (!editedAlbum) return;

        let albumTextToSave = editedAlbum.albumText;
        if (editedAlbum.nodeType === 'annotatedImage') {
            albumTextToSave = JSON.stringify({ imageName: annotations.imageName, annotations: annotations.annotations });
        }

        setCurAlbum(prev => ({ ...prev!, albumName: editedAlbum.albumName, albumText: albumTextToSave }));
        setCurAlbumChanged(false);

        setUser(prevUser => {
            if (!prevUser) {
                return null;
            }
            const updatedAlbums = prevUser.albums.map(album =>
                album.albumId === editedAlbum.albumId ? { ...editedAlbum, albumText: albumTextToSave } : album
            );
            return { ...prevUser, albums: updatedAlbums };
        });

        const url = `${process.env.REACT_APP_USERMANAGEMENT_URL}`;

        const postData = {
            action: 'changealbum',
            albumId: editedAlbum.albumId,
            albumText: albumTextToSave,
            albumName: editedAlbum.albumName,
            token: user.token
        };

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        });

        if (response.ok) {
            console.log("Album details saved successfully!");

            if (selectedFile) {
                const formData = new FormData();
                formData.append('file', selectedFile);
                formData.append('albumId', editedAlbum.albumId);
                formData.append('action', 'upload');

                const imageResponse = await fetch(`${process.env.REACT_APP_USERMANAGEMENT_URL}`, {
                    method: 'POST',
                    body: formData
                });

                if (imageResponse.ok) {
                    const imageData = await imageResponse.json();
                    setAnnotations(prev => ({ ...prev, imageName: imageData.fileName }));
                    setSelectedFile(null);
                } else {
                    console.error('Error uploading file:', imageResponse.statusText);
                }
            }
        } else {
            console.error("Failed to save album details.");
        }
    };

    const handleDelete = async () => {
        if (!curAlbum) return;

        const confirmed = window.confirm("Are you sure you want to delete this album?");
        if (!confirmed) return;

        const url = `${process.env.REACT_APP_USERMANAGEMENT_URL}`;

        const postData = {
            action: 'deletealbum',
            albumId: curAlbum.albumId,
            token: user.token
        };

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        });

        if (response.ok) {
            const res = await response.text();
            console.log(res);
            console.log("Album deleted successfully!");

            setUser(prevUser => {
                if (!prevUser) {
                    return null;
                }
                const updatedAlbums = prevUser.albums.filter(album => album.albumId !== curAlbum.albumId);

                updatedAlbums.forEach(album => {
                    if (album.albumParent === curAlbum.albumId) {
                        album.albumParent = "";
                    }
                });

                return { ...prevUser, albums: updatedAlbums };
            });

            setCurAlbum(null);
            setEditedAlbum(null);
            setCurAlbumChanged(false);

        } else {
            console.error("Failed to delete the album.");
        }
    };

    useEffect(() => {
        const handleUnload = (e: BeforeUnloadEvent) => {
            if (curAlbumChanged) {
                e.preventDefault();
                e.returnValue = "You have unsaved changes. Do you really want to leave?";
            }
        };

        window.addEventListener('beforeunload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, [curAlbumChanged]);

    const handleCancel = async () => {
        const confirmed = window.confirm("Are you sure you want to cancel the changes?");
        if (confirmed && curAlbum) {
            setCurAlbum(null);
            setCurAlbumChanged(false);
            const response = await fetch(`${process.env.REACT_APP_USERMANAGEMENT_URL}?action=getalbum&albumId=${curAlbum.albumId}&token=${user.token}`);
            if (response.ok) {
                const downloadedAlbum = await response.json();
                setCurAlbum({ ...downloadedAlbum, nodeType: downloadedAlbum.nodeType || 'text' });
                setEditedAlbum({ ...downloadedAlbum, nodeType: downloadedAlbum.nodeType || 'text' });
            } else {
                console.error("Failed to revert to the original album details.");
            }
        }
    };

    return (
        <div className={styles.popupoverlay}>
            <div className={styles.popup}>
                <div className={styles.header}>
                    <span>Album Editor</span>
                    <GenericIconButton icon='light/times.svg' width='18px' height='18px' onClick={handleClose} />
                </div>

                <div className={styles.main}>
                    <div className={styles.leftColumn}>
                        <div className={styles.treeView} onDrop={handleTreeDrop} onDragOver={e => e.preventDefault()}>
                            <div>
                                {treeData.map(node => (
                                    <TreeNode
                                        key={node.albumId}
                                        node={node}
                                        onNodeClick={handleNodeClick}
                                        expandedNodes={expandedNodes}
                                        toggleNodeExpansion={toggleNodeExpansion}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className={styles.leftColumnFooter}>
                            <button onClick={() => createNewAlbum()} className={genericStyles.GenericButton}>New album</button>
                            <button onClick={() => createNewAlbum(undefined, 'annotatedImage')} className={genericStyles.GenericButton}>New annotated image</button>
                        </div>
                    </div>
                    {
                        curAlbum && (
                            <div className={styles.editor}>
                                <div className={styles.editorHeader}>
                                    Title: <input type="text" value={editedAlbum?.albumName} onChange={(e) => { setCurAlbumChanged(true); setEditedAlbum(prev => ({ ...prev!, albumName: e.target.value })) }} />
                                    &nbsp;AlbumId: {curAlbum.albumId}
                                </div>

                                <div className={styles.editorContent}>
                                    {curAlbum.nodeType === 'text' ? (
                                        <EditorComponent
                                            content={curAlbum?.albumText || ''}
                                            onContentChange={(newContent) => {
                                                setCurAlbumChanged(newContent !== editedAlbum?.albumText);
                                                setEditedAlbum(prev => {
                                                    if (!prev) return null;
                                                    return { ...prev, albumText: newContent };
                                                });
                                            }}
                                        />
                                    ) : (
                                        <div className={styles.scrollableAnnotationContainer}>
                                            <AnnotationControl
                                                annotations={annotations}
                                                setAnnotations={(newAnnotations: any) => {
                                                    setAnnotations(newAnnotations);
                                                    setCurAlbumChanged(true);
                                                }}
                                                albumId={curAlbum.albumId}
                                                onFileSelect={(file: any) => setSelectedFile(file)}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className={styles.editorFooter}>
                                    <button disabled={!curAlbumChanged} className={genericStyles.GenericButton} onClick={handleSave}>Save</button>
                                    <button className={genericStyles.GenericButton} onClick={handleDelete}>Delete</button>
                                    <button disabled={!curAlbumChanged} className={genericStyles.GenericButton} onClick={handleCancel}>Cancel</button>
                                </div>
                            </div>
                        )
                    }
                </div>

                <div className={styles.footer}>
                    <button className={genericStyles.GenericButton} onClick={handleClose}>Close</button>
                </div>
            </div>
        </div>
    );
};

export default AlbumEditor;
