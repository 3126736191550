import React from 'react';
import { CCProvider, useCCContext } from '../CC/CCContext';

import DraggablePopup from './DraggablePopup';
import styles from './DebugCC.module.css';

interface DebugCCProps {
    onClose: () => void;
}

function DebugCC({ onClose }: DebugCCProps) {
    const { cc, setCc, first, setFirst, recordsPerPage, setRecordsPerPage, viewMode, setViewMode, browseMode, setBrowseMode, data, setData } = useCCContext();
    
    return (
        <div className={styles.resizable_popup}>
            <DraggablePopup title="Debug Information" onClose={onClose}>
                <div className={styles.scrollable_content}>
                    <pre>
                        {JSON.stringify({ cc, pagination: { first, recordsPerPage }, viewMode, browseMode }, null, 2)}
                    </pre>
                </div>
            </DraggablePopup>
        </div>
    );
}

export default DebugCC;
