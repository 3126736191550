import React from 'react';
import GenericIconButton from '../GenericComponents/GenericIconButton';
import styles from './BrowseMode.module.css';
import { CCProvider, useCCContext } from '../CC/CCContext';

function BrowseMode() {
  const { cc, setCc, first, setFirst, recordsPerPage, setRecordsPerPage, viewMode, setViewMode, browseMode, setBrowseMode, data, setData } = useCCContext();
  
  const changeModeToPaginate = () => {
    setBrowseMode('paginate');
    setCc(cc);
  }
  
  const changeModeToScroll = () => {
    setBrowseMode('scroll');
    setCc(cc);
  }
  return (
    <div className={styles.BrowseMode}>
      <GenericIconButton isActive={browseMode === 'paginate'} icon='light/book-open.svg' onClick={ () => changeModeToPaginate() }/>
      <GenericIconButton isActive={browseMode === 'scroll'} icon='light/scroll.svg' onClick={ () => changeModeToScroll() }/>
    </div>
  );
}

export default BrowseMode;
