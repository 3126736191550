import { useEffect, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearchPlus, faSearchMinus, faExpand, faUndoAlt, faRedoAlt, faAdjust, faSlidersH, faDownload } from '@fortawesome/free-solid-svg-icons';
import styles from './Map.module.css';
import { useCCContext } from '../CC/CCContext';
import { generateQuery } from '../CC/utils';
import * as ccUtils from '../CC/utils';

interface OpenSeadragonViewerProps {
  dzi: string | any;
}

type CountryCountType = {
  value: string;
  count: string;
};

type ContinentCountType = {
  value: string;
  count: string;
};

type CountryType = {
  country: string;
  count: number;
  x: number;
  y: number;
};

type ContinentType = {
  continent: string;
  count: number;
  x: number;
  y: number;
};

const countryHTML = (name: string, count: number, isSelected: boolean): string => `
  <div>
    <div style='display:block'>
      <div class='country' data-name='${name}'>
        <span>${count}</span>
        ${isSelected ? "<span class='redDot'></span>" : ""}
      </div>
    </div>
  </div>
`;

const continentHTML = (name: string, count: number, isSelected: boolean): string => `
  <div>
    <div style='display:block'>
      <div class='continent' data-name='${name}'>
        <span>${count}</span>
        ${isSelected ? "<span class='redDot'></span>" : ""}
      </div>
    </div>
  </div>
`;

const Map: React.FC<OpenSeadragonViewerProps> = ({ dzi }) => {
  const { t } = useTranslation();
  const instanceRef = useRef({});
  const cutoffZoomlevel = 3;
  const ctx = useCCContext();
  const viewerRef = useRef<HTMLDivElement>(null);
  const osdViewerRef = useRef<any>(null);
  const [countries, setCountries] = useState<CountryType[]>([]);
  const [continents, setContinents] = useState<ContinentType[]>([]);
  const { cc, setCc, first, setFirst, recordsPerPage, setRecordsPerPage, viewMode, setViewMode, browseMode, setBrowseMode, data, setData, isReady } = useCCContext();

  const showingCountries = useRef<boolean>(true);
  const zoomLoaded = useRef<boolean>(false);

  const fetchData = async () => {
    //console.log('FETCHING DATA');

    if (!zoomLoaded.current) { return; }

    const baseCountries: CountryType[] = await fetch(process.env.PUBLIC_URL + '/countries.json').then(response => response.json());
    const query = generateQuery(ctx.cc, { excludeFacet: 'countries' });

    const postData = {
      action: "get",
      command: "facets",
      fields: "/Record/Country/Term:1-500:**",
      query: query,
      sort: "alpha",
      responseformat: "json"
    };

    // Make the async call
    try {
      const data: { facets?: { facet?: { value: CountryCountType | CountryCountType[] } } } = await ccUtils.postWithCache(process.env.REACT_APP_BASE_URL as string, postData, instanceRef);

      // Process the response
      if (data && data.facets && data.facets.facet && data.facets.facet.value) {
        let countriesCountData = data.facets.facet.value;

        // Ensure the value is an array
        if (!Array.isArray(countriesCountData)) {
          countriesCountData = [countriesCountData];
        }

        const countriesWithCount = baseCountries.map(baseCountry => {
          const matchedCountry = (countriesCountData as CountryCountType[]).find((item: CountryCountType) => item.value === baseCountry.country);
          return {
            ...baseCountry,
            count: matchedCountry ? parseInt(matchedCountry.count) : 0
          };
        });
        setCountries(countriesWithCount);
      } else {
        //console.error('Unexpected data structure for countries:', data);
      }
    } catch (e: any) {
      console.log("Error getting country data");
      setTimeout(() => { fetchData() }, 1000);
    }

    const baseContinents: ContinentType[] = await fetch(process.env.PUBLIC_URL + '/continents.json').then(response => response.json());
    const continentQuery = generateQuery(ctx.cc, { excludeFacet: 'continents' });
    const continentPostData = {
      action: "get",
      command: "facets",
      fields: "/Record/Continent/Term:1-500:**",
      query: continentQuery,
      sort: "alpha",
      responseformat: "json"
    };

    try {
      const continentData: { facets?: { facet?: { value: ContinentCountType | ContinentCountType[] } } } = await ccUtils.postWithCache(process.env.REACT_APP_BASE_URL as string, continentPostData, instanceRef);
      if (continentData && continentData.facets && continentData.facets.facet && continentData.facets.facet.value) {
        let continentsCountData = continentData.facets.facet.value;

        // Ensure the value is an array
        if (!Array.isArray(continentsCountData)) {
          continentsCountData = [continentsCountData];
        }

        const continentsWithCount = baseContinents.map(baseContinent => {
          const matchedContinent = (continentsCountData as ContinentCountType[]).find((item: ContinentCountType) => item.value === baseContinent.continent);
          return {
            ...baseContinent,
            count: matchedContinent ? parseInt(matchedContinent.count) : 0
          };
        });
        setContinents(continentsWithCount);
      } else {
        //console.error('Unexpected data structure for continents:', continentData);
      }
    } catch (e: any) {
      console.log('Error getting continent data');
      setTimeout(() => { fetchData() }, 1000);
    }
  }

  const debounce = (func: Function, wait: number) => {
    let timeout: NodeJS.Timeout;
    return function executedFunction(...args: any[]) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const addOverlays = useCallback((items: (CountryType | ContinentType)[]) => {
    //console.log(items);
    if (!osdViewerRef.current) return;
    items.forEach((item) => {
      if (item.count > 0) {
        const mountPoint = document.createElement('div');
        const isSelected = 'country' in item
          ? ctx.cc.facets["countries"] && ctx.cc.facets["countries"].includes(item.country)
          : ctx.cc.facets["continents"] && ctx.cc.facets["continents"].includes(item.continent);

        const htmlContent = 'country' in item
          ? countryHTML(item.country, item.count, isSelected)
          : continentHTML(item.continent, item.count, isSelected);

        mountPoint.innerHTML = htmlContent;

        osdViewerRef.current.addOverlay({
          element: mountPoint,
          location: new (window as any).OpenSeadragon.Rect(item.x, item.y, 0, 0)
        });
        var tracker = new (window as any).OpenSeadragon.MouseTracker({
          element: mountPoint,
          clickHandler: function () {
            if ('continent' in item) {
              if (ctx.cc.facets["continents"] && ctx.cc.facets["continents"].includes(item.continent)) {
                // If continent is already selected, remove it
                ccUtils.removeFacetValue(ctx, "continents", item.continent);
              } else {
                // Otherwise, add it
                ccUtils.addFacetValue(ctx, "continents", item.continent);
              }
            } else {
              if (ctx.cc.facets["countries"] && ctx.cc.facets["countries"].includes(item.country)) {
                // If country is already selected, remove it
                ccUtils.removeFacetValue(ctx, "countries", item.country);
              } else {
                // Otherwise, add it
                ccUtils.addFacetValue(ctx, "countries", item.country);
              }
            }
          }
        });
      }
    });
  }, [ctx]);

  const showCorrectOverlays = (clearFirst: boolean) => {
    //console.log('showing: '+clearFirst);
    if (osdViewerRef.current && osdViewerRef.current.viewport) {
      const zoom = osdViewerRef.current.viewport.getZoom();
      if (clearFirst) {
        //cc changed
        osdViewerRef.current.clearOverlays();
        if (zoom < cutoffZoomlevel) {
          addOverlays(continents);
        }
        else {
          addOverlays(countries);
        }
      } else {
        //zoom changed
        if (zoom < cutoffZoomlevel) {
          if (showingCountries.current) {
            showingCountries.current = false;
            osdViewerRef.current.clearOverlays();
            addOverlays(continents);
          }
        } else {
          if (!showingCountries.current) {
            showingCountries.current = true;
            osdViewerRef.current.clearOverlays();
            addOverlays(countries);
          }
        }
      }
    }
  }

  useEffect(() => {
    //console.log('cc changed', ctx.cc);
    fetchData();
  }, [ctx.cc]);

  useEffect(() => {
    if (osdViewerRef.current && continents.length > 0 && osdViewerRef.current.viewport.getZoom() < cutoffZoomlevel) {
      showCorrectOverlays(true);
    }
  }, [continents]);

  useEffect(() => {
    if (osdViewerRef.current && countries.length > 0 && osdViewerRef.current.viewport.getZoom() >= cutoffZoomlevel) {
      showCorrectOverlays(true);
    }
  }, [countries]);

  useEffect(() => {
    const handleZoom = debounce(() => {
      if (osdViewerRef.current) {
        showCorrectOverlays(false);
      }
    }, 200);

    if (osdViewerRef.current) {
      osdViewerRef.current.addHandler('zoom', handleZoom);
    }

    return () => {
      if (osdViewerRef.current) {
        osdViewerRef.current.removeHandler('zoom', handleZoom);
      }
    };
  }, [countries, continents /*, addOverlays*/]);

  useEffect(() => {
    if (viewerRef.current) {
      const OpenSeadragon = (window as any).OpenSeadragon;
      if (viewerRef.current) {
        if (osdViewerRef.current) {
          osdViewerRef.current.destroy();
        }

        osdViewerRef.current = new OpenSeadragon({
          id: 'viewer',
          //element: viewerRef.current,
          crossOriginPolicy: 'Anonymous',
          prefixUrl: 'https://openseadragon.github.io/openseadragon/images/',
          tileSources: dzi,
          showRotationControl: false,
          toolbar: 'viewerToolbar',
          //zoomInButton: 'zoombutton_zoom-in',
          //zoomOutButton: 'zoombutton_zoom-out',
          //homeButton: 'zoombutton_home',
          //fullPageButton: 'zoombutton_full-page',
          //rotateLeftButton: 'zoombutton_rotate_left',
          //rotateRightButton: 'zoombutton_rotate_right'
          zoomInButton: 'hiddenButton',
          zoomOutButton: 'hiddenButton',
          homeButton: 'hiddenButton',
          fullPageButton: 'hiddenButton',
          rotateLeftButton: 'hiddenButton',
          rotateRightButton: 'hiddenButton'
        }) as any;

        osdViewerRef.current.addHandler('open', (event: any) => {
          zoomLoaded.current = true;
          fetchData();
        });

      }
    }

    return () => {
      if (osdViewerRef.current) {
        osdViewerRef.current.destroy();
      }
    };
  }, [dzi]);

  const handleHomeClick = () => {
    osdViewerRef.current.viewport.goHome();
  };

  const handleZoomInClick = () => {
    osdViewerRef.current.viewport.zoomBy(2.0);
  };

  const handleZoomOutClick = () => {
    osdViewerRef.current.viewport.zoomBy(1 / 2.0);
  };

  const handleFullscreenClick = () => {

    if (document.fullscreenElement !== null) {
      //isFullscreen.current=false;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).mozCancelFullScreen) { // Firefox
        (document as any).mozCancelFullScreen();
      } else if ((document as any).webkitExitFullscreen) { // Chrome, Safari and Opera
        (document as any).webkitExitFullscreen();
      } else if ((document as any).msExitFullscreen) { // IE/Edge
        (document as any).msExitFullscreen();
      }
    } else {
      //isFullscreen.current=true;
      const target = document.getElementById('zoomedImage') as HTMLDivElement;

      if (target.requestFullscreen) {
        target.requestFullscreen();
      } else if ((target as any).mozRequestFullScreen) { // Firefox
        (target as any).mozRequestFullScreen();
      } else if ((target as any).webkitRequestFullscreen) { // Chrome, Safari and Opera
        (target as any).webkitRequestFullscreen();
      } else if ((target as any).msRequestFullscreen) { // IE/Edge
        (target as any).msRequestFullscreen();
      }
    }
  };


  return (
    <div id="zoomedImage" className={styles.ZoomedImage} >

      <div ref={viewerRef} id="viewer" className={styles.viewerBody} />
      <div id='viewerToolbar' className={styles.viewerToolbar} >
        <div className={styles.toolbarItem} id='zoombutton_home' onClick={handleHomeClick}>
          <FontAwesomeIcon icon={faHome} />
        </div>
        <div className={styles.toolbarItem} id='zoombutton_zoom-in' onClick={handleZoomInClick}>
          <FontAwesomeIcon icon={faSearchPlus} />
        </div>
        <div className={styles.toolbarItem} id='zoombutton_zoom-out' onClick={handleZoomOutClick}>
          <FontAwesomeIcon icon={faSearchMinus} />
        </div>
        <div className={styles.toolbarItem} id='zoombutton_full-page' onClick={handleFullscreenClick}>
          <FontAwesomeIcon icon={faExpand} />
        </div>
      </div>
    </div>
  );
};

export default Map;
