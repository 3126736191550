import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ccConfig, CC, Search, SearchType } from '../CC/CC';
import { CCProvider, useCCContext } from '../CC/CCContext';
import GenericCheckbox from '../GenericComponents/GenericCheckbox';
import GenericIconButton from '../GenericComponents/GenericIconButton';
import GenericModal from '../GenericComponents/GenericModal';
import * as ccUtils from '../CC/utils';
import styles from './MobileSearch.module.css';
import MobileQuerySummary from './MobileQuerySummary';
import Count from '../Navigator/Count';
import MobileBrowseValues from './MobileBrowseValues';
import MobileScrollResults from './MobileScrollResults';
import MobileMap from './MobileMap';
import Icons from '../Result/Icons';
import GenericIcon from '../GenericComponents/GenericIcon';

interface SearchValue {
    source: string;
    text: string;
}

type SearchProps = {
    mainY: number;
    setAndKeepMainY: (newMainY: number, prevMainY: number) => void;
    selectedRecord: any | null;
    setSelectedRecord: React.Dispatch<React.SetStateAction<any | null>>;
};

const MobileSearch: React.FC<SearchProps> = ({ mainY, setAndKeepMainY, selectedRecord, setSelectedRecord }) => {
    const { t } = useTranslation();
    const { cc, setCc, viewMode, setViewMode } = useCCContext();
    const ctx = useCCContext();
    const [selectedField, setSelectedField] = useState<string>(Object.keys(ccConfig.mobileSearchFields)[0]);
    const [searchText, setSearchText] = useState<string>("");
    const [showMap, setShowMap] = useState<boolean>(false);
    const [showBrowseValues, setShowBrowseValues] = useState(false);
    const [showScrollToTop, setShowScrollToTop] = useState(false); // State to manage visibility of scroll-to-top button
    const topRef = useRef<HTMLDivElement | null>(null);
    const pageRef = useRef<HTMLDivElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    setViewMode("icons");

    useEffect(() => {
        const handleScroll = () => {
            if (pageRef?.current?.scrollTop && pageRef?.current?.scrollTop > 200) { // Show button after scrolling down 200px
                setShowScrollToTop(true);
            } else {
                setShowScrollToTop(false);
            }
        };

        const currentRef = pageRef.current;
        if (currentRef) {
            currentRef.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const showHomepage = () => {
        setAndKeepMainY(-100, -200);
    }

    const showMenu = () => {
        setAndKeepMainY(-400, -200);
    }

    const handleValueSelection = (selectedValue: string) => {
        if (topRef.current) {
            topRef.current.scrollIntoView();
        }
        setSearchText("");
        setShowBrowseValues(!showBrowseValues);

        let encodedValue = selectedValue.replace(/\[/g, encodeURIComponent('[')).replace(/\]/g, encodeURIComponent(']'));

        setTimeout(() => {
            // Check if search criteria already exist for the selected field
            const existingCriterion = cc.customSearchCriteria.find(criterion => criterion.field === ccConfig.mobileSearchFields[selectedField].field);

            if (existingCriterion) {
                // If they exist, add the new value to the existing criteria
                const newValues = [...existingCriterion.searchValues, `[${encodedValue}]`];
                // Ensure the new values are unique
                const uniqueValues = Array.from(new Set(newValues));

                const updatedCriterion = {
                    ...existingCriterion,
                    searchValues: uniqueValues
                };

                setCc(prevCc => ({
                    ...prevCc,
                    customSearchCriteria: prevCc.customSearchCriteria.map(criterion =>
                        criterion.field === existingCriterion.field ? updatedCriterion : criterion
                    )
                }));
            } else {
                // If they do not exist, add the new criteria
                ccUtils.addCustomSearch(ctx, ccConfig.mobileSearchFields[selectedField].field, selectedField, `[${encodedValue}]`);
            }
        }, 10);
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            executeSearch();
        }
    };

    const executeSearch = () => {
        if (searchText.trim() != "") {
            ccUtils.addCustomSearch(ctx, ccConfig.mobileSearchFields[selectedField].field, selectedField, searchText);
        }
        setSearchText("");
        setShowBrowseValues(false);
    };

    const handleMatchAllChange = (checked: boolean, value: string) => {
        //setMatchAll(checked);
        setCc({
            ...cc,
            matchAllCriteria: checked,
        });
    };

    const shouldExcludeCollection = () => {
        const query = ccUtils.generateQuery(cc);
        //console.log(query, !(query.indexOf('thème: Tapisseries') > 0 || query.indexOf('theme: Tapisseries') > 0));
        return !(query.indexOf('thème: Tapisseries') > 0 || query.indexOf('theme: Tapisseries') > 0);
    };

    return (
        <div ref={pageRef} className={styles.MobileSearch}>
            <div ref={topRef} className={styles.header}>
                <div className={styles.header_logo}
                    onClick={(e: any) => {
                        e.preventDefault();
                        setViewMode('homepage');
                    }}>
                    <div>
                        <div className={styles.header_logo_text}>Collections</div>
                        <div className={styles.header_logo_svg}>
                            <img src="header_logo.svg" />
                        </div>
                    </div>
                </div>
                <div className={styles.topRightButton}>
                    <GenericIconButton
                        icon='light/house.svg'
                        hoverEnabled={true}
                        width='28px'
                        height='28px'
                        onClick={(e: any) => {
                            showHomepage()
                        }}
                    />
                </div>
            </div>
            <div className={styles.searchbox}>
                <span style={{ display: 'none' }}>
                    <GenericCheckbox
                        id={'mobileSearchConformToAllCriteria'}
                        name={'mobileSearchConformToAllCriteria'}
                        value={''}
                        checked={cc.matchAllCriteria}
                        onChange={handleMatchAllChange}
                        label={t("mobile_all_criteria")}
                    />
                </span>
                <select
                    value={selectedField}
                    onChange={e => {
                        setSelectedField(e.target.value);
                        // Check if the selected field has browse set to true
                        if (ccConfig.mobileSearchFields[e.target.value].browse) {
                            setShowBrowseValues(true);
                        } else {
                            setShowBrowseValues(false);
                        }
                        if (inputRef.current) {
                            inputRef.current.focus();
                        }
                    }}
                >
                    {Object.keys(ccConfig.mobileSearchFields)
                        .filter(key => !(shouldExcludeCollection() && key === 'Collection'))
                        .map(key => (
                            <option key={key} value={key}>{t(key)}</option>
                        ))}
                </select>

                <div className={styles.searchInputContainer}>
                    <input
                        ref={inputRef}
                        type="text"
                        value={searchText}
                        onChange={e => {
                            setSearchText(e.target.value);
                            if (e.target.value) {
                                setShowBrowseValues(true);
                            }
                        }}
                        onKeyPress={handleKeyPress}
                    />
                    {ccConfig.mobileSearchFields[selectedField].browse ? (
                        <GenericIconButton isActive={showBrowseValues} width='32px' height='32px' icon='light/filter.svg' onClick={() => setShowBrowseValues(!showBrowseValues)} />
                    ) : ("")
                    }
                </div>

                <button onClick={executeSearch}>{t("mobile_search")}</button>

            </div>

            <div className={`${styles.browseValues} ${showBrowseValues ? styles.show : ''}`}>
                {ccConfig.mobileSearchFields[selectedField].browse ? (
                    <MobileBrowseValues field={ccConfig.mobileSearchFields[selectedField].field} searchText={searchText} onValueSelected={handleValueSelection}></MobileBrowseValues>
                ) : ("")
                }
            </div>

            <div className={`${styles.mobileResults} ${showBrowseValues ? '' : styles.show}`}>
                <MobileQuerySummary></MobileQuerySummary>
                <MobileScrollResults mainY={mainY} setAndKeepMainY={setAndKeepMainY} selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord} />
            </div>

            {showMap &&
                <div className={styles.modalMap}>
                    <div className={styles.map}>
                        <div className={styles.closeButton}>
                            <GenericIconButton icon='light/times.svg' width='28px' height='28px' onClick={() => { setShowMap(false) }} />
                        </div>
                        <MobileMap dzi="https://collections.quaibranly.fr/pod23/cc/imageproxy.ashx?server=localhost&port=15012&filename=images/MQB_CC3V2_02_21031210_PH.dzi" />
                    </div>
                </div>
            }

            <button
                className={styles.infoButton}
                onClick={showMenu}>
                <GenericIcon icon='solid/info.svg' />
            </button>

            <div className={`${styles.scrollToTop} ${showScrollToTop ? styles.show : ''}`} onClick={() => {pageRef?.current?.scrollTo({ top: 0, behavior: 'smooth' })}}>
                <GenericIcon icon='regular/chevron-up.svg'/>
            </div>
        </div>
    );
}

export default MobileSearch;
