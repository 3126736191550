import React, { ReactNode, MouseEvent } from 'react';
import styles from './MobileHomePageblock.module.css'; // Assuming your styles are in this file

interface MobileHomepageBlockProps {
    onClick: (event: MouseEvent<HTMLDivElement>) => void;
    imageUrl: string;
    text: string;
}

const MobileHomepageBlock: React.FC<MobileHomepageBlockProps> = ({ onClick, imageUrl, text }) => {
    return (
        <div onClick={onClick} >
            <div className={styles.homePageBlock}>
                <div className={styles.homePageBlockImage}>
                    <img src={imageUrl} alt="Image" />
                </div>
                <div className={styles.homePageBlockText}>
                    {text}
                </div>
            </div>
        </div>
  );
}

export default MobileHomepageBlock;
