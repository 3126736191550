import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './Header.module.css';
import User from '../User/User';
import { useTranslation } from 'react-i18next';
import * as ccUtils from '../CC/utils';
import { useCCContext } from '../CC/CCContext';

const Header = () => {
  const { t, i18n } = useTranslation();
  const { cc, setCc, first, setFirst, recordsPerPage, setRecordsPerPage, viewMode, setViewMode, browseMode, setBrowseMode, data, setData, isReady } = useCCContext();
  const location = useLocation();
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const queryParams = new URLSearchParams(window.location.search);

  const showLoginButton = queryParams.get('admin') === 'true';

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const getHeaderText = () => {
    const departmentFacet = cc.facets['department'];
    if (departmentFacet && departmentFacet.length === 1) {
      return departmentFacet[0];
    }
    return "Collections du Centre des monuments nationaux";
  };

  const getMonumentParam = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('monument') || 'no monument specified';
  };

  useEffect(() => {
    const monumentName = getMonumentParam();
    if (monumentName && monumentName !== 'no monument specified') {
      ccUtils.fetchMonumentImage(monumentName).then(setImageUrl);
    }
  }, [location.search]);

  return (
    <header id="header" className={styles.global} role="banner">
      <div id="header-top" className={styles.top}>
        <span
          className={styles.header_logo}
          onClick={(e: any) => {
            e.preventDefault();
            setViewMode('homepage');
          }}>
          <div className={styles.header_logo_text}>Collections</div>
          <div className={styles.header_logo_svg}>
            <img src="header_logo.svg" />
          </div>
        </span>
        <div className={styles.monumentInfo}>
          {getHeaderText()}
        </div>
        <div className={styles.languageSelectorContainer}>
          <select
            className={styles.languageSelector}
            value={i18n.language}
            onChange={(event) => changeLanguage(event.target.value)}
          >
            <option value="en">English</option>
            <option value="fr">Français</option>
          </select>
          {showLoginButton && <div className={styles.userMenu}><User /></div>}
        </div>
      </div>
    </header>
  );
};

export default Header;