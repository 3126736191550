import React, { ChangeEvent } from 'react';
import genericStyles from '../GenericComponents/GenericStyles.module.css';
import styles from './Paginator.module.css';
import { CCProvider, useCCContext } from '../CC/CCContext';
import GenericIconButton from '../GenericComponents/GenericIconButton';

function Paginator() {
  const { cc, setCc, first, setFirst, recordsPerPage, setRecordsPerPage, viewMode, setViewMode, browseMode, setBrowseMode, data, setData } = useCCContext();
  const totalRecords = data.request?.count || 0;

  const updateRecordsPerPage = (event: ChangeEvent<HTMLSelectElement>) => {
    setRecordsPerPage(parseInt(event.target.value)); // Update recordsPerPage
    setCc({
      ...cc
    });
    setFirst(1); // Reset first
  };

  const updateFirstRecord = (newFirst: number) => {
    setFirst(newFirst); // Update first
  };

  const goToFirstPage = () => updateFirstRecord(1);
  const goToPreviousPage = () => updateFirstRecord(Math.max(1, Number(first) - Number(recordsPerPage)));
  const goToNextPage = () => {
    let nextFirstRecord = Number(first) + Number(recordsPerPage);
    if (nextFirstRecord > Number(totalRecords)) {
        nextFirstRecord = Number(totalRecords);
    }
    updateFirstRecord(nextFirstRecord);
};
const goToLastPage = () => {
    let startLastPage = totalRecords - (totalRecords % recordsPerPage) + 1;
    if (totalRecords % recordsPerPage === 0) {
        startLastPage -= recordsPerPage;
    }
    updateFirstRecord(startLastPage);
};

  return (
    <div className={styles.Paginator}>
      <select className={genericStyles.genericSelect} value={recordsPerPage} onChange={updateRecordsPerPage}>
        <option value={8}>8</option>
        <option value={24}>24</option>
        <option value={48}>48</option>
        <option value={64}>64</option>
      </select>
      <GenericIconButton icon='light/angle-double-left.svg' width='22px' height='20px' onClick={() => goToFirstPage()} />
      <GenericIconButton icon='light/angle-left.svg' width='22px' height='20px' onClick={() => goToPreviousPage()} />
      <GenericIconButton icon='light/angle-right.svg' width='22px' height='20px' onClick={() => goToNextPage()} />
      <GenericIconButton icon='light/angle-double-right.svg' width='22px' height='20px' onClick={() => goToLastPage()} />
    </div>
  );
}

export default Paginator;
