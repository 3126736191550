import { NodeViewWrapper } from '@tiptap/react'
import React from 'react'

import Count from '../Navigator/Count'

export default props => {
  const increase = () => {
    props.updateAttributes({
      count: props.node.attrs.count + 1,
    })
  }

  return (
    <NodeViewWrapper className="react-component">
      <label>React Component</label>

      <div className="content">
        <Count />
        <button onClick={increase}>
          This button has been clicked {props.node.attrs.count} times.
        </button>
      </div>
    </NodeViewWrapper>
  )
}