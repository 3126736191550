import React, { useEffect, useState, useRef } from 'react';
import { I18nextProvider } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import GenericIconButton from '../GenericComponents/GenericIconButton';
import styles from './MobileHomepage.module.css';
import { CCProvider, useCCContext } from '../CC/CCContext';
import * as ccUtils from '../CC/utils';
import MobileHomepageBlock from './MobileHomePageblock';
import AnnotatedImageViewer from '../AlbumEditor/AnnotatedImageViewer';
import GenericIcon from '../GenericComponents/GenericIcon';

type MobileHomepageProps = {
    mainY: number;
    setAndKeepMainY: (newMainY: number, prevMainY: number) => void;
};

const MobileHomepage: React.FC<MobileHomepageProps> = ({ mainY, setAndKeepMainY }) => {
    const { t, i18n } = useTranslation();
    const { cc, setCc, first, setFirst, recordsPerPage, setRecordsPerPage, viewMode, setViewMode, browseMode, setBrowseMode, data, setData, isReady } = useCCContext();
    const ctx = useCCContext();
    const topRef = useRef<HTMLDivElement | null>(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [introTextEnglish, setIntroTextEnglish] = useState<string>('');
    const [introTextFrench, setIntroTextFrench] = useState<string>('');
    const [imageUrl, setImageUrl] = useState<string | null>(null);

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    const showMenu = () => {
        setAndKeepMainY(-400, -100)
    }

    const showSearch = () => {
        setAndKeepMainY(-200, -100);
    }

    useEffect(() => {
        const getMonumentName = () => {
            const departmentFacet = cc.facets['department'];
            if (departmentFacet && departmentFacet.length === 1) {
                return departmentFacet[0];
            } else {
                const webdepCriteria = cc.customSearchCriteria.find(criteria => criteria.field === '/record/webdep');
                if (webdepCriteria && webdepCriteria.searchValues.length === 1) {
                    let value = webdepCriteria.searchValues[0];
                    if (value.startsWith('[') && value.endsWith(']')) {
                        value = value.slice(1, -1);  // Remove the first and last character
                    }
                    return value;
                }
            }
            return '';
        };

        const getMonumentTexts = async () => {
            try {
                const monumentName = getMonumentName();
                if (monumentName) {
                    const data = await ccUtils.fetchMonumentHtml(monumentName);
                    if (data.records && data.records.record && data.records.record.data && data.records.record.data.record) {
                        const { introeng, introfra } = data.records.record.data.record;
                        setIntroTextEnglish(introeng || '');
                        setIntroTextFrench(introfra || '');
                    } else {
                        console.error('Unexpected response structure:', data);
                    }
                } else {
                    if (ccUtils.generateQuery(cc).indexOf('thème: Tapisseries') > 0 || ccUtils.generateQuery(cc).indexOf('theme: Tapisseries') > 0) {
                        setIntroTextEnglish('<span lang="EN"><p align="LEFT" dir="LTR"><strong>THE TAPESTRY COLLECTION</strong></p> <p align="LEFT" dir="LTR">Welcome to the database of the tapestries collection of the Centre des monuments nationaux, one of the richest in France!<br />Discover the Apocalypse Tapestry presented at the Château d\'Angers or that of the History of Tancred and Clorinde preserved at the Château de Châteaudun and nearly a hundred other tapestries woven from the 14th to the 19th century during your navigation.<br />For each one, you will find a documentary notice and a few words about its history.</p></span>');
                        setIntroTextFrench('<span lang="FR"><p align="LEFT" dir="LTR"><strong>LA COLLECTION DES TAPISSERIES</strong></p> <p align="LEFT" dir="LTR">Bienvenue sur la base de données de la collection de tapisseries du Centre des monuments nationaux, une des plus riches de France !<br />Découvrez la Tenture de l’Apocalypse présentée au château d’Angers ou encore celle de l\'Histoire de Tancrède et Clorinde conservée au château de Châteaudun et près d’une centaine autres tapisseries tissées du XIVe au XIXe siècle lors de votre navigation.<br />Pour chacune, vous trouverez une notice documentaire et quelques mots sur son histoire.<br /></p></span>');
                    } else {
                        setIntroTextEnglish('<span lang="EN"><p align="LEFT" dir="LTR"><strong>THE COLLECTION OF THE CENTRE DES MONUMENTS NATIONAUX</strong></p> <p align="LEFT" dir="LTR">This database brings together the collections of a certain number of monuments from the Centre des monuments nationaux network. Others will gradually enrich this fund.</p></span>');
                        setIntroTextFrench('<span lang="FR"><p align="LEFT" dir="LTR"><strong>LES COLLECTIONS DU CENTRE DES MONUMENTS NATIONAUX</strong></p> <p align="LEFT" dir="LTR">Cette base rassemble les collections d’un certain nombre de monuments du réseau du Centre des monuments nationaux. D’autres seront amenées progressivement à enrichir ce fonds.</p></span>');
                    }
                }
            } catch (error) {
                console.error('Error fetching monument data:', error);
            }
        };

        const getMonumentImage = async () => {
            try {
                const monumentName = getMonumentName();
                if (monumentName) {
                    const imageUrl = await ccUtils.fetchMonumentImage(monumentName);
                    setImageUrl(imageUrl);
                } else {
                    if (ccUtils.generateQuery(cc).indexOf('thème: Tapisseries') > 0 || ccUtils.generateQuery(cc).indexOf('theme: Tapisseries') > 0) {
                        setImageUrl('CMN_dbw16_0863.jpg');
                    } else {
                        setImageUrl('121755--DBW23-0113.jpg');
                    }
                }
            } catch (error) {
                console.error('Error fetching monument data:', error);
            }
        };

        getMonumentTexts();
        getMonumentImage();
    }, [cc]);

    useEffect(() => {
        const handleResize = () => {
            const contentsElement = document.querySelector(`.${styles.contents}`) as HTMLElement;
            if (contentsElement) {
                contentsElement.style.height = `${window.innerHeight - 60}px`;
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial call to set the height

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={styles.MobileHomepage} >
            <div ref={topRef} className={styles.header}>
                <div className={styles.header_logo}
                    onClick={(e: any) => {
                        e.preventDefault();
                        setViewMode('homepage');
                    }}>
                    <div>
                        <div className={styles.header_logo_text}>Collections</div>
                        <div className={styles.header_logo_svg}>
                            <img src="header_logo.svg" />
                        </div>
                    </div>
                </div>
                <div className={styles.languageSelectorContainer}>
                    <select
                        className={styles.languageSelector}
                        value={i18n.language}
                        onChange={(event) => changeLanguage(event.target.value)}
                    >
                        <option value="en">English</option>
                        <option value="fr">Français</option>
                    </select>
                </div>
            </div>
            <div className={styles.contents}>
                <div className={styles.intro}>
                    <div className={styles.introText}>
                        {/* imageUrl && <img src={imageUrl} alt={"monument"} className={styles.monumentImage} /> */}
                        {imageUrl && <AnnotatedImageViewer imageName={imageUrl} />}
                        {i18n.language === 'en' ? <div className={styles.introTextText} dangerouslySetInnerHTML={{ __html: introTextEnglish }} /> : i18n.language === 'fr' ? <div className={styles.introTextText} dangerouslySetInnerHTML={{ __html: introTextFrench }} /> : ''}
                    </div>
                    <button onClick={(e) => { showSearch() }}>{t("homepage_click_to_view")}</button>
                </div>
                <div className={styles.homepageBlocks}>
                    <MobileHomepageBlock
                        onClick={(e) => {
                            e.preventDefault();
                            ccUtils.clearFacet(ctx, 'department');
                            if (i18n.language === 'fr') {
                                ccUtils.addCustomSearch(ctx, '/record/theme', 'Thème', ['[thème: Tapisseries]'], '');
                            } else {
                                ccUtils.addCustomSearch(ctx, '/record/themeeng', 'Theme', ['[theme: Tapisseries]'], '');
                            }

                            showSearch();
                        }}
                        imageUrl={"https://collections.monuments-nationaux.fr/ccImageProxy.ashx?filename=images/033/images%20pleine%20page/CMN_dbw16_0865.jpg&width=350&_height=220&borderwidth=0&borderheight=0&bordercolor=e8e8e8&bg=f8f8f8&passepartoutwidth=0&passepartoutheight=0&passepartoutcolor=f8f8f8&cache=yes"}
                        text={t("homepageblock_tapisseries")}
                    />
                </div>

            </div>

            <button
                className={styles.infoButton}
                onClick={showMenu}>
                <GenericIcon icon='solid/info.svg' />
            </button>

        </div>
    )
};

export default MobileHomepage;
