// GenericCheckbox.tsx
import React from 'react';
import { ReactComponent as CheckSquareIcon } from '../fontawesome/svgs/regular/check.svg';
import styles from './GenericCheckbox.module.css';

interface GenericCheckboxProps {
  id: string;
  name: string;
  value: string;
  checked: boolean;
  onChange: (checked: boolean, value: string) => void;
  label: string;
}

const GenericCheckbox: React.FC<GenericCheckboxProps> = ({ id, name, value, checked, onChange, label }) => {
    
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.checked, value)
    };
    
    return (
    <div>
      <input 
          type="checkbox" 
          id={id}
          name={name}
          value={value} 
          checked={checked} 
          onChange={handleCheckboxChange} 
          className={styles.genericCheckboxInput}
      />
      <label 
          htmlFor={id}
          className={styles.genericCheckboxLabel}
      >
        {checked ? <CheckSquareIcon className={styles.icon}/> : <div className={styles.icon}/>}
        {label}
      </label>
    </div>
  );
}

export default GenericCheckbox;
