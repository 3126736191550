import React, { useState, useEffect } from 'react';

type FilterType = {
  [key: string]: { group: string; label: string; query: string };
};

type FacetType = {
  [key: string]: { label: string; query: string };
};

type SortType = {
  [key: string]: { label: string; field: string };
};

export type HierarchicalFacetValue = {
  CN: string;
  term: string;
};

type HierarchicalFacetType = {
  [key: string]: {
    label: string;
    CN: string;
  };
};

interface SearchValue {
  source: string;
  text: string;
}

export type SearchType = {
  identifier: string;
  field: string;
  searchValues: SearchValue[];
};

interface CustomSearchCriterion {
  field: string;
  fieldLabel: string;
  searchValues: string[];
  description: string;
}

export class Search implements SearchType {
  public identifier: string;
  public field: string;
  public searchValues: SearchValue[];

  constructor(identifier: string, field: string, searchValues: SearchValue[] = []) {
    this.identifier = identifier;
    this.field = field;
    this.searchValues = searchValues;
  }
}

class CC {
  public Searches: Search[] = [];
  matchAllCriteria: boolean = true;
  public filters: string[] = []; // This will store the filter keys the user selects
  public facets: { [field: string]: string[] } = {}; // This will store the facet fields the user selects
  public hierarchicalFacets: { [key: string]: HierarchicalFacetValue[] } = {};
  public customSearchCriteria: CustomSearchCriterion[] = [];

  constructor() {}
}

class CcConfig {

  simpleSearchFields: {
    [key: string]: {
      field: string;
      browse: boolean;
    };
  } = {
    "All search fields": {
      field: "*",
      browse: false,
    },
    "Title": {
      field: "/record/title",
      browse: true,
    },
    "Object Number": {
      field: "/record/objectnumber",
      browse: true,
    },
    "Artist": {
      field: "/record/auteur",
      browse: true,
    },
    "Model author": {
      field: "/record/auteurfiguree",
      browse: true,
    },
    "Workshop name": {
      field: "/record/conatelier",
      browse: true,
    },
    "Object name": {
      field: "/record/objectname",
      browse: true,
    },
    "Dated": {
      field: "/record/datebegin,/record/dated,/record/dateend",
      browse: true,
    },
    "Materials and techniques": {
      field: "/record/medium",
      browse: true,
    },
    "Descriptions": {
      field: "/record/description",
      browse: false,
    },
    "Inscriptions": {
      field: "/record/inscribed",
      browse: false,
    },
    "Historical comment": {
      field: "/record/provenance",
      browse: false,
    },
    "Thème": {
      field: "/record/theme",
      browse: true,
    }
  };

  //advancedSearchFields wordt voor MQB niet gebruikt
  //Bij MQB wordt de advanced search gedaan met de simple search
  advancedSearchFields: {
    [key: string]: {
      field: string;
      browse: boolean;
    };
  } = {
    "Title": {
      field: "/record/title",
      browse: true,
    },
    "Object Number": {
      field: "/record/objectnumber",
      browse: true,
    },
    "Artist": {
      field: "/record/auteur",
      browse: true,
    },
    "Model author": {
      field: "/record/auteurfiguree",
      browse: true,
    },
    "Workshop name": {
      field: "/record/conatelier",
      browse: true,
    },
    "Object name": {
      field: "/record/objectname",
      browse: true,
    },
    "Dated": {
      field: "/record/datebegin,/record/dated,/record/dateend",
      browse: true,
    },
    "Materials and techniques": {
      field: "/record/medium",
      browse: true,
    },
    "Descriptions": {
      field: "/record/description",
      browse: false,
    },
    "Inscriptions": {
      field: "/record/inscribed",
      browse: false,
    },
    "Historical comment": {
      field: "/record/provenance",
      browse: false,
    },
    "Thème": {
      field: "/record/theme",
      browse: true,
    }
  };

  mobileSearchFields: {
    [key: string]: {
      field: string;
      browse: boolean;
    };
  } = {
    "All search fields": {
      field: "*",
      browse: false,
    },
    "Monument": {
      field: "/record/webdep",
      browse: true,
    },
    "Title": {
      field: "/record/title",
      browse: true,
    },
    "Object Number": {
      field: "/record/objectnumber",
      browse: true,
    },
    "Artist": {
      field: "/record/auteur",
      browse: true,
    },
    "Model author": {
      field: "/record/auteurfiguree",
      browse: true,
    },
    "Workshop name": {
      field: "/record/conatelier",
      browse: true,
    },
    "Object name": {
      field: "/record/objectname",
      browse: true,
    },
    "Dated": {
      field: "/record/datebegin,/record/dated,/record/dateend",
      browse: true,
    },
    "Materials and techniques": {
      field: "/record/medium",
      browse: true,
    },
    "Descriptions": {
      field: "/record/description",
      browse: false,
    },
    "Inscriptions": {
      field: "/record/inscribed",
      browse: false,
    },
    "Historical comment": {
      field: "/record/provenance",
      browse: false,
    },
    "Thème": {
      field: "/record/theme",
      browse: true,
    },
    "Collection": {
      field: "/record/webcollection",
      browse: true,
    }
  };

  filters: FilterType = {
    filter_objects: { group: "A", label: "Objects", query: "/Record/source=objects" },
    filter_icono: { group: "A", label: "Iconography", query: "/Record/source=icono" },
    filter_freeOfRights: { group: "B", label: "Free to use Images", query: "CN=[ICO.AAN.AAB.AAC]" },
   
  };

  facets: FacetType = {
    department: { label: "facet_department", query: "/record/webdep" },
    title: { label: "facet_title", query: "/record/title" },
    personnes: { label: "facet_persons", query: "/record/creator" },
    medium: { label: "facet_medium", query: "/record/medium" },
    datedperiod: { label: "facet_datedperiod", query: "/record/datedperiod" },
    objectname: { label: "facet_objectname", query: "/record/objectname" },
    collection: { label: "facet_collection", query: "/record/webcollection" },
  };

  hierarchicalFacets: HierarchicalFacetType = {
    culture: { label: "facet_culture", CN: "MQY"},
    toponyme: { label: "facet_geography", CN: "MQX"},
  }

  sort: SortType = {
    relevance: {label: "Relevance", field: "ccRelevance"},
    title: {label: "Title", field: "/record/sorttitle"},
    objectNumber: {label: "Object number", field: "/Record/ObjectNumber,/Record/ObjectNumber2"},
    date: {label: "Date(s)", field: "/Record/DateBegin"},
    department: {label: "Monument", field: "/Record/Department"}
  }

}

let ccConfig = new CcConfig();

export { CC, ccConfig };
