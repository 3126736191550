import React from 'react';
import { useTranslation } from 'react-i18next';
import { ccConfig, CC } from '../CC/CC';
import { CCProvider, useCCContext } from '../CC/CCContext';
import styles from './MobileQuerySummary.module.css';
import CopyHyperlinkButton from '../Search/CopyHyperlinkButton';

function MobileQuerySummary() {
    const { t } = useTranslation();
    const { cc, setCc, first, setFirst, recordsPerPage, setRecordsPerPage, viewMode, setViewMode, browseMode, setBrowseMode, data, setData } = useCCContext();
    const totalRecords = data.request?.count || 0;

    const handleDelete = (type: string, key?: string, value?: string) => {
        // Clone cc object
        const newCc = { ...cc };

        switch (type) {
            case 'simple':
                if (value) {
                    const index = newCc.Searches.findIndex(search => search.identifier === key);
                    if (index > -1) {
                        newCc.Searches[index].searchValues = newCc.Searches[index].searchValues.filter(sv => sv.text !== value);
                    }
                } else {
                    // Find the search by identifier and reset it to default value
                    const index = newCc.Searches.findIndex(search => search.identifier === key);
                    if (index > -1) {
                        //this leads to endless recurion newCc.simpleSearches.splice(index, 1);
                        newCc.Searches[index].searchValues = [];
                    }
                }
                break;
            case 'custom':
                newCc.customSearchCriteria = newCc.customSearchCriteria.filter(c => c.field !== key);
                break;
            case 'filter':
                if (key) {
                    const index = newCc.filters.indexOf(key);
                    if (index > -1) {
                        newCc.filters = [
                            ...newCc.filters.slice(0, index),
                            ...newCc.filters.slice(index + 1),
                        ];
                    }
                }
                break;
            case 'facet':
                if (key) {
                    const [field, value] = key.split('-facetValueSeparator-');
                    const facetValues = newCc.facets[field] || [];
                    const valueIndex = facetValues.indexOf(value);
                    if (valueIndex > -1) {
                        newCc.facets[field] = [
                            ...facetValues.slice(0, valueIndex),
                            ...facetValues.slice(valueIndex + 1),
                        ];
                    }
                    if (newCc.facets[field].length === 0) {
                        delete newCc.facets[field];
                    }
                }
                break;
            case 'hierarchicalFacet':
                if (key) {
                    const hierarchicalFacetValues = newCc.hierarchicalFacets[key] || [];
                    const valueIndex = hierarchicalFacetValues.findIndex(facetValue => facetValue.term === value);
                    if (valueIndex > -1) {
                        newCc.hierarchicalFacets[key] = [
                            ...hierarchicalFacetValues.slice(0, valueIndex),
                            ...hierarchicalFacetValues.slice(valueIndex + 1),
                        ];
                    }
                    if (newCc.hierarchicalFacets[key].length === 0) {
                        delete newCc.hierarchicalFacets[key];
                    }
                }
                break;
            default:
                break;
        }

        // Update cc object in context
        setCc(newCc);
        setFirst(1);
    };

    const handleDeleteAllFacetValues = (field: string) => {
        // Clone cc object
        const newCc = { ...cc };

        // Remove all selected facet values for the given field
        delete newCc.facets[field];

        // Update cc object in context
        setCc(newCc);
        setFirst(1);
    }

    const handleDeleteAllHierarchicalFacetValues = (field: string) => {
        // Clone cc object
        const newCc = { ...cc };

        // Remove all selected hierarchical facet values for the given field
        delete newCc.hierarchicalFacets[field];

        // Update cc object in context
        setCc(newCc);
        setFirst(1);
    }

    const handleDeleteCustomSearchCriterionValue = (event: React.MouseEvent<HTMLDivElement>, field: string, value: string) => {
        event.stopPropagation(); // Stop event bubbling

        // Clone cc object
        const newCc = { ...cc };

        // Array to store indices of criteria that should be deleted
        const indicesToDelete: number[] = [];

        // Iterate over all customSearchCriteria
        newCc.customSearchCriteria.forEach((criterion, index) => {
            if (criterion.field === field) {
                // Delete the value from the searchValues array
                criterion.searchValues = criterion.searchValues.filter(v => v !== value);

                // If there are no more searchValues for this criterion, add its index to the deletion array
                if (criterion.searchValues.length === 0) {
                    indicesToDelete.push(index);
                }
            }
        });

        // Remove all customSearchCriteria that should be deleted
        newCc.customSearchCriteria = newCc.customSearchCriteria.filter((_, index) => !indicesToDelete.includes(index));

        // Update cc object in context
        setCc(newCc);
        setFirst(1);
    };


    return (
        <div className={styles.MobileQuerySummary}>
            <div className={styles.querySummary}>
                {cc.Searches.filter(simpleSearch => simpleSearch.searchValues?.length > 0).map((simpleSearch, sIndex) => (
                    <div key={`simpleSearch-${sIndex}`} className={styles.queryItem}>
                        <div className={styles.queryLabel} onClick={() => handleDelete('simple', simpleSearch.identifier)}>
                            {t(simpleSearch.field)}
                            <button className={styles.removeButton}>X</button>
                        </div>
                        &nbsp;:&nbsp;
                        {simpleSearch.searchValues?.map(({ text }, vIndex) => (
                            <div key={`value-${vIndex}`} className={styles.subQueryItem} onClick={() => handleDelete('simple', simpleSearch.identifier, text)}>
                                {text}
                                <button className={styles.removeButton}>X</button>
                            </div>
                        ))}
                    </div>
                ))}

                {/* Custom Search Criteria */}
                {cc.customSearchCriteria.map((criterion, cIndex) => (
                    <div key={`customSearchCriterion-${cIndex}`} className={styles.queryItem}>
                        {/* Display only the description if present 
                        Translations will probably not work
                    */}
                        {criterion.description ? (
                            <div className={styles.queryLabel} onClick={() => handleDelete('custom', criterion.field)}>
                                {criterion.description}
                                <button className={styles.removeButton}>X</button>
                            </div>
                        ) : (
                            <>
                                {criterion.searchValues.map((originalValue, vIndex) => {
                                    let displayValue = originalValue;

                                    // Remove surrounding [ and ] if they exist
                                    if (displayValue.startsWith('[') && displayValue.endsWith(']')) {
                                        displayValue = displayValue.slice(1, -1);
                                    }
                                    // URL decode [ and ]
                                    displayValue = displayValue.replace(/%5B/g, '[').replace(/%5D/g, ']');

                                    return (
                                        <div key={`value-${vIndex}`} className={styles.subQueryItem} onClick={(event) => handleDeleteCustomSearchCriterionValue(event, criterion.field, originalValue)}>
                                            {t(criterion.fieldLabel)} : {displayValue}
                                            <button className={styles.removeButton}>X</button>
                                        </div>
                                    );
                                })}
                            </>
                        )}
                    </div>
                ))}


                {cc.filters.map(filterKey => (
                    <div className={styles.queryItem} key={filterKey} onClick={() => handleDelete('filter', filterKey)}>
                        <div className={styles.queryLabel}>{t("filter_active")}</div>
                        <div className={styles.subQueryItem}>
                            {t(ccConfig.filters[filterKey].label)}
                            <button className={styles.removeButton}>X</button>
                        </div>
                    </div>
                ))}

                {Object.entries(cc.facets).map(([field, values]) => {
                    if (values.length > 0) {
                        return (
                            <div className={styles.queryItem} key={field}>
                                <div className={styles.queryLabel} onClick={() => handleDeleteAllFacetValues(field)}>
                                    {t(ccConfig.facets[field]?.label) || t(field)}
                                    <button className={styles.removeButton}>X</button>
                                </div>
                                &nbsp;:&nbsp;
                                {values.map(value => (
                                    <div className={styles.subQueryItem} key={`${field}-facetValueSeparator-${value}`} onClick={() => handleDelete('facet', `${field}-facetValueSeparator-${value}`)}>
                                        {value}
                                        <button className={styles.removeButton}>X</button>
                                    </div>
                                ))}
                            </div>
                        );
                    }
                    return null;
                })}

                {Object.entries(cc.hierarchicalFacets).map(([field, hierarchicalFacetValues]) => {
                    if (hierarchicalFacetValues.length > 0) {
                        return (
                            <div className={styles.queryItem} key={field}>
                                <div className={styles.queryLabel} onClick={() => handleDeleteAllHierarchicalFacetValues(field)}>
                                    {t(ccConfig.hierarchicalFacets[field]?.label) || t(field)}
                                    <button className={styles.removeButton}>X</button>
                                </div>
                                &nbsp;:&nbsp;
                                {hierarchicalFacetValues.map(({ term }, vIndex) => (
                                    <div key={`hierarchicalValue-${vIndex}`} className={styles.subQueryItem} onClick={() => handleDelete('hierarchicalFacet', field, term)}>
                                        {term}
                                        <button className={styles.removeButton}>X</button>
                                    </div>
                                ))}
                            </div>
                        );
                    }
                    return null;
                })}

            </div>
            <div className={styles.recordsFound}>
                <div className={styles.recordsFoundCount}>{parseInt(totalRecords, 10).toLocaleString()} {parseInt(totalRecords, 10) === 1 ? t("record found") : t("records found")}.</div>
                <div className={styles.recordsFoundCopyButton}><CopyHyperlinkButton /></div>
            </div>
        </div>
    );
}

export default MobileQuerySummary;
