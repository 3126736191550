import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, XIcon, LinkedinShareButton, LinkedinIcon, PinterestShareButton, PinterestIcon } from 'react-share';
import { useSwipeable } from 'react-swipeable';
import styles from './MobileRecord.module.css';
import Record from './Record';
import { generateQuery } from '../CC/utils';
import { ccConfig, CC } from '../CC/CC';
import { CCProvider, useCCContext } from '../CC/CCContext';
import GenericIcon from '../GenericComponents/GenericIcon';
import GenericIconButton from '../GenericComponents/GenericIconButton';
import * as ccUtils from '../CC/utils';
import genericStyles from '../GenericComponents/GenericStyles.module.css';

type MobileRecordProps = {
  mainY: number;
  setAndKeepMainY: (newMainY: number, prevMainY: number) => void;
  selectedRecord: any | null;
  curPage: number;
  setSelectedRecord: React.Dispatch<React.SetStateAction<any | null>>;
};

function copyCanvasContent(sourceCanvas: HTMLCanvasElement, targetCanvas: HTMLCanvasElement) {
  const ctx = targetCanvas.getContext('2d');
  ctx?.drawImage(sourceCanvas, 0, 0);
}

const generatePdf = () => {
  const originalElement = document.getElementById("pdf-container") as HTMLElement;
  const clonedElement = originalElement.cloneNode(true) as HTMLElement;

  const originalCanvases = originalElement.querySelectorAll('canvas');
  const clonedCanvases = clonedElement.querySelectorAll('canvas');

  originalCanvases.forEach((canvas, index) => {
    copyCanvasContent(canvas, clonedCanvases[index] as HTMLCanvasElement);
  });

  clonedElement.style.top = '-10000px';
  clonedElement.style.display = 'block';
  document.body.appendChild(clonedElement);

  setTimeout(() => {
    (clonedElement.querySelectorAll('.pdfOnly') as NodeListOf<HTMLElement>).forEach(el => el.style.display = 'block');
    (clonedElement.querySelectorAll('.screenOnly') as NodeListOf<HTMLElement>).forEach(el => el.style.display = 'none');

    (window as any).html3pdf(clonedElement, {
      margin: 10,
      filename: 'monumentsNotice.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    });
    document.body.removeChild(clonedElement);
  });
}

const MobileRecord: React.FC<MobileRecordProps> = ({ mainY, setAndKeepMainY, curPage, selectedRecord, setSelectedRecord }) => {
  const { t } = useTranslation();
  const instanceRef = useRef({});
  const { cc, setCc, sort, first, setFirst, recordsPerPage, setRecordsPerPage, viewMode, setViewMode, browseMode, setBrowseMode, data, setData } = useCCContext();
  const [isLoading, setIsLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(curPage);
  const [isInput, setIsInput] = useState(false);
  const [inputPage, setInputPage] = useState(curPage);
  const [sliderPage, setSliderPage] = useState(curPage);
  const pageRef = useRef<HTMLDivElement | null>(null);
  const [showScrollToTop, setShowScrollToTop] = useState(false); // State to manage visibility of scroll-to-top button
  const [showSharePopup, setShowSharePopup] = useState(false); // State to manage visibility of the share popup

  const showResults = () => {
    setAndKeepMainY(-200, -300);
  }

  useEffect(() => {
    const handleScroll = () => {
      if (pageRef?.current?.scrollTop && pageRef?.current?.scrollTop > 200) { // Show button after scrolling down 200px
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    const currentRef = pageRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPage = parseInt(e.target.value, 10);
    setSliderPage(newPage);
  };

  const handleSliderMouseUp = () => {
    updateCurrentPage(sliderPage);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputPage(parseInt(e.target.value, 10));
  };

  const handleBlur = () => {
    if (inputPage >= 1 && inputPage <= totalRecords) {
      updateCurrentPage(inputPage);
    }
    setIsInput(false);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleBlur();
    }
  };

  const updateCurrentPage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const goToPreviousPage = () => updateCurrentPage(Math.max(1, Number(currentPage) - 1));
  const goToNextPage = () => updateCurrentPage(Math.min(totalRecords, Number(currentPage) + 1));
  const goToFirstPage = () => updateCurrentPage(1);
  const goToLastPage = () => updateCurrentPage(Number(totalRecords));

  const handlers = useSwipeable({
    //onSwipedLeft: () => goToNextPage(),
    //onSwipedRight: () => goToPreviousPage(),
    //preventDefaultTouchmoveEvent: true,
    //trackMouse: true
  });

  const showMenu = () => {
    setAndKeepMainY(-400, -300)
  }

  useEffect(() => {
    const fetchData = async () => {
      const query = generateQuery(cc);
      const rangeStart = currentPage;

      const postData = {
        action: "get",
        command: "search",
        query: query,
        fields: "*",
        range: `${rangeStart}-${rangeStart}`,
        sort: ccConfig.sort[sort].field,
        responseformat: "json"
      };

      try {
        const data = await ccUtils.postWithCache(process.env.REACT_APP_BASE_URL as string, postData, instanceRef);
        setTotalRecords(data.request.count);
        const records = Array.isArray(data?.records?.record) ? data?.records?.record : [data?.records?.record];
        setSelectedRecord(records[0] ?? null);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentPage]);

  useEffect(() => {
    if (selectedRecord?.metadata?.recordnumber !== currentPage) {
      setCurrentPage(selectedRecord?.metadata?.recordnumber);
    }
  }, [selectedRecord]);

  function ShareButtons() {
    const invNo = selectedRecord?.data?.record?.objectnumber;
    const encodedInvNo = encodeURIComponent(invNo);
    const url = `${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}${window.location.pathname}?id=${encodedInvNo}`;
    const recordTitle = selectedRecord?.data?.record?.['titleorobjectname'];
    let title = 'Centre des monuments nationaux';
    if (recordTitle) {
      title = title + ' - ' + recordTitle;
    }

    return (
      <div className={styles.socialButtons}>
        <div className={styles.socialButtonWrapper}>
          <FacebookShareButton url={url}>
            <FacebookIcon size={40} borderRadius={4} />
          </FacebookShareButton>
        </div>
        <div className={styles.socialButtonWrapper}>
          <TwitterShareButton url={url} title={title}>
            <XIcon size={40} borderRadius={4} />
          </TwitterShareButton>
        </div>
        <div className={styles.socialButtonWrapper}>
          <LinkedinShareButton url={url} title={title}>
            <LinkedinIcon size={40} borderRadius={4} />
          </LinkedinShareButton>
        </div>
        <div className={styles.socialButtonWrapper}>
          <PinterestShareButton url={url} media={url} description={title}>
            <PinterestIcon size={40} borderRadius={4} />
          </PinterestShareButton>
        </div>
        <div className={styles.socialButtonWrapper} onClick={generatePdf} >
          <GenericIcon icon='regular/file-pdf.svg' className={styles.pdfButtonWideScreen} />
        </div>
      </div>
    );
  }

  function ShareButtonsNarrowScreen() {
    const invNo = selectedRecord?.data?.record?.objectnumber;
    const encodedInvNo = encodeURIComponent(invNo);
    const url = `${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}${window.location.pathname}?id=${encodedInvNo}`;
    const recordTitle = selectedRecord?.data?.record?.['titleorobjectname'];
    let title = 'Centre des monuments nationaux';
    if (recordTitle) {
      title = title + ' - ' + recordTitle;
    }

    return (
      <div className={styles.socialButtons}>
        <div className={styles.socialButtonWrapper}>
          <FacebookShareButton url={url}>
            <FacebookIcon size={60} borderRadius={4} />
          </FacebookShareButton>
        </div>
        <div className={styles.socialButtonWrapper}>
          <TwitterShareButton url={url} title={title}>
            <XIcon size={60} borderRadius={4} />
          </TwitterShareButton>
        </div>
        <div className={styles.socialButtonWrapper}>
          <LinkedinShareButton url={url} title={title}>
            <LinkedinIcon size={60} borderRadius={4} />
          </LinkedinShareButton>
        </div>
        <div className={styles.socialButtonWrapper}>
          <PinterestShareButton url={url} media={url} description={title}>
            <PinterestIcon size={60} borderRadius={4} />
          </PinterestShareButton>
        </div>
        <div className={styles.socialButtonWrapper} onClick={generatePdf} >
          <GenericIcon icon='regular/file-pdf.svg' className={styles.pdfButtonNarrowScreen} />
        </div>
      </div>
    );
  }

  return (
    <div {...handlers} ref={pageRef} className={styles.MobileRecord}>
      <div className={styles.popupheader}>
        <div className={styles.Navigator}>
          <div className={styles.Paginator}>
            <GenericIconButton icon='light/angle-double-left.svg' width='32px' height='32px' onClick={() => goToFirstPage()} />
            <GenericIconButton icon='light/angle-left.svg' width='32px' height='32px' onClick={() => goToPreviousPage()} />
            <GenericIconButton icon='light/angle-right.svg' width='32px' height='32px' onClick={() => goToNextPage()} />
            <GenericIconButton icon='light/angle-double-right.svg' width='32px' height='32px' onClick={() => goToLastPage()} />
          </div>
          <div className={styles.CurPage}>
            {t("curpage_page")}&nbsp;
            {isInput ? (
              <input
                type="number"
                value={inputPage}
                onChange={handleInputChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyPress}
                style={{ width: '50px' }}
                autoFocus
              />
            ) : (
              <span onClick={() => { setIsInput(true); setInputPage(currentPage); }}>{currentPage.toLocaleString()}</span>
            )}&nbsp;{t("curpage_of")} {Number(totalRecords).toLocaleString()}
          </div>
          <div className={styles.ResultSetSlider}>
            <input
              type="range"
              min="1"
              max={totalRecords}
              value={sliderPage}
              onChange={handleSliderChange}
              onMouseUp={handleSliderMouseUp}
              onTouchEnd={handleSliderMouseUp}
              style={{ width: '100%' }}
            />
          </div>
        </div>
        <div className={styles.shareButtonContainer}>
          <div className={styles.shareButton}>
            <GenericIconButton icon='light/share.svg' width='28px' height='28px' onClick={() => setShowSharePopup(!showSharePopup)} />
          </div>
          <div className={`${styles.sharePopup} ${showSharePopup ? styles.visible : ''}`} onClick={() => setShowSharePopup(false)}>
            <div className={styles.socialsNarrowScreen}>
              <div className={styles.closeSharePopupButton}>
                <GenericIconButton icon='light/times.svg' width='40px' height='40px' onClick={() => setShowSharePopup(false)} />
              </div>
              <div className={styles.socialsNarrowScreenContent}>
                {ShareButtonsNarrowScreen()}
              </div>
            </div>
            <div className={styles.socialsWideScreen}>
              {ShareButtons()}
            </div>
          </div>
        </div>
        <div className={styles.closeButton}>
            <GenericIconButton icon='light/times.svg' width='28px' height='28px' onClick={() => showResults()} />
          </div>
      </div>

      <div className={styles.popupcontent}>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <Record record={selectedRecord} setAndKeepMainY={setAndKeepMainY} />
        )}
      </div>
      <button
        className={styles.infoButton}
        onClick={showMenu}>
        <GenericIcon icon='solid/info.svg' />
      </button>

      <div className={`${styles.scrollToTop} ${showScrollToTop ? styles.show : ''}`} onClick={() => { pageRef?.current?.scrollTo({ top: 0, behavior: 'smooth' }) }}>
        <GenericIcon icon='regular/chevron-up.svg' />
      </div>
    </div>
  );
}

export default MobileRecord;
