import { useEffect } from 'react';
import List from './List';
import Icons from './Icons';
import ScrollResults from './ScrollResults';
import { CCProvider, useCCContext } from '../CC/CCContext';

function ResultSet() {
  const { cc, first, viewMode, browseMode } = useCCContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [cc, first, viewMode, browseMode]);

  return (
    <>
      {browseMode === 'paginate' && viewMode === 'list' && <List />}
      {browseMode === 'paginate' && viewMode === 'icons' && <Icons />}
      {browseMode === 'scroll' && <ScrollResults />}
    </>
  );
}

export default ResultSet;
