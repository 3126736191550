import React from 'react';
import styles from './GenericIconButton.module.css';
import GenericIcon from './GenericIcon';

interface GenericIconButtonProps {
  icon: string;
  width?: string;
  height?: string;
  isActive?: boolean;
  disabled?: boolean;
  onClick?: Function;
  hoverEnabled?: boolean;
  fill?: string;
  hoverFill?: string;
  hoverBackgroundColor?: string;
  activeBorderColor?: string;
  ariaLabel?: string; // New prop for ARIA label
}

const GenericIconButton: React.FC<GenericIconButtonProps> = ({
    icon, width='22px', height='20px', isActive, disabled, onClick, fill='#626568', hoverEnabled=true, hoverFill, hoverBackgroundColor, activeBorderColor, ariaLabel
  }) => {
    const buttonStyle = {
      width,
      height,
      fill,
      '--hover-fill': hoverFill || '#626568',
      '--hover-bg-color': hoverBackgroundColor || '#e0e0e0',
      '--active-border-color': activeBorderColor || '#d3d3d3'
    };
  
    const combinedClassName = `${styles.genericIconButton} 
    ${isActive ? styles.genericIconButtonActive : ''} 
    ${disabled ? styles.genericIconButtonDisabled : ''} 
    ${hoverEnabled ? styles.hoverEnabled : ''}`;

    return (
      <div
        className={combinedClassName}
        style={buttonStyle}
        onClick={(event) => {
          if (!disabled && onClick) {
              onClick(event);
          }
        }}
        aria-disabled={disabled}
        aria-label={ariaLabel}
      >
        <GenericIcon className={styles.iconInsideButton} icon={icon} />
      </div>
    );
};

export default GenericIconButton;
