import React, { useState, useEffect, useRef, useCallback, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import genericStyles from '../GenericComponents/GenericStyles.module.css';
import styles from './BrowseValues.module.css';
import { CC, ccConfig } from '../CC/CC';
import { generateQuery } from '../CC/utils';
import { CCProvider, useCCContext } from '../CC/CCContext';
import GenericCheckbox from '../GenericComponents/GenericCheckbox';
import GenericIconButton from '../GenericComponents/GenericIconButton';
import * as ccUtils from '../CC/utils';

interface BrowseValueProps {
  browseValueName?: string;
  field?: string;
  sort?: 'alpha' | 'count';
  onSelect?: (values: string[]) => void;
  searchValues?: string[];
  isOpen?: boolean;
  onClose?: (update: boolean, values?: string[]) => void;
}

interface BrowseValueDataItem {
  value: string;
  count: string;
}

const BrowseValues: React.FC<BrowseValueProps> = ({
  browseValueName = "",
  field = "",
  sort = 'count',
  onSelect = undefined,
  searchValues = [],
  isOpen = false,
  onClose = (update: boolean, values?: string[]) => { }
}) => {

  //console.log("initial field : " + JSON.stringify(field));
  const { t } = useTranslation();
  const instanceRef = useRef({});
  const { cc, setCc, first, setFirst, recordsPerPage, setRecordsPerPage, viewMode, setViewMode, browseMode, setBrowseMode, data, setData } = useCCContext();
  const [currentQuery, setCurrentQuery] = useState("");
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [browseValueData, setBrowseValueData] = useState<BrowseValueDataItem[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const observer = useRef<IntersectionObserver | null>(null);
  const [userInput, setUserInput] = useState('');
  const [currentSort, setCurrentSort] = useState(sort);
  const [localSelectedValues, setLocalSelectedValues] = useState<string[]>([]);
  const previousParams = useRef<{ page: number, userInput: string, sort: 'alpha' | 'count' } | null>(null);
  const lastFetchedInputRef = useRef('');

  const shouldFetchData = (page: number, userInput: string, sort: 'alpha' | 'count') => {
    if (!isOpen) return false;
    if (!previousParams.current) return true;  // First time, no previous params

    const isSame =
      previousParams.current.page === page &&
      previousParams.current.userInput === userInput &&
      previousParams.current.sort === sort;

    return !isSame;
  };

  const lastElementRef = useCallback((node: HTMLDivElement | null) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [hasMore]);

  const fetchData = async (page: number) => {
    lastFetchedInputRef.current = userInput;
    if (shouldFetchData(page, userInput, currentSort)) {
      previousParams.current = { page, userInput, sort: currentSort };
      const pageSize = 100;
      const query = generateQuery(cc, { excludeSimpleSearch: false });
      setCurrentQuery(query);

      const postData = {
        action: "get",
        command: "facets",
        fields: `${field}:${pageSize * (page - 1) + 1}-${pageSize * page}:*${userInput}*`,
        query: query,
        sort: currentSort,
        responseformat: "json"
      };

      try {
        const data = await ccUtils.postWithCache(process.env.REACT_APP_BASE_URL as string, postData, instanceRef);

        let newData: BrowseValueDataItem[] = Array.isArray(data.facets.facet.value) ? data.facets.facet.value : [data.facets.facet.value];
        if (userInput === lastFetchedInputRef.current) {
          setBrowseValueData(prev => {
            const updatedData = [...prev];
            newData.forEach(item => {
              if (!updatedData.find(i => i.value === item.value)) {
                updatedData.push(item);
              }
            });
            return updatedData;
          });
          setHasMore(data.facets.facet.count > pageSize * page);
        } else {
          console.log('Newer user input available');
        }
      } catch (error) {
        //console.error(error);
      }
    }
  };


  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setUserInput(e.target.value);
  };

  const clearInput = () => {
    setUserInput('');
  };

  const handleAlphaSort = () => {
    setPage(1);
    setCurrentSort('alpha');
  };

  const handleCountSort = () => {
    setPage(1);
    setCurrentSort('count');
  };

  const handleCheckboxChange = (checked: boolean, value: string) => {
    setLocalSelectedValues(prev => {
      if (checked) {
        return [...prev, value];
      } else {
        return prev.filter(val => val !== value);
      }
    });
  };

  const handleSearchValueCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const { checked } = e.target;
    setLocalSelectedValues(prev => {
      if (!checked) {
        return prev.filter(val => val !== value);
      }
      return prev;
    });
  };

  useEffect(() => {
    if (page > 1) {
      fetchData(page);
    }
  }, [page, userInput, currentSort]);

  useEffect(() => {
    const query = generateQuery(cc, { excludeSimpleSearch: true });
    if (currentQuery != query) {
      setBrowseValueData([]);
      fetchData(1);
    }
  }, [cc, isOpen]);

  useEffect(() => {
    setBrowseValueData([]);
    fetchData(1);
  }, [userInput, currentSort]);

  useEffect(() => {
    setLocalSelectedValues(selectedValues);
  }, [selectedValues]);

  useEffect(() => {
    setLocalSelectedValues(searchValues);
  }, [searchValues]);

  useEffect(() => {
    console.log('Rendered');
  }, []);

  return (
    <div>
      {/* Modal overlay and container */}
      {isOpen && (
        <>
          <div className={styles.popupoverlay}></div>
          <div className={styles.popup}>
            <div className={styles.header}>
              <span>{t("browse_values")}</span>
            </div>
            <div className={styles.main}>
              <div className={styles.browseValueContainer}>
                <div className={styles.controls}>
                  <div className={styles.maskAndButton}>
                    <div className={styles.maskLabel}>{t("browse_filter")}</div>
                    <input className={styles.mask} type="text" value={userInput} onChange={handleInput} />
                    <div className={styles.clearMaskButton}>
                      <GenericIconButton icon='light/times.svg' width='15px' height='15px' onClick={() => clearInput()} />
                    </div>
                  </div>
                  <div className={styles.sortButtons}>
                    <GenericIconButton isActive={currentSort === 'alpha'} icon='regular/sort-alpha-down.svg' width='15px' height='15px' onClick={() => handleAlphaSort()} />
                    <GenericIconButton isActive={currentSort === 'count'} icon='regular/sort-amount-down.svg' width='15px' height='15px' onClick={() => handleCountSort()} />
                  </div>
                </div>
                <div className={styles.selectedItems}>
                  {localSelectedValues.map((selectedValue) => (
                    <div className={styles.facetValue} key={selectedValue}>
                      <GenericCheckbox
                        id={`browseValue_${field}_${selectedValue}`}
                        name={`browseValue_${field}_${selectedValue}`}
                        value={selectedValue}
                        checked={true}
                        onChange={handleCheckboxChange}
                        label={selectedValue}
                      />
                    </div>
                  ))}
                </div>
                <div className={styles.content} >
                  {browseValueData.filter(item => !localSelectedValues.includes(item.value) && !searchValues.includes(item.value)).map((item) => (
                    <div className={styles.facetValue} key={item.value} ref={browseValueData.indexOf(item) === browseValueData.length - 1 ? lastElementRef : null}>
                      <GenericCheckbox
                        id={`browseValue_${field}_${item.value}`}
                        name={`browseValue_${field}_${item.value}`}
                        value={item.value}
                        checked={localSelectedValues.includes(item.value)}
                        onChange={handleCheckboxChange}
                        label={`${item.value} (${item.count})`}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className={styles.footer}>
              <button className={genericStyles.GenericButton} onClick={(e) => {
                e.preventDefault();
                onClose(true, localSelectedValues);
              }}>
                {t("browse_apply")}
              </button>
              <button className={genericStyles.GenericButton} onClick={(e) => {
                e.preventDefault();
                onClose(false);
              }}>
                {t("browse_cancel")}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default BrowseValues;
