import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createRoot } from 'react-dom/client';
import styles from './ScrollResults.module.css';
import { CC, ccConfig } from '../CC/CC';
import { CCProvider, useCCContext } from '../CC/CCContext';
import ImageWithFallback from '../GenericComponents/ImageWithFallback';
import * as ccUtils from '../CC/utils';
import RecordPopup from './RecordPopup';
import RecordIconScroll from '../Record/RecordIconScroll';
import RecordList from '../Record/RecordList';

const ScrollResults: React.FC = () => {
  const { t } = useTranslation();
  const instanceRef = useRef<any>({});
  const { cc, sort, viewMode, data } = useCCContext();
  const [items, setItems] = useState<any[]>([]);
  const [selectedRecord, setSelectedRecord] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number | null>(null);
  const [columns, setColumns] = useState<number[]>(new Array(5).fill(0));
  const [renderCount, setRenderCount] = useState(0);
  const isLoading = useRef(false);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const nextStartIndex = useRef(1);
  const sentinelRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const numColumnsRef = useRef(0);
  const localViewMode = useRef("list");

  const forceUpdate = () => setRenderCount(renderCount + 1);

  useEffect(() => {
    setIsModalOpen(false);
  }, [cc, sort]);

  const IconItem: React.FC<{ record: any; index: number }> = ({ record, index }) => {
    return (
      <RecordIconScroll
        key={`scroll_icons_${record.metadata.recordnumber}`}
        record={record}
        index={index}
        handleLinkClick={handleLinkClick}
      />
    );
  };

  const handleLinkClick = (event: React.MouseEvent, recordNumber: number) => {
    event.preventDefault();
    selectRecord(recordNumber);
  };

  const ListItem: React.FC<{ record: any; index: number }> = ({ record, index }) => {
    return (
      <RecordList
        key={`scroll_list_${record.metadata.recordnumber}`}
        record={record}
        index={index}
        handleLinkClick={handleLinkClick}
      />
    );
  };

  const distributeItems = async (batch: any[]) => {
    const tempColumns = [...columns];

    const itemsWithHeightPromises = batch.map(async (item) => {
      const tempDiv = document.createElement('div');
      tempDiv.style.visibility = 'hidden';
      tempDiv.style.position = 'fixed';
      tempDiv.style.left = '-9999px';

      document.body.appendChild(tempDiv);
      const root = createRoot(tempDiv);
      root.render(<IconItem record={item} index={1} />);

      return new Promise<{ item: any; height: number }>((resolve) => {
        const imageSrc = item.data?.Record?.Image?.Image ?? 'noimage.cci';
        const img = new Image();
        img.src = `https://collections.quaibranly.fr/cc/imageproxy.ashx?server=localhost&port=15012&filename=${imageSrc}&width=180&borderwidth=0&borderheight=0&bordercolor=e8e8e8&bg=ffffff&passepartoutwidth=0&passepartoutheight=0&passepartoutcolor=f8f8f8&cache=yes`;

        img.onload = () => {
          requestAnimationFrame(() => {
            setTimeout(() => {
              const height = tempDiv.offsetHeight;
              document.body.removeChild(tempDiv);
              resolve({ item, height });
            }, 0);
          });
        };

        img.onerror = () => {
          const height = 100;
          resolve({ item, height });
        };
      });
    });

    const itemsWithHeight = await Promise.all(itemsWithHeightPromises);

    const sortedItemsWithHeight = itemsWithHeight.sort((a, b) => b.height - a.height);

    sortedItemsWithHeight.forEach(({ item, height }) => {
      const minColumnIndex = tempColumns.indexOf(Math.min(...tempColumns));
      tempColumns[minColumnIndex] += height;
      item.column = minColumnIndex;
    });

    setColumns(tempColumns);
  };

  const fetchData = useCallback(async () => {
    if (isLoading.current || items.length >= 5000 || (totalRecords !== null && items.length >= totalRecords)) return;
    isLoading.current = true;
    const startIndex = nextStartIndex.current;
    const endIndex = startIndex + 29;

    const postData = ccUtils.generatePayload(cc, sort, startIndex, endIndex);

    try {
      const data = await ccUtils.postWithCache(process.env.REACT_APP_BASE_URL as string, postData, instanceRef);

      if (!data.records || !data.records.record) {
        isLoading.current = false;
        return;
      }

      if (totalRecords === null) {
        setTotalRecords(data.request.count);
      }

      let records = data.records.record;
      records = Array.isArray(records) ? records : [records];

      if (localViewMode.current === 'list') {
        records.forEach((record: any) => record.column = 0);
      } else {
        await distributeItems(records);
      }

      setItems(prevItems => [...prevItems, ...records]);
      nextStartIndex.current += 30;
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      isLoading.current = false;
    }
  }, [items, cc, sort]);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (observerRef.current) {
            observerRef.current.disconnect();
          }
          setTimeout(() => fetchData(), 0);
        }
      });
    });

    observerRef.current = observer;

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [fetchData]);

  const setNumColumns = () => {
    let newNumColumns = 1;
    if (localViewMode.current === 'icons' && containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      newNumColumns = containerWidth < 650 ? 2 : containerWidth < 850 ? 3 : containerWidth < 1050 ? 4 : 5;
    }

    if (newNumColumns !== numColumnsRef.current) {
      nextStartIndex.current = 1;
      numColumnsRef.current = newNumColumns;
      setColumns(new Array(newNumColumns).fill(0));
      setItems([]);
      setTotalRecords(null);
      isLoading.current = false;
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    const handleResize = setNumColumns;
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    nextStartIndex.current = 1;
    setItems([]);
    setColumns(new Array(5).fill(0));
    setTotalRecords(null);
    isLoading.current = false;
    setTimeout(() => fetchData(), 0);
  }, [cc, sort]);

  useEffect(() => {
    nextStartIndex.current = 1;
    localViewMode.current = viewMode;
    setItems([]);
    setTotalRecords(null);
    setNumColumns();
    setTimeout(() => fetchData(), 0);
  }, [viewMode]);

  const selectRecord = (recordNumber: number) => {
    setSelectedRecord(recordNumber);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getColumnClass = (myViewMode: string) => {
    switch (myViewMode) {
      case 'icons':
        return styles.column;
      case 'list':
        return styles.listItem;
      default:
        return '';
    }
  };

  if (!data.records || !data.records.record) {
    return <div>{t("no_records_found")}</div>;
  }

  return (
    <div className={styles.masonryContainer} ref={containerRef}>
      <div className={styles.columnsContainer}>
        {Array.from({ length: numColumnsRef.current }).map((_, columnIndex) => (
          <div className={getColumnClass(localViewMode.current)} key={columnIndex}>
            {items.filter(item => item && item.column === columnIndex).map((record) => {
              const index = items.indexOf(record);
              if (localViewMode.current === 'icons') {
                return <IconItem record={record} index={index + 1} key={`scroll_parent_icon${index}`} />;
              } else if (localViewMode.current === 'list') {
                return <ListItem record={record} index={index + 1} key={`scroll_parent_list${index}`} />;
              }
              return null;
            })}
          </div>
        ))}
      </div>

      {items.length < 500 && <div ref={sentinelRef}></div>}

      {items.length >= 500 && !isLoading.current && (totalRecords === null || items.length < totalRecords) ? (
        <div className={styles.loadMore} onClick={fetchData}>
          {t("scroll_load_more")}
        </div>
      ) : (
        totalRecords !== null && items.length >= totalRecords && (
          <p style={{ textAlign: "center" }}>
            <b>{t("scroll_no_more")}</b>
          </p>
        )
      )}

      {isModalOpen && <RecordPopup recordNumber={selectedRecord!} onClose={closeModal} />}
    </div>
  );
};

export default ScrollResults;
