import React, { useState, useRef, useEffect } from 'react';

type Props = {
  url: string;
  children?: React.ReactNode;
};

const RightClickPopup: React.FC<Props> = ({ url, children }) => {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleContextMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();

    setPosition({
      top: e.clientY,
      left: e.clientX
    });

    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  const handleOpenInNewTab = (e: React.MouseEvent) => {
    e.stopPropagation();
    window.open(url, '_blank');
    handleClose();
  };

  useEffect(() => {
    const handleDocumentClick = (e: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
        handleClose();
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  return (
    <div ref={containerRef} onContextMenu={handleContextMenu}>
      {children}

      {visible && (
        <div
          style={{
            position: 'fixed',
            top: `${position.top}px`,
            left: `${position.left}px`,
            backgroundColor: 'white',
            border: '1px solid #ccc',
            padding: '8px',
            zIndex: 1000,
            cursor: 'pointer'
          }}
          onClick={handleOpenInNewTab}
        >
          Open record in new tab
        </div>
      )}
    </div>
  );
};

export default RightClickPopup;
