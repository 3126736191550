import React, { useState, useEffect, useRef, useCallback, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import genericStyles from '../GenericComponents/GenericStyles.module.css';
import styles from './Facet.module.css';
import { CC, ccConfig } from '../CC/CC'; 
import { generateQuery } from '../CC/utils'; 
import { CCProvider, useCCContext } from '../CC/CCContext';
import GenericIconButton from '../GenericComponents/GenericIconButton';
import GenericCheckbox from '../GenericComponents/GenericCheckbox';
import * as ccUtils from '../CC/utils';

interface FacetProps {
  facetName?: string;
  field?: string;
  contentHeight: string;
  sort?: 'alpha' | 'count'; 
  onSelect?: (values: string[]) => void;
  showSelectedAbove?: boolean;
}

interface FacetDataItem {
  value: string;
  count: string;
}

const Facet: React.FC<FacetProps> = ({ facetName = "", field = "", contentHeight, sort = 'count', onSelect = undefined, showSelectedAbove = true }) => {
  const { t } = useTranslation();
  const instanceRef = useRef({});
  const { cc, setCc, first, setFirst, recordsPerPage, setRecordsPerPage, viewMode, setViewMode, browseMode, setBrowseMode, data, setData } = useCCContext();
  const ctx = useCCContext();
  const [currentQuery, setCurrentQuery] = useState("");
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [renderCount, setRenderCount] = useState(0);
  const [facetData, setFacetData] = useState<FacetDataItem[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const observer = useRef<IntersectionObserver | null>(null);
  const [userInput, setUserInput] = useState('');
  const [currentSort, setCurrentSort] = useState(sort);
  const [facetCount, setFacetCount] = useState(0);
  const facetRootRef = useRef<HTMLDivElement>(null);
  const lastFetchedInputRef = useRef('');

  if(facetName !== "" && ccConfig.facets[facetName]) field = ccConfig.facets[facetName].query;

  const lastElementRef = useCallback((node: HTMLDivElement | null) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
        forceUpdate();
      }
    });
    if (node) observer.current.observe(node);
  }, [hasMore]);

  const fetchData = async (page: number) => {
    lastFetchedInputRef.current = userInput;
    const pageSize = 100;
    const query = generateQuery(cc, { excludeFacet: facetName }); 
    setCurrentQuery(query);
  
    const postData = {
      action: "get",
      command: "facets",
      fields: `${field}:${pageSize * (page - 1) + 1}-${pageSize * page}:*${userInput}*`,
      query: query,
      sort: currentSort,
      responseformat: "json"
    };
  
    try {
      const data = await ccUtils.postWithCache(process.env.REACT_APP_BASE_URL as string, postData, instanceRef);

      let newData: FacetDataItem[] = data?.facets?.facet ? Array.isArray(data?.facets?.facet?.value) ? data?.facets?.facet?.value : [data?.facets?.facet?.value] : [];
       
      if (userInput === lastFetchedInputRef.current) {
        setFacetData(prev => {
            const updatedData = [...prev];
            newData.forEach(item => {
                if (!updatedData.find(i => i.value === item.value)) {
                    updatedData.push(item);
                }
            });
            return updatedData;
        });
        setHasMore(data?.facets?.facet && data.facets.facet.count > pageSize * page);
        setFacetCount(data?.facets?.facet ? data.facets.facet.count : 0);
      } else {
        console.log('Skipped data update');
      }
        
    } catch (error) {
      console.error(error);
    }
  };
  
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setUserInput(e.target.value);
    setPage(1);
    setFacetData([]);
    forceUpdate();
    if (viewMode == "homepage") {
      setViewMode('list');
    }
  };

  const clearInput = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setUserInput('');
    setFacetData([]);
    setPage(1);
    forceUpdate();
    if (viewMode == "homepage") {
      setViewMode('list');
    }
  };

  const handleAlphaSort = () => {
    setCurrentSort('alpha');
    setFacetData([]);
    setPage(1);
    forceUpdate();
  };

  const handleCountSort = () => {
    setCurrentSort('count');
    setFacetData([]);
    setPage(1);
    forceUpdate();
  };

  const handleCheckboxChange = (checked: boolean, value: string) => {
      setFirst(1);
  
      const currentFacets = cc.facets || {};
      let updatedFacets = { ...currentFacets };
  
      if (!updatedFacets[facetName]) {
          updatedFacets[facetName] = [];
      }
  
      if (checked && !updatedFacets[facetName].includes(value)) {
          updatedFacets[facetName].push(value);
      } else {
          updatedFacets[facetName] = updatedFacets[facetName].filter(val => val !== value);
      }
  
      setCc(prevCc => {
          return { ...prevCc, facets: updatedFacets };
      });
      
      if (viewMode == "homepage") {
        setViewMode('list');
      }
  };
  
  const forceUpdate = () => setRenderCount(renderCount + 1);

  useEffect(() => {
    fetchData(page);
  }, [page, renderCount]);
 
  useEffect(() => {
    const query = generateQuery(cc, { excludeFacet: facetName }); 
    if(currentQuery != query) {
        setFacetData([]);
        setPage(1);
        forceUpdate();
    }
  }, [cc]);

  return (
    <div className={styles.facetContainer} ref={facetRootRef} role="region" aria-labelledby={`${facetName}-header`}>
      <div className={styles.facetName} id={`${facetName}-header`}>
        <span className={styles.facetNameLabel}>
          {ccConfig.facets[facetName] ? t(ccConfig.facets[facetName].label) : t("No label")}
        </span>
        <span className={styles.facetNameCount}>
          ({facetCount} {facetCount !== 1 ? t('facet_values') : t('facet_value')})
        </span>
      </div>
      <div className={styles.header}>
          <div className={styles.inputContainer}>
            <input className={styles.mask} type="text" value={userInput} placeholder={t("facet_filter")} onChange={handleInput} aria-label={`${facetName} filter`} />
          </div>
          <GenericIconButton icon='light/times.svg' width='15px' height='15px' fill='#f8f8f8' hoverBackgroundColor='#56ce67' onClick={clearInput} aria-label="Clear input"/>
          <div className={styles.sortButtons}>
              <GenericIconButton isActive={currentSort === 'alpha'} activeBorderColor='#ffffff' icon='regular/sort-alpha-down.svg' width='15px' height='15px' fill='#f8f8f8' hoverFill='#0000ff' hoverBackgroundColor='#56ce67' onClick={handleAlphaSort} aria-label="Sort alphabetically" />
              <GenericIconButton isActive={currentSort === 'count'} activeBorderColor='#ffffff' icon='regular/sort-amount-down.svg' width='15px' height='15px' fill='#f8f8f8' hoverFill='#0000ff' hoverBackgroundColor='#56ce67' onClick={handleCountSort} aria-label="Sort by count" />
          </div>
      </div>
      <div className={styles.selectedItems}>
        {showSelectedAbove && cc.facets[facetName]?.length > 0 && (
            <div className={styles.clearAllContainer} onClick={() => {ccUtils.clearFacet(ctx, facetName)}} aria-label="Clear all selections">
              <div className={styles.clearAllIconContainer}>
                <div className={styles.clearAll} />
                <div className={styles.clearAll} />
                <div className={styles.clearAll} />
              </div>
              <div className={styles.clearAllText}>
                {t("facet_clear")}
              </div>
            </div>
        )}
        
        {showSelectedAbove && cc.facets[facetName]?.map((selectedValue) => (
          <div key={selectedValue}>
            <GenericCheckbox
                id={`facet_${field}_${selectedValue}`}
                name={`facet_${field}_${selectedValue}`}
                value={selectedValue} 
                checked={true} 
                onChange={handleCheckboxChange} 
                label={selectedValue}
                aria-labelledby={`facet_${field}_${selectedValue}-label`}
            />
          </div>
        ))}
      </div>
  
      <div className={styles.content} style={{height: contentHeight}} role="list">
        {facetData.filter(item => (!showSelectedAbove) || !(cc.facets[facetName]?.includes(item.value))).map((item) => (
          <div key={item.value} ref={facetData.indexOf(item) === facetData.length - 1 ? lastElementRef : null} role="listitem">
            <GenericCheckbox
                id={`facet_${field}_${item.value}`}
                name={`facet_${field}_${item.value}`}
                value={item.value} 
                checked={ 
                  cc.facets[facetName] 
                  ? cc.facets[facetName].includes(item.value) 
                  : false
                } 
                onChange={handleCheckboxChange} 
                label={`${item.value} (${item.count})`}
                aria-labelledby={`facet_${field}_${item.value}-label`}
            />
         </div>
        ))}
      </div>
    </div>
  )
}

export default Facet;
