import React, { ChangeEvent } from 'react';
import styles from './Navigator.module.css';
import { CCProvider, useCCContext } from '../CC/CCContext';
import CurPage from './curPage';
import Paginator from './Paginator';
import ResultSetSlider from './ResultSetSlider';

function Navigator() {
    const { cc, setCc, first, setFirst, recordsPerPage, setRecordsPerPage, viewMode, setViewMode, browseMode, setBrowseMode, data, setData } = useCCContext();
    const totalRecords = data.request?.count || 0;

    if(totalRecords==0) {
        return (<div></div>);
    }

    return (
        <div className = {styles.Navigator}>
          <Paginator />
          <CurPage />
         <ResultSetSlider />
        </div>
    )

  }

export default Navigator;