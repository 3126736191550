import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Paragraph from '@tiptap/extension-paragraph';
import { mergeAttributes } from '@tiptap/core';
import Color from '@tiptap/extension-color';
import React, { useState, useEffect, MouseEvent as ReactMouseEvent } from 'react';
import GenericIconButton from '../GenericComponents/GenericIconButton';
import styles from './EditorComponent.module.css';
import contentStyles from './EditorComponentContent.module.css';
import ReactComponent from './EditorExtension.jsx';
import { SketchPicker } from 'react-color';

type EditorComponentProps = {
  content: string;
  onContentChange: (content: string) => void;
};

type MatrixSelectorProps = {
  onSelect: (rows: number, cols: number) => void;
};

const MatrixSelector: React.FC<MatrixSelectorProps> = ({ onSelect }) => {
  const [hovered, setHovered] = useState<{ rows: number, cols: number }>({ rows: 0, cols: 0 });

  const handleMouseEnter = (rows: number, cols: number) => {
    setHovered({ rows, cols });
  };

  const handleClick = () => {
    onSelect(hovered.rows, hovered.cols);
  };

  return (
    <div className={styles.matrixSelector} onMouseLeave={() => setHovered({ rows: 0, cols: 0 })}>
      <div className={styles.matrixGrid}>
        {[...Array(10)].map((_, rowIndex) => (
          <div key={rowIndex} className={styles.matrixRow}>
            {[...Array(10)].map((_, colIndex) => (
              <div
                key={colIndex}
                className={`${styles.matrixCell} ${hovered.rows >= rowIndex + 1 && hovered.cols >= colIndex + 1 ? styles.matrixCellHovered : ''}`}
                onMouseEnter={() => handleMouseEnter(rowIndex + 1, colIndex + 1)}
                onClick={handleClick}
              />
            ))}
          </div>
        ))}
      </div>
      <div className={styles.matrixPreview}>
        {hovered.rows} x {hovered.cols}
      </div>
    </div>
  );
};

type DraggablePopupProps = {
  children: React.ReactNode;
  closePopup: () => void;
};

const DraggablePopup: React.FC<DraggablePopupProps> = ({ children, closePopup }) => {
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e: ReactMouseEvent<HTMLDivElement>) => {
    setDragging(true);
    setOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (dragging) {
      setPosition({
        x: e.clientX - offset.x,
        y: e.clientY - offset.y,
      });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  useEffect(() => {
    if (dragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragging]);

  return (
    <div
      className={styles.draggablePopup}
      style={{ top: position.y, left: position.x }}
      onMouseDown={handleMouseDown}
    >
      <div className={styles.draggableHeader}>
        <span>Draggable Popup</span>
        <button className={styles.closeButton} onClick={closePopup}>X</button>
      </div>
      {children}
    </div>
  );
};

const EditorComponent: React.FC<EditorComponentProps> = ({ content, onContentChange }) => {
  const [showMatrixSelector, setShowMatrixSelector] = useState(false);
  const [selectedHeader, setSelectedHeader] = useState<string>('0');
  const [isHtmlMode, setIsHtmlMode] = useState(false);
  const [htmlContent, setHtmlContent] = useState(content);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [currentColor, setCurrentColor] = useState<string>('#000000');
  const [showDivStylingPopup, setShowDivStylingPopup] = useState(false);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Paragraph.extend({
        parseHTML() {
          return [{ tag: 'div' }]
        },
        renderHTML({ HTMLAttributes }) {
          return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
        },
      }),
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
      TextAlign.configure({
        types: ['heading', 'paragraph', 'reactComponent', 'div'], // Add reactComponent and div to TextAlign types
      }),
      TextStyle,
      Color,
      ReactComponent,
    ],
    content: content,
    onUpdate: ({ editor }) => {
      const updatedContent = editor.getHTML();
      onContentChange(updatedContent);
      setHtmlContent(updatedContent);
      updateHeaderDropdown();
    },
  });

  const updateHeaderDropdown = () => {
    if (editor) {
      if (editor.isActive('heading', { level: 1 })) {
        setSelectedHeader('1');
      } else if (editor.isActive('heading', { level: 2 })) {
        setSelectedHeader('2');
      } else if (editor.isActive('heading', { level: 3 })) {
        setSelectedHeader('3');
      } else {
        setSelectedHeader('0');
      }
    }
  };

  useEffect(() => {
    updateHeaderDropdown();
  }, [editor?.state.selection]);

  const insertTable = (rows: number, cols: number) => {
    if (editor) {
      editor.chain().focus().insertTable({ rows, cols, withHeaderRow: true }).run();
      setShowMatrixSelector(false);
    }
  };

  const applyHeading = (level: 0 | 1 | 2 | 3) => {
    if (editor) {
      if (level === 0) {
        editor.chain().focus().setParagraph().run();
      } else {
        editor.chain().focus().toggleHeading({ level }).run();
      }
      updateHeaderDropdown();
    }
  };

  const handleToggle = () => {
    setIsHtmlMode(!isHtmlMode);
  };

  const handleHtmlChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newContent = event.target.value;
    setHtmlContent(newContent);
    onContentChange(newContent);
    if (editor) {
      editor.commands.setContent(newContent);
    }
  };

  const insertContent = () => {
    if (editor) {
      editor.chain().focus().insertContent("<react-component count='0'></react-component>").run();
    }
  };

  const insertDiv = () => {
    if (editor) {
      editor.chain().focus().insertContent('<div class="styled-div">New div</div>').run();
    }
  };

  const handleColorChange = (color: any) => {
    setCurrentColor(color.hex);
    if (editor) {
      editor.chain().focus().setColor(color.hex).run();
    }
  };

  const handleDivStyling = (align: string, border: string) => {
    if (editor) {
      const transaction = editor.state.tr;
      const { selection } = editor.state;
      const divNode = editor.state.doc.nodeAt(selection.from);

      if (divNode?.type.name === 'div') {
        transaction.setNodeMarkup(selection.from, undefined, {
          ...divNode.attrs,
          style: `text-align: ${align}; border: ${border};`,
        });
        editor.view.dispatch(transaction);
      }
      setShowDivStylingPopup(false);
    }
  };

  return (
    <div>
      <div className={styles.toolbar}>
        <button onClick={insertContent}>Insert Content</button>
        <button onClick={handleToggle}>
          {isHtmlMode ? 'Switch to WYSIWYG' : 'Switch to HTML'}
        </button>
        {editor && !isHtmlMode && (
          <>
            <select
              value={selectedHeader}
              onChange={(e) => applyHeading(parseInt(e.target.value) as 0 | 1 | 2 | 3)}
              className={styles.headerDropdown}
            >
              <option value="0">Normal</option>
              <option value="1">H1</option>
              <option value="2">H2</option>
              <option value="3">H3</option>
            </select>
            <GenericIconButton
              icon="regular/bold.svg"
              onClick={() => editor.chain().focus().toggleBold().run()}
              isActive={editor.isActive('bold')}
            />
            <GenericIconButton
              icon="regular/italic.svg"
              onClick={() => editor.chain().focus().toggleItalic().run()}
              isActive={editor.isActive('italic')}
            />
            <GenericIconButton
              icon="regular/strikethrough.svg"
              onClick={() => editor.chain().focus().toggleStrike().run()}
              isActive={editor.isActive('strike')}
            />
            <GenericIconButton
              icon="regular/list-ul.svg"
              onClick={() => editor.chain().focus().toggleBulletList().run()}
              isActive={editor.isActive('bulletList')}
            />
            <GenericIconButton
              icon="regular/list-ol.svg"
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
              isActive={editor.isActive('orderedList')}
            />
            <GenericIconButton
              icon="regular/quote-right.svg"
              onClick={() => editor.chain().focus().toggleBlockquote().run()}
              isActive={editor.isActive('blockquote')}
            />
            <GenericIconButton
              icon="regular/code.svg"
              onClick={() => editor.chain().focus().toggleCode().run()}
              isActive={editor.isActive('codeBlock')}
            />
            <GenericIconButton
              icon="regular/table.svg"
              onClick={() => setShowMatrixSelector(!showMatrixSelector)}
            />
            <GenericIconButton
              icon="regular/align-left.svg"
              onClick={() => editor.chain().focus().setTextAlign('left').run()}
              isActive={editor.isActive({ textAlign: 'left' })}
            />
            <GenericIconButton
              icon="regular/align-center.svg"
              onClick={() => editor.chain().focus().setTextAlign('center').run()}
              isActive={editor.isActive({ textAlign: 'center' })}
            />
            <GenericIconButton
              icon="regular/align-right.svg"
              onClick={() => editor.chain().focus().setTextAlign('right').run()}
              isActive={editor.isActive({ textAlign: 'right' })}
            />
            <GenericIconButton
              icon="regular/palette.svg"
              onClick={() => setShowColorPicker(!showColorPicker)}
            />
            <GenericIconButton
              icon="regular/div.svg"
              onClick={insertDiv}
            />
            <GenericIconButton
              icon="regular/style.svg"
              onClick={() => setShowDivStylingPopup(!showDivStylingPopup)}
            />
          </>
        )}
      </div>
      {showMatrixSelector && <MatrixSelector onSelect={insertTable} />}
      {showColorPicker && (
        <DraggablePopup closePopup={() => setShowColorPicker(false)}>
          <SketchPicker color={currentColor} onChangeComplete={handleColorChange} />
        </DraggablePopup>
      )}
      {showDivStylingPopup && (
        <DraggablePopup closePopup={() => setShowDivStylingPopup(false)}>
          <div>
            <label>
              Align:
              <select onChange={(e) => handleDivStyling(e.target.value, '1px solid black')}>
                <option value="left">Left</option>
                <option value="center">Center</option>
                <option value="right">Right</option>
              </select>
            </label>
            <label>
              Border:
              <input
                type="text"
                placeholder="e.g., 1px solid black"
                onBlur={(e) => handleDivStyling('left', e.target.value)}
              />
            </label>
          </div>
        </DraggablePopup>
      )}
      {isHtmlMode ? (
        <textarea
          value={htmlContent}
          onChange={handleHtmlChange}
          className={contentStyles.htmlEditor}
        />
      ) : (
        <EditorContent editor={editor} className={contentStyles.editorContent} />
      )}
    </div>
  );
};

export default EditorComponent;
