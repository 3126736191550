import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { useCCContext } from '../CC/CCContext';
import * as ccUtils from '../CC/utils';

const AnnotatedImageViewer = ({ imageName, width = 800, height = 600 }) => {
    const { t } = useTranslation();
    const { cc, setCc, first, setFirst, recordsPerPage, setRecordsPerPage, viewMode, setViewMode, browseMode, setBrowseMode, data, setData, isReady } = useCCContext();
    const ctx = useCCContext();
    const [annotations, setAnnotations] = useState([]);
    const [hoveredAnnotation, setHoveredAnnotation] = useState(null);
    const [isMouseOverImage, setIsMouseOverImage] = useState(false);
    const canvasRef = useRef(null);
    const imageRef = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        const fetchAnnotations = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_USERMANAGEMENT_URL}?action=getImageAnnotation&imageName=${encodeURIComponent(imageName)}`);
                const data = await response.json();
                setAnnotations(data.annotations || []);
            } catch (error) {
                console.error("Failed to fetch annotations:", error);
            }
        };

        fetchAnnotations();
    }, [imageName]);

    const drawAnnotations = useCallback(() => {
        const canvas = canvasRef.current;
        if (!canvas || !isMouseOverImage) return;

        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        annotations.forEach((ann, index) => {
            drawAnnotation(ctx, ann, index, ann === hoveredAnnotation);
        });
    }, [annotations, hoveredAnnotation, isMouseOverImage]);

    const updateCanvasSize = useCallback(() => {
        const image = imageRef.current;
        const canvas = canvasRef.current;
        const container = containerRef.current;

        if (image && canvas && container) {
            const containerWidth = container.offsetWidth;
            const containerHeight = container.offsetHeight;
            const scale = Math.min(containerWidth / image.naturalWidth, containerHeight / image.naturalHeight, 1);
            const scaledWidth = image.naturalWidth * scale;
            const scaledHeight = image.naturalHeight * scale;

            canvas.width = scaledWidth;
            canvas.height = scaledHeight;
            canvas.style.width = `${scaledWidth}px`;
            canvas.style.height = `${scaledHeight}px`;

            image.style.width = `${scaledWidth}px`;
            image.style.height = `${scaledHeight}px`;

            drawAnnotations();
        }
    }, [drawAnnotations]);

    const debouncedUpdateCanvasSize = useCallback(debounce(updateCanvasSize, 100), [updateCanvasSize]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(debouncedUpdateCanvasSize);

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current);
            }
            debouncedUpdateCanvasSize.cancel();
        };
    }, [debouncedUpdateCanvasSize]);

    useEffect(() => {
        drawAnnotations();
    }, [drawAnnotations]);

    const drawAnnotation = (ctx, ann, index, isHovered) => {
        const canvas = canvasRef.current;
        const image = imageRef.current;
        if (!canvas || !image) return;

        const scale = canvas.width / image.naturalWidth;

        ctx.strokeStyle = isHovered ? 'rgba(181, 52,29, 1.0)' : 'rgba(231,228, 223, 1.0)';
        ctx.lineWidth = isHovered ? 3 : 2;
        ctx.setLineDash(isHovered ? [] : [5, 3]);
        ctx.shadowColor = 'rgba(0, 0, 0, 0.6)';
        ctx.shadowBlur = isHovered ? 8 : 4;

        ctx.beginPath();
        ann.points.forEach((point, idx) => {
            const scaledX = point.x * scale;
            const scaledY = point.y * scale;
            if (idx === 0) ctx.moveTo(scaledX, scaledY);
            else ctx.lineTo(scaledX, scaledY);
        });
        if (ann.points.length > 2) {
            ctx.closePath();
        }
        ctx.stroke();
        ctx.setLineDash([]);
    };

    const handleClick = (e, ann) => {
        const canvas = canvasRef.current;
        const image = imageRef.current;
        if (!canvas || !image) return;

        const scale = canvas.width / image.naturalWidth;

        const { x, y } = getMousePos(canvas, e);
        if (isPointInPolygon(x / scale, y / scale, ann)) {
            let currentMonument = "";
            if (Array.isArray(cc.facets['department']) && cc.facets['department'].length > 0) {
                currentMonument = cc.facets['department'][0];
            }
            ccUtils.clearSearch(ctx);
            if (currentMonument !== "") {
                ccUtils.addFacetValue(ctx, 'department', currentMonument);
            }
            ccUtils.addCustomSearch(ctx, '/record/objectnumber', t("record_objectnumber"), ['[' + ann.text + ']'], '');
            setViewMode('list');
        }
    };

    const handleMouseMove = useCallback((e) => {
        const canvas = canvasRef.current;
        const image = imageRef.current;
        if (!canvas || !image) return;

        const scale = canvas.width / image.naturalWidth;
        const { x, y } = getMousePos(canvas, e);

        let hoveredAnn = null;
        for (let ann of annotations) {
            if (isPointInPolygon(x / scale, y / scale, ann)) {
                hoveredAnn = ann;
                break;
            }
        }

        if (hoveredAnn !== hoveredAnnotation) {
            setHoveredAnnotation(hoveredAnn);
        }
    }, [annotations, hoveredAnnotation]);

    const handleMouseEnter = useCallback(() => {
        setIsMouseOverImage(true);
    }, []);

    const handleMouseLeave = useCallback(() => {
        setHoveredAnnotation(null);
        setIsMouseOverImage(false);
    }, []);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        canvas.addEventListener('mousemove', handleMouseMove);
        canvas.addEventListener('mouseenter', handleMouseEnter);
        canvas.addEventListener('mouseleave', handleMouseLeave);

        return () => {
            canvas.removeEventListener('mousemove', handleMouseMove);
            canvas.removeEventListener('mouseenter', handleMouseEnter);
            canvas.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, [handleMouseMove, handleMouseEnter, handleMouseLeave]);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const handleCanvasClick = (e) => {
            annotations.forEach(ann => handleClick(e, ann));
        };

        canvas.addEventListener('click', handleCanvasClick);

        return () => {
            if (canvas) {
                canvas.removeEventListener('click', handleCanvasClick);
            }
        };
    }, [annotations]);

    const getMousePos = (canvas, evt) => {
        const rect = canvas.getBoundingClientRect();
        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;
        return {
            x: (evt.clientX - rect.left) * scaleX,
            y: (evt.clientY - rect.top) * scaleY
        };
    };

    const isPointInPolygon = (x, y, polygon) => {
        let inside = false;
        for (let i = 0, j = polygon.points.length - 1; i < polygon.points.length; j = i++) {
            const xi = polygon.points[i].x, yi = polygon.points[i].y;
            const xj = polygon.points[j].x, yj = polygon.points[j].y;
            const intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }
        return inside;
    };

    return (
        <div ref={containerRef} style={{ position: 'relative', display: 'inline-block', width: '100%', height: '100%', maxWidth: width, maxHeight: height }}>
            <img
                ref={imageRef}
                src={imageName}
                alt="Annotatable"
                style={{ display: 'block', width: '100%', height: '100%' }}
                onLoad={updateCanvasSize}
            />
            <canvas
                ref={canvasRef}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    cursor: 'crosshair'
                }}
            />
        </div>
    );
};

export default AnnotatedImageViewer;
