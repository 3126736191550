import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface ImageWithFallbackProps {
  src: string;
  alt: string;
  placeholder: string;
  className?: string;
  width?: number | string;
  height?: number | string;
  maxWidth?: number | string;
  maxHeight?: number | string;
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  src,
  alt,
  placeholder,
  className,
  width,
  height,
  maxWidth,
  maxHeight
}) => {
  const { t } = useTranslation();
  const [imageSrc, setImageSrc] = useState(placeholder);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setImageLoaded(false);
    setImageSrc(src);
  }, [src]);

  const handleError = () => {
    setImageSrc(placeholder);
    setTimeout(() => {
      setImageLoaded(true); // Stop loading indicator even if fallback image is shown
    }, 0);
  };

  const handleLoad = () => {
    setTimeout(() => {
      setImageLoaded(true);
    }, 0);
  };

  //console.log(imageSrc);

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      {!imageLoaded && (
        <div className={className} style={{
          width: width || 'auto',
          height: height || 'auto',
          maxWidth: maxWidth || 'none',
          maxHeight: maxHeight || 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '14px',
          color: '#999',
          marginTop: 10,
          marginLeft: 10
        }}>
          {t("loading_image")}
        </div>
      )}
      <img
        src={imageSrc}
        alt={alt}
        className={className}
        onError={handleError}
        onLoad={handleLoad}
        style={{
          maxWidth: maxWidth || 'none',
          maxHeight: maxHeight || 'none',
          width: maxWidth ? 'auto' : width,
          height: maxHeight ? 'auto' : height,
          display: imageLoaded ? 'block' : 'none'
        }}
      />
    </div>
  );
};

export default ImageWithFallback;
