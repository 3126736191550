import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import { I18nextProvider } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import genericStyles from './GenericComponents/GenericStyles.module.css';
import styles from './App.module.css';
import { CCProvider, useCCContext } from './CC/CCContext';
import './CC/CC';
import Startup from './Startup';
import User from './User/User';
import Mobile from './Mobile/Mobile';
import Header from './Header/Header';
import HeaderMobile from './Header/HeaderMobile';
import Footer from './Footer/Footer';
import SimpleSearchBox from './Search/SimpleSearchBox';
import Filter from './Filter/Filter';
import Facet from './Facet/Facet';
import HierarchicalFacet from './Facet/HierarchicalFacet';
import Navigator from './Navigator/Navigator';
import ViewMode from './Navigator/ViewMode';
import BrowseMode from './Navigator/BrowseMode';
import ResultSet from './Result/ResultSet';
import Map from './Record/Map';
import QuerySummary from './Result/QuerySummary';
import Sort from './Navigator/Sort';
import EmbedCount from './Embed/EmbedCount';
import EmbedFacet from './Embed/EmbedFacet';
import EmbedArticle from './Embed/EmbedArticle';
import DebugCC from './Diagnostics/DebugCC';
import RightClickPopup from './GenericComponents/RightClickPopup';
import HomepageBlock from './HomePageblock';
import GenericIconButton from './GenericComponents/GenericIconButton';
import AlbumViewer from './AlbumEditor/AlbumViewer';
import AnnotatedImageViewer from './AlbumEditor/AnnotatedImageViewer';
import { ccConfig, CC, Search } from './CC/CC';
import * as ccUtils from './CC/utils';


function MainContent() {
  const { t } = useTranslation();
  const { cc, setCc, first, setFirst, recordsPerPage, setRecordsPerPage, viewMode, setViewMode, browseMode, setBrowseMode, data, setData, isReady } = useCCContext();
  const ctx = useCCContext();
  const [debugOpen, setDebugOpen] = useState(false);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [introTextEnglish, setIntroTextEnglish] = useState<string>('');
  const [introTextFrench, setIntroTextFrench] = useState<string>('');
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  //const monumentName = 'Hôtel de la Marine'; // Example monument name

  //embed stuff
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const embedType = params.get('embed');
  const facetValue = params.get('facet');
  const articleId = params.get('articleId');
  const state = params.get('state');

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const queryParams = new URLSearchParams(window.location.search);
  //const isTabletOrMobile = queryParams.get('tablet') === 'true' || queryParams.get('mobile') === 'true';
  const isTabletOrMobile = window.innerWidth < 1080 || queryParams.get('tablet') === 'true' || queryParams.get('mobile') === 'true';


  const getMonumentParam = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('monument') || 'no monument specified';
  };

  const handleDebugOpen = () => {
    setDebugOpen(true);
  };

  const handleDebugClose = () => {
    setDebugOpen(false);
  };

  const handleAddCustomSearch = () => {
    ccUtils.addCustomSearch(ctx, '*', 'All fields', ['masque', 'voiture'], '');
    console.log('Custom search added:', cc.customSearchCriteria);
  };

  const handleCustomSearch = () => {
    ccUtils.customSearch(ctx, '/Record/Title', 'Title', 'masque', 'Searching for Masque');
    console.log('Custom search set:', cc.customSearchCriteria);
  };

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScrollToTop && window.scrollY > 1000) {
        setShowScrollToTop(true);
      } else if (showScrollToTop && window.scrollY <= 1000) {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener('scroll', checkScrollTop);

    return () => window.removeEventListener('scroll', checkScrollTop);
  }, [showScrollToTop]);

  useEffect(() => {

    const getMonumentTexts = async () => {
      try {
        const departmentFacet = cc.facets['department'];
        if (departmentFacet && departmentFacet.length === 1) {
          const monumentName = departmentFacet[0];
          const data = await ccUtils.fetchMonumentHtml(monumentName);
          if (data.records && data.records.record && data.records.record.data && data.records.record.data.record) {
            const { introeng, introfra } = data.records.record.data.record;
            setIntroTextEnglish(introeng || '');
            setIntroTextFrench(introfra || '');
          } else {
            console.error('Unexpected response structure:', data);
          }
        } else {
          if (ccUtils.generateQuery(cc).indexOf('thème: Tapisseries') > 0 || ccUtils.generateQuery(cc).indexOf('theme: Tapisseries') > 0) {
            setIntroTextEnglish('<span lang="EN"><p align="LEFT" dir="LTR"><strong>THE TAPESTRY COLLECTION</strong></p> <p align="LEFT" dir="LTR">Welcome to the database of the tapestries collection of the Centre des monuments nationaux, one of the richest in France!<br />Discover the Apocalypse Tapestry presented at the Château d\'Angers or that of the History of Tancred and Clorinde preserved at the Château de Châteaudun and nearly a hundred other tapestries woven from the 14th to the 19th century during your navigation.<br />For each one, you will find a documentary notice and a few words about its history.</p></span>');
            setIntroTextFrench('<span lang="FR"><p align="LEFT" dir="LTR"><strong>LA COLLECTION DES TAPISSERIES</strong></p> <p align="LEFT" dir="LTR">Bienvenue sur la base de données de la collection de tapisseries du Centre des monuments nationaux, une des plus riches de France !<br />Découvrez la Tenture de l’Apocalypse présentée au château d’Angers ou encore celle de l\'Histoire de Tancrède et Clorinde conservée au château de Châteaudun et près d’une centaine autres tapisseries tissées du XIVe au XIXe siècle lors de votre navigation.<br />Pour chacune, vous trouverez une notice documentaire et quelques mots sur son histoire.<br /></p></span>');
          } else {
            setIntroTextEnglish('<span lang="EN"><p align="LEFT" dir="LTR"><strong>THE COLLECTION OF THE CENTRE DES MONUMENTS NATIONAUX</strong></p> <p align="LEFT" dir="LTR">This database brings together the collections of a certain number of monuments from the Centre des monuments nationaux network. Others will gradually enrich this fund.</p></span>');
            setIntroTextFrench('<span lang="FR"><p align="LEFT" dir="LTR"><strong>LES COLLECTIONS DU CENTRE DES MONUMENTS NATIONAUX</strong></p> <p align="LEFT" dir="LTR">Cette base rassemble les collections d’un certain nombre de monuments du réseau du Centre des monuments nationaux. D’autres seront amenées progressivement à enrichir ce fonds.</p></span>');
          }
        }
      } catch (error) {
        console.error('Error fetching monument data:', error);
      }
    };
    
    const getMonumentImage = async () => {
      try {
        const departmentFacet = cc.facets['department'];
        console.log("cc", JSON.stringify(cc));
        console.log("departmentFacet", departmentFacet);
        if (departmentFacet && departmentFacet.length === 1) {
          const monumentName = departmentFacet[0];
          const imageUrl = await ccUtils.fetchMonumentImage(monumentName);
          setImageUrl(imageUrl);
          console.log("setImageUrl", imageUrl);
        } else {
          if (ccUtils.generateQuery(cc).indexOf('thème: Tapisseries') > 0 || ccUtils.generateQuery(cc).indexOf('theme: Tapisseries') > 0) {
            setImageUrl('CMN_dbw16_0863.jpg');
            console.log("setImageUrl", "tapis");
          } else {
            setImageUrl('121755--DBW23-0113.jpg');
            console.log("setImageUrl", "homepage");
          }
        }
      } catch (error) {
        console.error('Error fetching monument data:', error);
      }
    };

    getMonumentTexts();
    getMonumentImage();

  }, [cc])

  if (!isReady) {
    return <div></div>;
  }

  if (embedType === 'count') {
    return <EmbedCount state={state} ccContext={ctx} />;
  }

  if (embedType === 'facet' && facetValue) {
    return <EmbedFacet facetName={facetValue} />
  }

  if (embedType === 'article' && articleId) {
    return <EmbedArticle articleId={articleId} ccContext={ctx} />
  }

  return (
    <I18nextProvider i18n={i18n}>
      {isTabletOrMobile ? (
        <Mobile></Mobile>
      ) : (
        <div className={styles.App}>
          <Header />
          <div style={{ 'display': 'none' }}>
            <div onClick={handleAddCustomSearch} style={{ padding: '0px', margin: '0px', border: '1px solid black', cursor: 'pointer' }}>
              Click me to add custom search
            </div>
            <div onClick={handleCustomSearch} style={{ padding: '0px', margin: '0px', border: '1px solid black', cursor: 'pointer' }}>
              Click me to set custom search
            </div>
            <div onClick={() => ccUtils.activateFilter(ctx, "objects")} style={{ padding: '0px', margin: '0px', border: '1px solid black', cursor: 'pointer' }}>
              Activate Object Filter
            </div>
            <div onClick={() => ccUtils.deactivateFilter(ctx, "objects")} style={{ padding: '0px', margin: '0px', border: '1px solid black', cursor: 'pointer' }}>
              Deactivate Object Filter
            </div>
            <div onClick={() => ccUtils.clearFacet(ctx, "words")} style={{ padding: '0px', margin: '0px', border: '1px solid black', cursor: 'pointer' }}>
              Clear Words Facet
            </div>
            <div onClick={() => ccUtils.addFacetValue(ctx, "words", "bois")} style={{ padding: '0px', margin: '0px', border: '1px solid black', cursor: 'pointer' }}>
              Add New Word to Words Facet
            </div>
            <div onClick={() => ccUtils.addFacetValue(ctx, "words", ["bois", "voiture"])} style={{ padding: '0px', margin: '0px', border: '1px solid black', cursor: 'pointer' }}>
              Add New Words to Words Facet
            </div>
            <div onClick={() => ccUtils.clearSearch(ctx)} style={{ padding: '20px', margin: '10px', border: '1px solid black', cursor: 'pointer' }}>
              Clear Search
            </div>
          </div>

          <div className={styles.debugLink} onClick={handleDebugOpen}>
            <RightClickPopup url="https://example.com/record">Debug</RightClickPopup></div>

          <div className={styles.container}>
            <div className={styles.middlePane}>
              <div className={styles.bottomMiddlePane}>
                <div className={styles.leftColumn}>
                  <div className={styles.filters} style={{ display: "none" }}>
                    <div className={styles.objetsIcono}>
                      <Filter filterName="filter_objects" />
                      <Filter filterName="filter_icono" />
                      <Filter filterName="filter_freeOfRights" />
                    </div>
                  </div>

                  <div className={styles.facets}>
                    <div className={styles.facetContainer}>
                      <div className={`${styles.facetName} ${cc.facets['department'] && cc.facets['department'].length > 0 ? styles.facetNameSelected : ''}`}>
                        <span>{t("facet_department")}</span>
                        {cc.facets['department'] && cc.facets['department'].length > 0 ?
                          <span className={styles.facetActiveDot}></span> : null}
                      </div>
                      <div className={styles.facet}>
                        <Facet facetName='department' contentHeight='160px' sort='alpha' />
                      </div>
                    </div>
                    <div className={styles.facetContainer}>
                      <div className={`${styles.facetName} ${cc.facets['title'] && cc.facets['title'].length > 0 ? styles.facetNameSelected : ''}`}>
                        <span>{t("facet_title")}</span>
                        {cc.facets['title'] && cc.facets['title'].length > 0 ?
                          <span className={styles.facetActiveDot}></span> : null}
                      </div>
                      <div className={styles.facet}>
                        <Facet facetName='title' contentHeight='160px' sort='alpha' />
                      </div>
                    </div>
                    <div className={styles.facetContainer}>
                      <div className={`${styles.facetName} ${cc.facets['personnes'] && cc.facets['personnes'].length > 0 ? styles.facetNameSelected : ''}`}>
                        <span>{t("facet_persons")}</span>
                        {cc.facets['personnes'] && cc.facets['personnes'].length > 0 ?
                          <span className={styles.facetActiveDot}></span> : null}
                      </div>
                      <div className={styles.facet}>
                        <Facet facetName='personnes' contentHeight='160px' sort='alpha' />
                      </div>
                    </div>
                    <div className={styles.facetContainer}>
                      <div className={`${styles.facetName} ${cc.facets['medium'] && cc.facets['medium'].length > 0 ? styles.facetNameSelected : ''}`}>
                        <span>{t("facet_medium")}</span>
                        {cc.facets['medium'] && cc.facets['medium'].length > 0 ?
                          <span className={styles.facetActiveDot}></span> : null}
                      </div>
                      <div className={styles.facet}>
                        <Facet facetName='medium' contentHeight='160px' sort='alpha' />
                      </div>
                    </div>
                    <div className={styles.facetContainer}>
                      <div className={`${styles.facetName} ${cc.facets['datedperiod'] && cc.facets['datedperiod'].length > 0 ? styles.facetNameSelected : ''}`}>
                        <span>{t("facet_datedperiod")}</span>
                        {cc.facets['datedperiod'] && cc.facets['datedperiod'].length > 0 ?
                          <span className={styles.facetActiveDot}></span> : null}
                      </div>
                      <div className={styles.facet}>
                        <Facet facetName='datedperiod' contentHeight='160px' sort='alpha' />
                      </div>
                    </div>
                    <div className={styles.facetContainer}>
                      <div className={`${styles.facetName} ${cc.facets['objectname'] && cc.facets['objectname'].length > 0 ? styles.facetNameSelected : ''}`}>
                        <span>{t("facet_objectname")}</span>
                        {cc.facets['objectname'] && cc.facets['objectname'].length > 0 ?
                          <span className={styles.facetActiveDot}></span> : null}
                      </div>
                      <div className={styles.facet}>
                        <Facet facetName='objectname' contentHeight='160px' sort='alpha' />
                      </div>
                    </div>
                    {(ccUtils.generateQuery(cc).indexOf('thème: Tapisseries') > 0 || ccUtils.generateQuery(cc).indexOf('theme: Tapisseries') > 0) && (<div className={styles.facetContainer}>
                      <div className={`${styles.facetName} ${cc.facets['collection'] && cc.facets['collection'].length > 0 ? styles.facetNameSelected : ''}`}>
                        <span>{t("facet_collection")}</span>
                        {cc.facets['collection'] && cc.facets['collection'].length > 0 ?
                          <span className={styles.facetActiveDot}></span> : null}
                      </div>
                      <div className={styles.facet}>
                        <Facet facetName='collection' contentHeight='160px' sort='alpha' />
                      </div>
                    </div>
                    )}
                    {false && <>
                      <div className={styles.facetContainer}>
                        <div className={`${styles.facetName} ${cc.hierarchicalFacets['culture'] && cc.hierarchicalFacets['culture'].length > 0 ? styles.facetNameSelected : ''}`}>
                          <span>{t("facet_culture")}</span>
                          {cc.hierarchicalFacets['culture'] && cc.hierarchicalFacets['culture'].length > 0 ?
                            <span className={styles.facetActiveDot}></span> : null}
                        </div>
                        <div className={styles.facet}>
                          <HierarchicalFacet hierarchicalFacetName='culture' contentHeight='160px' sort='alpha' showSelectedAbove={false} />
                        </div>
                      </div>
                      <div className={styles.facetContainer}>
                        <div className={`${styles.facetName} ${cc.hierarchicalFacets['toponyme'] && cc.hierarchicalFacets['toponyme'].length > 0 ? styles.facetNameSelected : ''}`}>
                          <span>{t("facet_geography")}</span>
                          {cc.hierarchicalFacets['toponyme'] && cc.hierarchicalFacets['toponyme'].length > 0 ?
                            <span className={styles.facetActiveDot}></span> : null}
                        </div>
                        <div className={styles.facet}>
                          <HierarchicalFacet hierarchicalFacetName='toponyme' contentHeight='160px' sort='alpha' showSelectedAbove={false} />
                        </div>
                      </div>
                    </>
                    }
                  </div>
                </div>

                {viewMode !== 'homepage' ? (
                  <div className={styles.homePageButton}>
                    <GenericIconButton
                      icon='light/house.svg'
                      hoverEnabled={true}
                      width='28px'
                      height='28px'
                      onClick={(e: any) => {
                        e.preventDefault();
                        //const newState = { "Searches": [{ "identifier": "simpleSearch", "field": "All search fields" }], "matchAllCriteria": false };
                        //const newStateCc = ccUtils.convertStateToCC(newState);
                        //setCc(newStateCc);
                        setViewMode('homepage');
                      }}
                    />
                  </div>
                ) : (null)
                }
                <div className={styles.rightColumn}>
                  <div className={styles.searchContainer}>
                    <div className={styles.simpleSearchBox}>
                      <SimpleSearchBox Fields="simple" id="simpleSearch" showSearchButton={true} />
                    </div>
                  </div>

                  {viewMode !== 'homepage' ?
                    <div>
                      {viewMode !== "map" &&
                        <div className={styles.displayControl}>
                          <ViewMode />
                          <div className={styles.displayControlSeperator} />
                          <div className={styles.displayControlItem}>
                            <BrowseMode />
                          </div>
                          {browseMode !== 'scroll' &&
                            (<>
                              <div className={styles.displayControlSeperator} />
                              <div className={styles.displayControlItem}>
                                <Navigator />
                              </div>
                            </>
                            )
                          }
                          <Sort />
                        </div>
                      }
                      {viewMode == "map" && (<div style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <ViewMode />
                        <div className={styles.hyperlinkBackToCollection} style={{ "display": "none" }} onClick={(e) => { setViewMode('list') }}>{t("map_return_to_list")}</div>
                      </div>
                      )
                      }
                      <QuerySummary />

                      {viewMode == "map" && (
                        <div className={styles.map}>
                          <Map dzi="https://collections.quaibranly.fr/pod23/cc/imageproxy.ashx?server=localhost&port=15012&filename=images/MQB_CC3V2_02_21031210_PH.dzi" />
                        </div>
                      )
                      }

                      {
                        viewMode !== "map" ? (
                          <>
                            <ResultSet />
                            {browseMode !== 'scroll' && (
                              <div className={styles.navigatorBottom}>
                                <div className={styles.displayControlSeperator} />
                                <div className={styles.displayControlItem}>
                                  <Navigator />
                                </div>
                              </div>
                            )}
                          </>
                        ) : null
                      }

                    </div>
                    :
                    <div>
                      <div className={styles.intro}>
                        <div className={styles.introText}>
                          {imageUrl && <AnnotatedImageViewer imageName={imageUrl} />}
                          {i18n.language === 'en' ? <div className={styles.introTextText} dangerouslySetInnerHTML={{ __html: introTextEnglish }} /> : i18n.language === 'fr' ? <div className={styles.introTextText} dangerouslySetInnerHTML={{ __html: introTextFrench }} /> : ''}
                        </div>
                        <div className={styles.introHyperlink} onClick={(e) => { setViewMode('list') }}>{t("homepage_click_to_view")}</div>
                      </div>
                      {false && (
                        <div>
                          <AlbumViewer height={400} albumId="56bd8fd2-2592-4a57-936e-491022607b41" />
                          <div style={{ height: '400px', overflow: 'auto' }}>
                            <EmbedArticle ccContext={ctx} articleId="160d5d4e-7c5a-4b84-b81e-47b577b58395" />
                          </div>
                        </div>
                      )}
                      <div className={styles.homepageBlocks}>
                        <HomepageBlock
                          onClick={(e) => {
                            e.preventDefault();
                            ccUtils.clearFacet(ctx, 'department');
                            if (i18n.language === 'fr') {
                              ccUtils.addCustomSearch(ctx, '/record/theme', 'Thème', ['[thème: Tapisseries]'], '');
                            } else {
                              ccUtils.addCustomSearch(ctx, '/record/themeeng', 'Theme', ['[theme: Tapisseries]'], '');
                            }

                            setViewMode('list');
                          }}

                          imageUrl={"https://collections.monuments-nationaux.fr/ccImageProxy.ashx?filename=images/033/images%20pleine%20page/CMN_dbw16_0865.jpg&width=350&_height=220&borderwidth=0&borderheight=0&bordercolor=e8e8e8&bg=f8f8f8&passepartoutwidth=0&passepartoutheight=0&passepartoutcolor=f8f8f8&cache=yes"}
                          text={t("homepageblock_tapisseries")}
                        />
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          {debugOpen && <DebugCC onClose={handleDebugClose} />}
          {
            showScrollToTop &&
            <div className={styles.scrollToTop} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
              <GenericIconButton icon='regular/chevron-up.svg' width='24px' height='24px' />
            </div>
          }
          <Footer />
        </div>
      )}
    </I18nextProvider>
  );
}

function App() {
  useEffect(() => {
    console.log('initialize app');
  }, []);

  return (
    <Router>
      <CCProvider>
        <MainContent />
      </CCProvider>
    </Router>
  );
}

export default App;
