import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import genericStyles from '../GenericComponents/GenericStyles.module.css';
import styles from './Sort.module.css';
import { CCProvider, useCCContext } from '../CC/CCContext';
import { CC, ccConfig } from '../CC/CC';

function Sort() {
  const { t } = useTranslation();
  const { cc, setCc, sort, setSort, first, setFirst } = useCCContext();
  
  const updateSort = (event: ChangeEvent<HTMLSelectElement>) => {
    setSort(event.target.value);
    setCc({
      ...cc
    });
    setFirst(1); // Reset first
  };
  return (
    <div className={styles.Sort}>
      <div className={styles.label}>{t("sort_sort_results")}&nbsp;</div>
      <select className={genericStyles.genericSelect} value={sort} onChange={updateSort}>
      {Object.entries(ccConfig.sort).map(([key, value]) => (
            <option key={key} value={key}>{t(value.label)}</option>
        ))}
      </select>
    </div>
  );
}

export default Sort;
