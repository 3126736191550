import React, { useState, useEffect } from 'react';
import JSXParser from 'react-jsx-parser';
import { createRoot } from 'react-dom/client';
import styles from './EmbedArticle.module.css';
import EmbedCount from './EmbedCount';
import EmbedFacet from './EmbedFacet';
import { ICCContext, CCProvider, CCContext, useCCContext } from '../CC/CCContext';

interface EmbedArticleProps {
  articleId: string;
  ccContext: ICCContext
}

const EmbedArticle: React.FC<EmbedArticleProps> = ({ articleId, ccContext}) => {
  const { data } = useCCContext();
  const [articleText, setArticleText] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  
  interface ParsedPlaceholder {
    componentName: string;
    attributes: { [key: string]: string };
}

interface Token {
    type: 'text' | 'placeholder';
    value: string;
}

function tokenizeContent(content: string): Token[] {
    const tokens: Token[] = [];
    let isCapturingPlaceholder = false;
    let buffer = '';
  
    let i = 0;
    while (i < content.length) {
        if (content.substring(i, i+2) === '{{') {
            if (buffer) {
                tokens.push({ type: 'text', value: buffer });
                buffer = '';
            }
            isCapturingPlaceholder = true;
            i += 2;  // Skip next chars
        } else if (content.substring(i, i+2) === '}}') {
            if (isCapturingPlaceholder) {
                tokens.push({ type: 'placeholder', value: buffer });
                buffer = '';
                isCapturingPlaceholder = false;
            }
            i += 2;  // Skip next chars
        } else {
            buffer += content[i];
            i++;
        }
    }
  
    if (buffer) {
        tokens.push({ type: isCapturingPlaceholder ? 'placeholder' : 'text', value: buffer });
    }
  
    return tokens;
}

function parsePlaceholder(placeholder: string): ParsedPlaceholder | null {
    let state: 'COMPONENT_NAME' | 'KEY' | 'VALUE_START' | 'VALUE' | 'VALUE_UNQUOTED' | 'JSON_VALUE' = 'COMPONENT_NAME';
    let buffer = '';
    let attr_key = '';
    let attr_value = '';
    let component_name = '';
    const attributes: { [key: string]: string } = {};
    let quote_char: string | null = null;
    let curly_brace_count = 0;  // Used to track nested JSON-like structures

    for (let i = 0; i < placeholder.length; i++) {
        const char = placeholder[i];

        if (state === 'COMPONENT_NAME') {
            if (char === ' ' || i === placeholder.length - 1) {
                component_name = (buffer + char).trim();
                buffer = '';
                state = 'KEY';
            } else {
                buffer += char;
            }
        } else if (state === 'KEY') {
            if (char === '=') {
                attr_key = buffer.trim();
                buffer = '';
                state = 'VALUE_START';
            } else {
                buffer += char;
            }
        } else if (state === 'VALUE_START') {
            if (char === '"' || char === "'") {
                quote_char = char;
                state = 'VALUE';
            } else if (char === '{') {
                buffer += char;
                curly_brace_count++;
                state = 'JSON_VALUE';
            } else {
                buffer += char;
                state = 'VALUE_UNQUOTED';
            }
        } else if (state === 'VALUE') {
            if (char === quote_char) {
                attr_value = buffer;
                attributes[attr_key] = attr_value;
                buffer = '';
                state = 'KEY';
            } else {
                buffer += char;
            }
        } else if (state === 'VALUE_UNQUOTED') {
            if (char === ' ' || i === placeholder.length - 1) {
                attr_value = buffer;
                attributes[attr_key] = attr_value;
                buffer = '';
                state = 'KEY';
            } else {
                buffer += char;
            }
        } else if (state === 'JSON_VALUE') {
            buffer += char;
            if (char === '{') {
                curly_brace_count++;
            } else if (char === '}') {
                curly_brace_count--;
                if (curly_brace_count === 0) {
                    attr_value = buffer;
                    attributes[attr_key] = attr_value;
                    buffer = '';
                    state = 'KEY';
                }
            }
        }
    }

    if (!component_name) {
        return null;
    }

    return {
        componentName: component_name,
        attributes: attributes
    };
}

function renderArticleHTML(htmlContent: string): JSX.Element {
  const tokens = tokenizeContent(htmlContent);

  // Create a map for placeholders to components
  const componentPlaceholders: { [key: string]: JSX.Element } = {};

  // Generate the modified HTML content
  const modifiedHtml = tokens.map((token, index) => {
    if (token.type === 'text') {
      return token.value;
    } else {
      const parsed = parsePlaceholder(token.value);
      if (!parsed) {
        return token.value;
      } else {
        const placeholder = `@@REACT_COMPONENT_${index}@@`;

        switch (parsed.componentName) {
          case "facet":
            componentPlaceholders[placeholder] = <EmbedFacet key={index} {...parsed.attributes} />;
            break;
          case "count":
            componentPlaceholders[placeholder] = <EmbedCount key={index} {...parsed.attributes} ccContext={ccContext} />;
            break;
          default:
            return token.value;
        }

        return `<span data-placeholder="${placeholder}"></span>`;
      }
    }
  }).join('');

  return (
    <div
      dangerouslySetInnerHTML={{ __html: modifiedHtml }}
      ref={(div) => {
        // After rendering, replace placeholders with actual components
        if (div) {
          Object.entries(componentPlaceholders).forEach(([placeholder, component]) => {
            const placeholderElement = div.querySelector(`span[data-placeholder="${placeholder}"]`);
            if (placeholderElement) {
              //ReactDOM.render(component, placeholderElement);
              const root = createRoot(placeholderElement);
              root.render(component);            }
          });
        }
      }}
    />
  );
}
  
  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_USERMANAGEMENT_URL}?action=getpublicalbum&albumId=${articleId}` // assuming `data.token` is the user token you referred to
        );

        if (response.ok) {
          const downloadedArticle = await response.json();
          setArticleText(downloadedArticle.albumText); // assuming the response structure is similar
        } else {
          console.error("Failed to fetch article details.");
        }
      } catch (error) {
        console.error("Error fetching article:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [articleId, data.token]);

  function decodeHtml(html: string) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  /*
  let htmlString="";
  if(articleText) {
    htmlString = decodeHtml(ReactDOMServer.renderToString(renderArticleHTML(articleText)));
  }
  */

  return (
    <div className={styles.EmbedArticle}>
      {loading ? "Loading..." : articleText ? renderArticleHTML(articleText) : "Album not found."}
    </div>
  );
  
}

export default EmbedArticle;
