import React, { useState, useRef, useEffect } from 'react';
import { useCCContext } from '../CC/CCContext';
import styles from './User.module.css';
import GenericIconButton from '../GenericComponents/GenericIconButton';
import genericStyles from '../GenericComponents/GenericStyles.module.css';
import AlbumEditor from '../AlbumEditor/AlbumEditor';

function User() {
    const { user, setUser } = useCCContext();
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showAlbumEditorModal, setShowAlbumEditorModal] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const menuButtonRef = useRef<HTMLButtonElement | null>(null);
    const [showMenuDropdown, setShowMenuDropdown] = useState(false);
    const [message, setMessage] = useState<string | null>(null);
    const [messageVisible, setMessageVisible] = useState(false); // <-- New state for message visibility
    const warningTimerRef = useRef<number | null>(null);
    const logoutTimerRef = useRef<number | null>(null);

    // Clear timers when component unmounts
    useEffect(() => {
        return () => {
            if (warningTimerRef.current) {
                clearTimeout(warningTimerRef.current);
            }
            if (logoutTimerRef.current) {
                clearTimeout(logoutTimerRef.current);
            }
        };
    }, []);

    const handleLogin = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        const response = await fetch(`${process.env.REACT_APP_USERMANAGEMENT_URL}?action=login&email=${email}&password=${password}`);
        const data = await response.json();
        if (data) {
            setUser(data);
            setShowLoginModal(false);
        } else {
            // Handle login error (e.g., show a message to the user)
            alert("Error logging in: " + JSON.stringify(data));
        }
    };

    // Update timers when tokenExpires changes
    useEffect(() => {
        if (user?.tokenExpires) {
            const expirationTime = new Date(user.tokenExpires).getTime();
            const currentTime = new Date().getTime();
            const timeRemaining = expirationTime - currentTime;

            // Clear existing timers
            if (warningTimerRef.current) {
                clearTimeout(warningTimerRef.current);
            }
            if (logoutTimerRef.current) {
                clearTimeout(logoutTimerRef.current);
            }

            // Set a new warning timer
            warningTimerRef.current = setTimeout(() => {
                setMessage('You will be logged out in 1 minute');
                setMessageVisible(true);

                // Clear the message after 3 seconds
                setTimeout(() => {
                    setMessageVisible(false);
                }, 3000);

            }, timeRemaining - 60000) as unknown as number;  // 60000 milliseconds = 1 minute

            // Set a new logout timer
            logoutTimerRef.current = setTimeout(() => {
                setUser(null);
                setShowMenuDropdown(false);
                setMessage('You have been logged out');
                setMessageVisible(true);

                // Clear the message after 3 seconds
                setTimeout(() => {
                    setMessageVisible(false);
                }, 3000);
            }, timeRemaining) as unknown as number;
        }
    }, [user?.tokenExpires]);

    const renderUserMenu = () => {
        let x = 0;
        let y = 0;

        if (menuButtonRef.current) {
            const rect = menuButtonRef.current.getBoundingClientRect();

            const parentModal = menuButtonRef.current.parentElement;
            if (parentModal) {
                const modalRect = parentModal.getBoundingClientRect();
                x = rect.left - modalRect.left - 108;
                y = rect.bottom + 2 - modalRect.top + 12;
            }
        }

        const menuItems = [
            {
                label: 'edit', onClick: () => {
                    console.log('Edit clicked');
                    setShowMenuDropdown(false);
                }
            },

            {
                label: 'album editor', onClick: () => {
                    setShowAlbumEditorModal(true);
                    setShowMenuDropdown(false);
                }
            },

            {
                label: 'logout', onClick: () => {
                    setUser(null);
                    setShowMenuDropdown(false);
                }
            }
        ];

        if (user?.userDetails?.roles?.includes('admin')) {
            menuItems.splice(1, 0, {
                label: 'manage users', onClick: () => {
                    console.log('Manage Users clicked');
                    setShowMenuDropdown(false);
                }
            });
        }

        return (
            <div
                className={styles.userMenuResults}
                style={{ position: 'absolute', top: y + 'px', left: x + 'px' }}
            >
                {menuItems.map((item, index) => (
                    <div
                        key={index}
                        className={styles.userMenuItem}
                        onClick={item.onClick}
                    >
                        {item.label}
                    </div>
                ))}
            </div>
        );
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            // Check if the click is outside both the menu and the menu button
            if (
                menuButtonRef.current &&
                !menuButtonRef.current.contains(event.target as Node) &&
                !document.querySelector(`.${styles.userMenuResults}`)?.contains(event.target as Node)
            ) {
                setShowMenuDropdown(false);
            }
        };

        // Add the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <div className={styles.User}>
            {user?.userDetails?.realname ? (
                <div className={styles.userdata}>
                    <div className={styles.realname}>{user.userDetails?.realname}</div>
                    <button className={styles.menubutton} ref={menuButtonRef} onClick={() => setShowMenuDropdown(!showMenuDropdown)}>
                        <GenericIconButton
                            icon='regular/chevron-double-down.svg'
                            hoverEnabled={false}
                            width='8px'
                            height='8px'
                        />
                    </button>
                    {showMenuDropdown && renderUserMenu()}
                </div>
            ) : (
                <>
                    <a href="#" onClick={() => setShowLoginModal(true)}>Login</a>
                    {showLoginModal && (
                        <div className={styles.popupoverlay}>
                            <div className={styles.popup}>
                                <div className={styles.header}>
                                    <span>Login</span>
                                    <button onClick={() => setShowLoginModal(false)}>X</button>
                                </div>
                                <div className={styles.main}>
                                    <div className={styles.row}>
                                        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                    </div>
                                    <div className={styles.row}>
                                        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                                    </div>
                                </div>
                                <div className={styles.footer}>
                                    <button className={genericStyles.GenericButton} type="submit" onClick={handleLogin}>Login</button>
                                    <button className={genericStyles.GenericButton}>Create New Account</button>
                                    <button className={genericStyles.GenericButton} onClick={() => setShowLoginModal(false)}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    )}                  
                </>
            )}
            <div className={`${styles.message} ${messageVisible ? styles.fadeIn : styles.fadeOut}`}>
                {message}
            </div>

            {showAlbumEditorModal && (
                <div className={styles.albumEditor}>
                    <AlbumEditor onClose={()=> {setShowAlbumEditorModal(false)}}/>
                </div>
            )}
        </div>
    );
}

export default User;
