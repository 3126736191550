import React, { useState, useEffect } from 'react';
import styles from './Mobile.module.css';
import { useDrag } from '@use-gesture/react';
import Menu from './Menu';
import MobileSearch from './MobileSearch';
import Results from './Results';
import MobileRecord from './MobileRecord';
import MobileHomepage from './MobileHomepage';

const Mobile = () => {
    const [mainY, setMainY] = useState(-100);
    const [selectedRecord, setSelectedRecord] = useState<any | null>(null);
    const [prevMainY, setPrevMainY] = useState(-100);
    const [isMenuOpen, setMenuOpen] = useState(false);

    const handleHamburgerClick = () => {
        if (mainY == -300) {
            setMainY(0);
        } else {
            setMainY(mainY - 100);
        }
    }

    const setAndKeepMainY = (newMainY: number, prevMainY: number) => {
        setMainY(newMainY);
        setPrevMainY(prevMainY);
    }

    const scrollBack = () => {
        setMainY(prevMainY);
    }

    const bind = useDrag(({ swipe: [swipeX, swipeY] }) => {
        console.log(swipeX, swipeY);
        if (swipeY === 1 && mainY < 0) { // Swipe down
            setMainY(mainY + 100);
        } else if (swipeY === -1 && mainY > -300) { // Swipe up
            setMainY(mainY - 100);
        }
    });

    useEffect(() => {
        console.log(selectedRecord);
    }, [selectedRecord]);

    return (
        <div className={styles.Mobile} {...bind()}>
            <div className={styles.MobileContents}>

                {/*
                <button className={styles.hamburger} onClick={onMenuClick}>
                    {isMenuOpen ? 'X' : '☰'}
                </button>
                 */}

                <div className={styles.menu} style={{ transform: `translateY(${mainY}vh)` }}>
                    <Menu mainY={mainY} setAndKeepMainY={setAndKeepMainY} scrollBack={scrollBack} ></Menu>
                </div>

                <div className={styles.menu} style={{ transform: `translateY(${mainY}vh)` }}>
                    <MobileHomepage mainY={mainY} setAndKeepMainY={setAndKeepMainY} ></MobileHomepage>
                </div>

                <div className={styles.search} style={{ transform: `translateY(${mainY}vh)` }}>
                    <MobileSearch mainY={mainY} setAndKeepMainY={setAndKeepMainY} selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord}></MobileSearch>
                </div>

                {/*
                    <div className={styles.results} style={{ transform: `translateY(${mainY}vh)` }}>
                    <Results mainY={mainY} setMainY={setMainY}></Results>
                    </div>
                */}

                <div className={styles.record} style={{ transform: `translateY(${mainY}vh)` }}>
                    {
                        selectedRecord?.metadata?.recordnumber ? (
                            <MobileRecord mainY={mainY} setAndKeepMainY={setAndKeepMainY} curPage={selectedRecord.metadata.recordnumber} selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord}></MobileRecord>
                        ) :
                            ("XXX")
                    }
                </div>


                <div className={styles.menu} style={{ transform: `translateY(${mainY}vh)` }}>
                    <Menu mainY={mainY} setAndKeepMainY={setAndKeepMainY} scrollBack={scrollBack} ></Menu>
                </div>


            </div>

        </div>
    )
};

export default Mobile;
