import React from 'react';
import { CCProvider, useCCContext } from '../CC/CCContext';

function Count() {
  const { data } = useCCContext();

  const count = data?.request?.count ?? 0;

  return (
    <>
      {count}
    </>
  );
}

export default Count;
