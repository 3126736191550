import React from 'react';
import styles from './GenericIcon.module.css';

// Import the SVG icons as React components
import { ReactComponent as SortAlphaDown } from '../fontawesome/svgs/regular/sort-alpha-down.svg';
import { ReactComponent as SortAmountDown } from '../fontawesome/svgs/regular/sort-amount-down.svg';
import { ReactComponent as CheckSquareIcon } from '../fontawesome/svgs/regular/check.svg';
import { ReactComponent as BookOpen } from '../fontawesome/svgs/light/book-open.svg';
import { ReactComponent as Scroll } from '../fontawesome/svgs/light/scroll.svg';
import { ReactComponent as First } from '../fontawesome/svgs/light/angle-double-left.svg';
import { ReactComponent as Prev } from '../fontawesome/svgs/light/angle-left.svg';
import { ReactComponent as Next } from '../fontawesome/svgs/light/angle-right.svg';
import { ReactComponent as Last } from '../fontawesome/svgs/light/angle-double-right.svg';
import { ReactComponent as List } from '../fontawesome/svgs/light/list.svg';
import { ReactComponent as TH } from '../fontawesome/svgs/light/th.svg';
import { ReactComponent as Times } from '../fontawesome/svgs/light/times.svg';
import { ReactComponent as DoubleDown } from '../fontawesome/svgs/regular/chevron-double-down.svg';
import { ReactComponent as Plus } from '../fontawesome/svgs/regular/plus.svg';
import { ReactComponent as Minus } from '../fontawesome/svgs/regular/minus.svg';
import { ReactComponent as MapMarkedAlt } from '../fontawesome/svgs/light/map-marked-alt.svg';
import { ReactComponent as Link } from '../fontawesome/svgs/light/link.svg';
import { ReactComponent as Copy } from '../fontawesome/svgs/light/copy.svg';
import { ReactComponent as Copyright } from '../fontawesome/svgs/light/copyright.svg';
import { ReactComponent as Home } from '../fontawesome/svgs/light/home.svg';
import { ReactComponent as Landmark } from '../fontawesome/svgs/light/landmark.svg';
import { ReactComponent as ArrowFromRight } from '../fontawesome/svgs/duotone/arrow-from-right.svg';
import { ReactComponent as Filter } from '../fontawesome/svgs/light/filter.svg';
import { ReactComponent as Bars } from '../fontawesome/svgs/regular/bars.svg';
import { ReactComponent as House } from '../fontawesome/svgs/light/house.svg';
import { ReactComponent as Search } from '../fontawesome/svgs/regular/search.svg';
import { ReactComponent as BoldIcon } from '../fontawesome/svgs/regular/bold.svg';
import { ReactComponent as ItalicIcon } from '../fontawesome/svgs/regular/italic.svg';
import { ReactComponent as UnderlineIcon } from '../fontawesome/svgs/regular/underline.svg';
import { ReactComponent as AlignLeftIcon } from '../fontawesome/svgs/regular/align-left.svg';
import { ReactComponent as AlignCenterIcon } from '../fontawesome/svgs/regular/align-center.svg';
import { ReactComponent as AlignRightIcon } from '../fontawesome/svgs/regular/align-right.svg';
import { ReactComponent as ListUlIcon } from '../fontawesome/svgs/regular/list-ul.svg';
import { ReactComponent as ListOlIcon } from '../fontawesome/svgs/regular/list-ol.svg';
import { ReactComponent as StrikethroughIcon } from '../fontawesome/svgs/regular/strikethrough.svg';
import { ReactComponent as CodeIcon } from '../fontawesome/svgs/regular/code.svg';
import { ReactComponent as QuoteRightIcon } from '../fontawesome/svgs/regular/quote-right.svg';
import { ReactComponent as TableIcon } from '../fontawesome/svgs/regular/table.svg';
import { ReactComponent as Palette } from '../fontawesome/svgs/regular/palette.svg';
import { ReactComponent as ChevronUp } from '../fontawesome/svgs/regular/chevron-up.svg';
import { ReactComponent as ArrowUp } from '../fontawesome/svgs/light/arrow-up.svg';
import { ReactComponent as Pdf } from '../fontawesome/svgs/light/file-pdf.svg';
import { ReactComponent as Info } from '../fontawesome/svgs/solid/info.svg';
import { ReactComponent as Expand } from '../fontawesome/svgs/solid/expand-alt.svg';
import { ReactComponent as Compress } from '../fontawesome/svgs/solid/compress-alt.svg';
import { ReactComponent as Share } from '../fontawesome/svgs/light/share.svg';

// Create a mapping between icon names and their respective React components
const iconMap: Record<string, any> = {
  'regular/sort-alpha-down.svg': SortAlphaDown,
  'regular/sort-amount-down.svg': SortAmountDown,
  'regular/check.svg': CheckSquareIcon,
  'light/book-open.svg': BookOpen,
  'light/scroll.svg': Scroll,
  'light/angle-double-left.svg': First,
  'light/angle-left.svg': Prev,
  'light/angle-right.svg': Next,
  'light/angle-double-right.svg': Last,
  'light/list.svg': List,
  'light/th.svg': TH,
  'light/times.svg': Times,
  'regular/chevron-double-down.svg': DoubleDown,
  'regular/plus.svg': Plus,
  'regular/minus.svg': Minus,
  'light/map-marked-alt.svg': MapMarkedAlt,
  'light/link.svg': Link,
  'light/copy.svg': Copy,
  'light/copyright.svg': Copyright,
  'light/home.svg': Home,
  'light/landmark.svg': Landmark,
  'duotone/arrow-from-right.svg': ArrowFromRight,
  'light/filter.svg': Filter,
  'regular/bars.svg': Bars,
  'light/house.svg': House,
  'regular/search.svg': Search,
  'regular/bold.svg': BoldIcon,
  'regular/italic.svg': ItalicIcon,
  'regular/underline.svg': UnderlineIcon,
  'regular/align-left.svg': AlignLeftIcon,
  'regular/align-center.svg': AlignCenterIcon,
  'regular/align-right.svg': AlignRightIcon,
  'regular/list-ul.svg': ListUlIcon,
  'regular/list-ol.svg': ListOlIcon,
  'regular/strikethrough.svg': StrikethroughIcon,
  'regular/code.svg': CodeIcon,
  'regular/quote-right.svg': QuoteRightIcon,
  'regular/table.svg': TableIcon,
  'regular/palette.svg': Palette,
  'regular/chevron-up.svg': ChevronUp,
  'regular/arrow-up.svg': ArrowUp,
  'regular/file-pdf.svg': Pdf,
  'solid/info.svg': Info,
  'solid/expand-alt.svg': Expand,
  'solid/compress-alt.svg': Compress,
  'light/share.svg': Share,
};

interface GenericIconProps {
  icon: string;
  className?: string;
}

const GenericIcon: React.FC<GenericIconProps> = ({ icon, className }) => {
  const IconComponent = iconMap[icon as any];

  if (!IconComponent) {
    console.error(`Icon not found: ${icon}`);
    return null;
  }

  return (
    <IconComponent className={`${styles.GenericIcon} ${className}`} />
  );
};

export default GenericIcon;