import React, { useState, useRef, useEffect, useCallback } from 'react';
import styles from './AnnotationControl.module.css';

const AnnotationControl = ({ annotations, setAnnotations, albumId, onFileSelect }) => {
    const [currentAnnotation, setCurrentAnnotation] = useState(null);
    const [selectedAnnotation, setSelectedAnnotation] = useState(null);
    const [selectedPoint, setSelectedPoint] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [imageName, setImageName] = useState(annotations.imageName || '');
    const canvasRef = useRef(null);
    const imageRef = useRef(null);
    const containerRef = useRef(null);
    const [localImageUrl, setLocalImageUrl] = useState(null);
    const fileInputRef = React.createRef();
    const [hoveredSegment, setHoveredSegment] = useState(null);
    const [hoveredPoint, setHoveredPoint] = useState(null);

    const drawAnnotation = (ctx, ann, scaleX, scaleY, isCurrent, isSelected) => {
        ctx.lineWidth = 2;
        ctx.setLineDash(isCurrent ? [5, 5] : []);

        ann.points.forEach((point, index) => {
            const nextPoint = ann.points[(index + 1) % ann.points.length];
            const isHoveredSegment = hoveredSegment &&
                hoveredSegment.annotationIndex === annotations.annotations.indexOf(ann) &&
                hoveredSegment.segmentIndex === index;

            ctx.beginPath();
            ctx.moveTo(point.x * scaleX, point.y * scaleY);
            ctx.lineTo(nextPoint.x * scaleX, nextPoint.y * scaleY);

            ctx.strokeStyle = isHoveredSegment ? 'yellow' :
                (isCurrent ? 'blue' : isSelected ? 'green' : 'red');
            ctx.stroke();

            // Draw points
            const isHoveredPoint = hoveredPoint &&
                hoveredPoint.annotationIndex === annotations.annotations.indexOf(ann) &&
                hoveredPoint.pointIndex === index;

            ctx.fillStyle = isHoveredPoint ? 'yellow' :
                (isCurrent ? 'blue' : isSelected ? 'green' : 'red');
            ctx.beginPath();
            ctx.arc(point.x * scaleX, point.y * scaleY, 6, 0, 2 * Math.PI);
            ctx.fill();
        });

        ctx.setLineDash([]);
    };

    const drawAnnotations = useCallback(() => {
        const canvas = canvasRef.current;
        const image = imageRef.current;
        if (!canvas || !image) return;

        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const scaleX = canvas.width / image.naturalWidth;
        const scaleY = canvas.height / image.naturalHeight;

        annotations.annotations.forEach((ann, index) => {
            const isSelected = selectedAnnotation === index;
            drawAnnotation(ctx, ann, scaleX, scaleY, false, isSelected);
        });

        if (currentAnnotation) {
            drawAnnotation(ctx, currentAnnotation, scaleX, scaleY, true, false);
        }
    }, [annotations.annotations, currentAnnotation, selectedAnnotation, hoveredSegment, hoveredPoint]);

    useEffect(() => {
        return () => {
            if (localImageUrl) {
                URL.revokeObjectURL(localImageUrl);
            }
        };
    }, [localImageUrl]);

    useEffect(() => {
        setImageName(annotations.imageName);
    }, [annotations]);

    useEffect(() => {
        const image = imageRef.current;
        const canvas = canvasRef.current;
        const container = containerRef.current;

        let rafId = null;

        const updateCanvasSize = () => {
            if (image && canvas && container) {
                canvas.width = image.clientWidth;
                canvas.height = image.clientHeight;
                canvas.style.width = `${image.clientWidth}px`;
                canvas.style.height = `${image.clientHeight}px`;
                drawAnnotations();
            }
        };

        const debouncedUpdateCanvasSize = () => {
            if (rafId) {
                cancelAnimationFrame(rafId);
            }
            rafId = requestAnimationFrame(updateCanvasSize);
        };

        const resizeObserver = new ResizeObserver(debouncedUpdateCanvasSize);

        if (container) {
            resizeObserver.observe(container);
        }

        return () => {
            if (container) {
                resizeObserver.unobserve(container);
            }
            if (rafId) {
                cancelAnimationFrame(rafId);
            }
        };
    }, [drawAnnotations]);

    useEffect(() => {
        drawAnnotations();
    }, [drawAnnotations]);

    const getMousePos = (canvas, evt) => {
        const rect = canvas.getBoundingClientRect();
        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;
        return {
            x: (evt.clientX - rect.left) * scaleX,
            y: (evt.clientY - rect.top) * scaleY
        };
    };

    const getOriginalPos = (canvas, x, y) => {
        const image = imageRef.current;
        const scaleX = image.naturalWidth / canvas.width;
        const scaleY = image.naturalHeight / canvas.height;
        return {
            x: x * scaleX,
            y: y * scaleY
        };
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            onFileSelect(file);
            const newImageName = file.name;
            setImageName(newImageName);
            const imageUrl = URL.createObjectURL(file);
            setLocalImageUrl(imageUrl);

            // Update annotations with the new image name and reset annotations
            setAnnotations(prevAnnotations => ({
                ...prevAnnotations,
                imageName: newImageName,
                annotations: [] // Reset annotations for the new image
            }));
        }
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
        const canvas = canvasRef.current;
        const { x, y } = getMousePos(canvas, e);
        const originalPos = getOriginalPos(canvas, x, y);
    
        if (annotationType === 'pointer') {
            if (hoveredPoint !== null) {
                // A point is being dragged
                setSelectedAnnotation(hoveredPoint.annotationIndex);
                setSelectedPoint(hoveredPoint.pointIndex);
                setIsDragging(true);
                setDragStart({ x: originalPos.x, y: originalPos.y });
            } else if (hoveredSegment !== null) {
                // A new point is being added to a line segment
                setAnnotations(prevAnnotations => {
                    const newAnnotations = [...prevAnnotations.annotations];
                    const ann = newAnnotations[hoveredSegment.annotationIndex];
                    const newPoints = [...ann.points];
                    newPoints.splice(hoveredSegment.segmentIndex + 1, 0, { x: originalPos.x, y: originalPos.y });
                    newAnnotations[hoveredSegment.annotationIndex] = { ...ann, points: newPoints };
                    return { ...prevAnnotations, annotations: newAnnotations };
                });
                setSelectedAnnotation(hoveredSegment.annotationIndex);
                setSelectedPoint(hoveredSegment.segmentIndex + 1);
                setIsDragging(true);
                setDragStart({ x: originalPos.x, y: originalPos.y });
            } else {
                // Check if clicking inside an annotation to move it
                let clickedAnnotationIndex = -1;
                for (let i = 0; i < annotations.annotations.length; i++) {
                    if (isPointInPolygon(originalPos.x, originalPos.y, annotations.annotations[i])) {
                        clickedAnnotationIndex = i;
                        break;
                    }
                }
    
                if (clickedAnnotationIndex !== -1) {
                    // An annotation is being moved
                    setSelectedAnnotation(clickedAnnotationIndex);
                    setSelectedPoint(null);
                    setIsDragging(true);
                    setDragStart({ x: originalPos.x, y: originalPos.y });
                } else {
                    // Clicked outside any annotation
                    setSelectedAnnotation(null);
                    setSelectedPoint(null);
                    setIsDragging(false);
                }
            }
        } else if (annotationType === 'rectangle') {
            setCurrentAnnotation({ type: 'polygon', points: [{ x: originalPos.x, y: originalPos.y }], text: '', imageName });
        } else if (annotationType === 'polygon') {
            if (!currentAnnotation) {
                setCurrentAnnotation({ type: 'polygon', points: [{ x: originalPos.x, y: originalPos.y }], text: '', imageName });
            } else {
                setCurrentAnnotation(prev => ({
                    ...prev,
                    points: [...prev.points, { x: originalPos.x, y: originalPos.y }]
                }));
            }
        }
    };

    const isPointOnPolygonLine = (x, y, polygon) => {
        const threshold = 25;
        for (let i = 0; i < polygon.points.length; i++) {
            const p1 = polygon.points[i];
            const p2 = polygon.points[(i + 1) % polygon.points.length];

            const d = distanceToLineSegment(x, y, p1.x, p1.y, p2.x, p2.y);
            if (d < threshold) {
                return { onLine: true, index: i };
            }
        }
        return { onLine: false };
    };

    const distanceToLineSegment = (x, y, x1, y1, x2, y2) => {
        const A = x - x1;
        const B = y - y1;
        const C = x2 - x1;
        const D = y2 - y1;

        const dot = A * C + B * D;
        const len_sq = C * C + D * D;
        let param = -1;
        if (len_sq !== 0) param = dot / len_sq;

        let xx, yy;

        if (param < 0) {
            xx = x1;
            yy = y1;
        } else if (param > 1) {
            xx = x2;
            yy = y2;
        } else {
            xx = x1 + param * C;
            yy = y1 + param * D;
        }

        const dx = x - xx;
        const dy = y - yy;
        return Math.sqrt(dx * dx + dy * dy);
    };

    const handleMouseMove = (e) => {
        e.preventDefault();
        const canvas = canvasRef.current;
        if (!canvas) return;

        const { x, y } = getMousePos(canvas, e);
        const originalPos = getOriginalPos(canvas, x, y);

        let foundHover = false;
        for (let i = 0; i < annotations.annotations.length; i++) {
            const ann = annotations.annotations[i];
            for (let j = 0; j < ann.points.length; j++) {
                const point = ann.points[j];
                const nextPoint = ann.points[(j + 1) % ann.points.length];

                // Check if hovering over a point
                if (Math.sqrt((point.x - originalPos.x) ** 2 + (point.y - originalPos.y) ** 2) < 25) {
                    setHoveredPoint({ annotationIndex: i, pointIndex: j });
                    setHoveredSegment(null);
                    foundHover = true;
                    break;
                }

            }
            if (foundHover) break;
        }
        if (!foundHover) {
            for (let i = 0; i < annotations.annotations.length; i++) {
                const ann = annotations.annotations[i];
                for (let j = 0; j < ann.points.length; j++) {
                    const point = ann.points[j];
                    const nextPoint = ann.points[(j + 1) % ann.points.length];

                    // Check if hovering over a line segment
                    if (distanceToLineSegment(originalPos.x, originalPos.y, point.x, point.y, nextPoint.x, nextPoint.y) < 25) {
                        setHoveredSegment({ annotationIndex: i, segmentIndex: j });
                        setHoveredPoint(null);
                        foundHover = true;
                        break;
                    }
                }
                if (foundHover) break;
            }
        }

        if (!foundHover) {
            setHoveredSegment(null);
            setHoveredPoint(null);
        }

        if (isDragging && selectedAnnotation !== null) {
            const dx = originalPos.x - dragStart.x;
            const dy = originalPos.y - dragStart.y;

            setAnnotations(prevAnnotations => {
                const newAnnotations = [...prevAnnotations.annotations];
                const annotation = newAnnotations[selectedAnnotation];

                if (selectedPoint !== null && selectedPoint < annotation.points.length) {
                    annotation.points[selectedPoint].x += dx;
                    annotation.points[selectedPoint].y += dy;
                } else {
                    annotation.points = annotation.points.map(point => ({
                        x: point.x + dx,
                        y: point.y + dy
                    }));
                }
                return { ...prevAnnotations, annotations: newAnnotations };
            });
            setDragStart({ x: originalPos.x, y: originalPos.y });
        } else if (currentAnnotation) {
            if (annotationType === 'rectangle') {
                const startPoint = currentAnnotation.points[0];
                setCurrentAnnotation({
                    ...currentAnnotation,
                    points: [
                        startPoint,
                        { x: originalPos.x, y: startPoint.y },
                        { x: originalPos.x, y: originalPos.y },
                        { x: startPoint.x, y: originalPos.y }
                    ]
                });
            } else if (annotationType === 'polygon') {
                setCurrentAnnotation(prev => ({
                    ...prev,
                    points: [...prev.points.slice(0, -1), { x: originalPos.x, y: originalPos.y }]
                }));
            }
        }
    };

    const handleMouseUp = (e) => {
        e.preventDefault();
        if (isDragging) {
            setIsDragging(false);
        } else if (annotationType === 'rectangle' && currentAnnotation) {
            setAnnotations(prev => {
                const newAnnotations = [...prev.annotations, currentAnnotation];
                setSelectedAnnotation(newAnnotations.length - 1);  // Select the newly added annotation
                setAnnotationType('pointer');
                return { ...prev, annotations: newAnnotations };
            });
            setCurrentAnnotation(null);
        }
    };

    const handleDoubleClick = () => {
        if (annotationType === 'polygon' && currentAnnotation && currentAnnotation.points.length > 2) {
            setAnnotations(prev => {
                const newAnnotations = [...prev.annotations, currentAnnotation];
                setSelectedAnnotation(newAnnotations.length - 1);  // Select the newly added annotation
                setAnnotationType('pointer');
                return { ...prev, annotations: newAnnotations };
            });
            setCurrentAnnotation(null);
        }
    };

    const isPointInPolygon = (x, y, polygon) => {
        let inside = false;
        for (let i = 0, j = polygon.points.length - 1; i < polygon.points.length; j = i++) {
            const xi = polygon.points[i].x, yi = polygon.points[i].y;
            const xj = polygon.points[j].x, yj = polygon.points[j].y;
            const intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }
        return inside;
    };

    const handleTextChange = (index, newText) => {
        setAnnotations(prevAnnotations => {
            const newAnnotations = [...prevAnnotations.annotations];
            newAnnotations[index].text = newText;
            return { ...prevAnnotations, annotations: newAnnotations };
        });
    };

    const handleDeleteAnnotation = () => {
        if (selectedAnnotation !== null) {
            setAnnotations(prevAnnotations => {
                const newAnnotations = prevAnnotations.annotations.filter((_, index) => index !== selectedAnnotation);
                return { ...prevAnnotations, annotations: newAnnotations };
            });
            setSelectedAnnotation(null);
        }
    };

    const handleFileButtonClick = () => {
        fileInputRef.current.click();
    };

    const [annotationType, setAnnotationType] = useState('pointer');

    return (
        <div className={styles.annotationControl}>
            <div className={styles.header}>
                <input
                    type="text"
                    placeholder="Image Name"
                    value={imageName}
                    onChange={(e) => {
                        setImageName(e.target.value);
                        setAnnotations(prev => ({ ...prev, imageName: e.target.value }));
                    }}
                    className={styles.imageName}
                />
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                />
                <button
                    onClick={handleFileButtonClick}
                    className={styles.uploadButton}
                >
                    Upload Image
                </button>
                <button
                    className={`${styles.toolButton} ${annotationType === 'pointer' ? styles.active : ''}`}
                    onClick={() => setAnnotationType('pointer')}
                >
                    Pointer
                </button>
                <button
                    className={`${styles.toolButton} ${annotationType === 'rectangle' ? styles.active : ''}`}
                    onClick={() => setAnnotationType('rectangle')}
                >
                    Rectangle
                </button>
                <button
                    className={`${styles.toolButton} ${annotationType === 'polygon' ? styles.active : ''}`}
                    onClick={() => setAnnotationType('polygon')}
                >
                    Polygon
                </button>
                <div className={styles.annotationText}>
                    <textarea
                        className={styles.annotationTextarea}
                        value={selectedAnnotation !== null ? annotations.annotations[selectedAnnotation].text : ''}
                        onChange={(e) => handleTextChange(selectedAnnotation, e.target.value)}
                        disabled={selectedAnnotation === null}
                    />
                    <button
                        className={styles.deleteButton}
                        onClick={handleDeleteAnnotation}
                        disabled={selectedAnnotation === null}
                    >
                        Delete Annotation
                    </button>
                </div>
            </div>
            <div className={styles.imageContainer} ref={containerRef}>
                <div className={styles.imageWrapper}>
                    <img
                        ref={imageRef}
                        src={localImageUrl || `${process.env.REACT_APP_USERMANAGEMENT_URL}?action=getAnnotatedImage&albumId=${albumId}`}
                        //src={"ccImageProxy.ashx?filename=images/Visuels%20site%20Collectio%2Fbgw19_1524.jpg&width=600&_height=160&borderwidth=0&borderheight=0&bordercolor=e8e8e8&bg=f8f8f8&passepartoutwidth=0&passepartoutheight=0&passepartoutcolor=f8f8f8&cache=yes"}
                        alt="Annotatable"
                        className={styles.image}
                    />
                    <canvas
                        ref={canvasRef}
                        className={styles.canvas}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        onDoubleClick={handleDoubleClick}
                    />
                </div>
            </div>
        </div>
    );
};

export default AnnotationControl;
