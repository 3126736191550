import React from 'react';
import Draggable from 'react-draggable';
import styles from './DraggablePopup.module.css';

interface DraggablePopupProps {
    title: string;
    onClose: () => void;
    children: React.ReactNode;
}

const DraggablePopup: React.FC<DraggablePopupProps> = ({ title, onClose, children }) => {
    return (
        <Draggable handle=".dragHandle">
            <div className={`${styles.popup} ${styles.resizable_popup}`}>
                <div className={`${styles.popupHeader} dragHandle`}>
                    <span>{title}</span>
                    <button onClick={onClose} className={styles.closeButton}>X</button>
                </div>
                <div className={`${styles.popupContent} ${styles.scrollable_content}`}>
                    {children}
                </div>
            </div>
        </Draggable>
    );
}

export default DraggablePopup;
