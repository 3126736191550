import React, { useEffect, useState, useCallback, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import styles from './MobileScrollResults.module.css';
import { useTranslation } from 'react-i18next';
import { CC, ccConfig } from '../CC/CC';
import { CCProvider, useCCContext } from '../CC/CCContext';
import ImageWithFallback from '../GenericComponents/ImageWithFallback';
import * as ccUtils from '../CC/utils';
import MobileRecordIconScroll from './MobileRecordIconScroll';
import RecordList from '../Record/RecordList';

type MobileScrollResults = {
  mainY: number;
  setAndKeepMainY: (newMainY: number, prevMainY: number) => void;
  selectedRecord: any | null;
  setSelectedRecord: React.Dispatch<React.SetStateAction<any | null>>;
};

const MobileScrollResults: React.FC<MobileScrollResults> = ({ mainY, setAndKeepMainY, selectedRecord, setSelectedRecord }) => {
  const { t } = useTranslation();
  const instanceRef = useRef({});
  const { cc, setCc, sort, setSort, first, setFirst, recordsPerPage, setRecordsPerPage, viewMode, setViewMode, browseMode, setBrowseMode, data, setData } = useCCContext();
  const ctx = useCCContext();
  const [localCc, setLocalCc] = useState(cc);
  const [localSort, setLocalSort] = useState(sort);
  const [renderCount, setRenderCount] = useState(0);
  const [items, setItems] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number | null>(null);

  const isLoading = useRef(false);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const nextStartIndex = useRef(1); // Reference to keep track of the next starting index
  const sentinelRef = useRef<HTMLDivElement | null>(null); // Ref for the sentinel element
  const localViewMode = useRef("icons");
  const forceUpdate = () => setRenderCount(renderCount + 1);

  useEffect(() => {
    setIsModalOpen(false);
  }, [ctx.cc, ctx.first]);

  const IconItem = ({ record, index }: { record: any, index: number }) => {
    const imageUrl = `https://collections.quaibranly.fr/cc/imageproxy.ashx?server=localhost&port=15012&filename=${record?.data?.Record?.Image?.Image ?? 'Objets\\Images\\nondisponible.cci'}&width=160&borderwidth=0&borderheight=0&bordercolor=e8e8e8&bg=ffffff&passepartoutwidth=0&passepartoutheight=0&passepartoutcolor=f8f8f8&cache=yes`;
    const title = record?.data?.Record?.Title ?? 'No title';
    const recordNumber = record?.metadata?.recordnumber;
    const invNo = record?.data?.Record?.ObjectNumber;

    return (
      <MobileRecordIconScroll
        key={`scroll_icons_${recordNumber}`}
        record={record}
        index={index}
        handleLinkClick={handleLinkClick}
      />
    );
  };

  const handleLinkClick = (event: React.MouseEvent, record: any) => {
    event.preventDefault(); // prevent default navigation on left-click
    selectRecord(record);
  };

  const ListItem = ({ record, index }: { record: any, index: number }) => {
    const recordNumber = record?.metadata?.recordnumber;
    const imageUrl = `https://collections.quaibranly.fr/cc/imageproxy.ashx?server=localhost&port=15012&filename=${record?.data?.Record?.Image?.Image ?? 'Objets\\Images\\nondisponible.cci'}&width=160&borderwidth=0&borderheight=0&bordercolor=e8e8e8&bg=ffffff&passepartoutwidth=0&passepartoutheight=0&passepartoutcolor=f8f8f8&cache=yes`;
    const invNo = record?.data?.Record?.ObjectNumber;

    return (
      <RecordList
        key={`scroll_list_${recordNumber}`}
        record={record}
        index={index}
        handleLinkClick={handleLinkClick}
      />
    );
  };

  const fetchData = useCallback(async () => {
    if (isLoading.current || items.length >= 5000 || (totalRecords !== null && items.length >= totalRecords)) return;
    isLoading.current = true;
    const startIndex = nextStartIndex.current;
    const endIndex = startIndex + 29;

    const postData = ccUtils.generatePayload(localCc, localSort, startIndex, endIndex);

    try {
      const data = await ccUtils.postWithCache(process.env.REACT_APP_BASE_URL as string, postData, instanceRef);

      if (!data.records || !data.records.record) {
        isLoading.current = false;
        return;
      }

      if (totalRecords === null) {
        setTotalRecords(data.request.count);
      }

      let records = data.records.record;
      records = Array.isArray(records) ? records : [records];

      setItems([...items, ...records]);
      nextStartIndex.current += 30;

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      isLoading.current = false;
    }
  }, [items]);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (observerRef.current) {
            observerRef.current.disconnect();
          }
          setTimeout(() => fetchData(), 0);
        }
      });
    });

    observerRef.current = observer;

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [fetchData]);

  useEffect(() => {
    console.log('cc from mobile', cc);
    nextStartIndex.current = 1;
    setItems([]);
    setLocalCc(cc);
    setTotalRecords(null);
    isLoading.current = false;
  }, [cc]);

  useEffect(() => {
    nextStartIndex.current = 1;
    setItems([]);
    setLocalSort(sort);
    setTotalRecords(null);
    isLoading.current = false;
  }, [sort]);

  useEffect(() => {
    nextStartIndex.current = 1;
    setItems([]);
    setTotalRecords(null);
  }, [viewMode]);

  const selectRecord = (record: any) => {
    setSelectedRecord(record)
    setAndKeepMainY(-300, -200);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Add a condition to check if data.records.record is defined
  if (!data.records || !data.records.record) {
    return <div>{t("no_records_found")}</div>; // Or your custom no records component
  }

  return (
    <div className={styles.masonryContainer}>
      <div className={styles.rowContainer}>
        {items.map((record: any, index: number) => (
          localViewMode.current === 'icons' ? (
            <IconItem record={record} index={index} key={`scroll_parent_icon${index}`} />
          ) : (
            <ListItem record={record} index={index} key={`scroll_parent_list${index}`} />
          )
        ))}
      </div>

      {items.length < 500 &&
        <div ref={sentinelRef}></div> // Sentinel element
      }

      {
        items.length >= 500 && !isLoading.current && (totalRecords === null || items.length < totalRecords) ? (
          <div className={styles.loadMore} onClick={fetchData}>
            {t("scroll_load_more")}
          </div>
        ) : (
          ((totalRecords !== null && items.length >= totalRecords)) && (
            <p style={{ textAlign: "center" }}>
              <b>{t("scroll_no_more")}</b>
            </p>
          )
        )
      }

      {isModalOpen && (
        <>
          {/*<RecordPopup recordNumber={selectedRecord} onClose={closeModal} />*/}
        </>
      )}

    </div>
  );
}

export default MobileScrollResults;
