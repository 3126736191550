import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './MobileRecordIconScroll.module.css';
import ImageWithFallback from '../GenericComponents/ImageWithFallback';

interface MobileRecordIconScrollProps {
    record: any;
    index: number;
    handleLinkClick: (event: React.MouseEvent, record: any) => void;
}

const MobileRecordIconScroll: React.FC<MobileRecordIconScrollProps> = React.memo(({ record, index, handleLinkClick }) => {
    const { t } = useTranslation();
    const [isHovered, setIsHovered] = useState(false);

    let filename = "";
    if (record?.data?.record?.image) {
        if (Array.isArray(record?.data?.record?.image)) {
            if (record?.data?.record?.image.length > 0) {
                const imageWithLowestRank = record.data.record.image.reduce((prev: any, curr: any) => {
                    return parseInt(prev.rank) < parseInt(curr.rank) ? prev : curr;
                });
                filename = imageWithLowestRank.image;
            }
        } else {
            filename = record.data.record.image.image;
        }
    }

    const imageUrl = filename ?
        `ccImageProxy.ashx?filename=images/${filename}&width=240&height=160&borderwidth=0&borderheight=0&bordercolor=e8e8e8&bg=f8f8f8&passepartoutwidth=0&passepartoutheight=0&passepartoutcolor=f8f8f8&cache=yes` :
        `ccImageProxy.ashx?filename=images/noimage.cci&width=240&height=160&borderwidth=0&borderheight=0&bordercolor=e8e8e8&bg=f8f8f8&passepartoutwidth=0&passepartoutheight=0&passepartoutcolor=f8f8f8&cache=yes`;

    const recordNumber = record?.metadata?.recordnumber;
    const invNo = record?.data?.Record?.ObjectNumber;
    const [hasBeenShown, setHasBeenShown] = useState(false);
    const iconRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!hasBeenShown && iconRef.current) {
            setTimeout(() => {
                iconRef.current?.classList.add(styles.fadeIn);
                setHasBeenShown(true); // Set the state to true after the icon has been shown
            }, 50);
        }
    }, [hasBeenShown]);


    function getTitle(record: any) {
        const titleData = record?.data?.record?.title?.title;

        if (!titleData) return <div className={styles.row}>{t('No title')}</div>;

        return (
            <div className={styles.row}>
                {titleData}
            </div>
        );
    }

    return (
        <div key={index}>
            <a
                href={`${window.location.pathname}?action=search&field=/Record/ObjectNumber&label=N°%20de%20gestion&value=${invNo}`}
                className={styles.MobileRecordIconScroll}
                onClick={(event) => handleLinkClick(event, record)}
                onMouseDown={e => e.preventDefault()}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div className={styles.iconViewRecord} ref={iconRef}>
                    {imageUrl && <ImageWithFallback src={imageUrl} alt="Image" placeholder="ccImageProxy.ashx?filename=images/noimage.cci&width=240&height=160" />}
                    {isHovered && (
                        <div className={styles.hoverOverlay}>
                            <div className={styles.title}>{getTitle(record)}</div>
                            {record?.data?.Record?.Country && (
                                <>
                                    <div className={styles.staticText}>{t("record_country")}</div>
                                    {Array.isArray(record?.data?.Record?.Country)
                                        ? record?.data?.Record?.Country[0]?.Term ?? ''
                                        : record?.data?.Record?.Country?.Term ?? ''
                                    }
                                </>
                            )}
                        </div>
                    )}
                </div>
            </a>
        </div>
    );
});

export default MobileRecordIconScroll;
