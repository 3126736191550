import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Draggable from 'react-draggable';
import genericStyles from '../GenericComponents/GenericStyles.module.css';
import styles from './MobileZoomedImage.module.css';
import GenericIconButton from '../GenericComponents/GenericIconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearchPlus, faSearchMinus, faExpand, faUndoAlt, faRedoAlt, faAdjust, faSlidersH, faDownload, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

interface OpenSeadragonViewerProps {
  dzi: string | any;
  downloadAllowed: boolean;
  images: { imagesmore: string; copyright: string, rank: string }[];
  initialIndex: number;
}

const OpenSeadragonViewer: React.FC<OpenSeadragonViewerProps> = ({ dzi, downloadAllowed, images = [], initialIndex = 0 }) => {
  const { t } = useTranslation();
  const viewerRef = useRef<HTMLDivElement>(null);
  const osdViewerRef = useRef<any>(null);
  const [isInverted, setIsInverted] = useState(false);
  const [showFilterPanel, setShowFilterPanel] = useState(false);
  const [brightness, setBrightness] = useState(0);
  const [contrast, setContrast] = useState(10);
  const [greyscale, setGreyscale] = useState(false);
  const [position, setPosition] = useState({ x: 425, y: 310 });
  const [errorLoading, setErrorLoading] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const retryCountRef = useRef(0);
  const retryLimit = 3;
  const currentIndexRef = useRef(initialIndex);

  const brightnessRef = useRef<HTMLInputElement>(null);
  const contrastRef = useRef<HTMLInputElement>(null);

  const hasActiveFilters = () => {
    return brightness !== 0 || contrast !== 10 || greyscale !== false;
  };

  const handleDrag = (e: any, data: any) => {
    setPosition({ x: data.x, y: data.y });
  };

  const applyFilters = (newBrightness = brightness, newContrast = contrast, newGreyscale = greyscale, newIsInverted = isInverted) => {
    if (osdViewerRef.current) {
      const OpenSeadragon = (window as any).OpenSeadragon;
      const processors = [
        OpenSeadragon.Filters.BRIGHTNESS(newBrightness),
        OpenSeadragon.Filters.CONTRAST(newContrast / 10),
        newIsInverted ? OpenSeadragon.Filters.INVERT() : null,
      ];
      if (newGreyscale) {
        processors.push(OpenSeadragon.Filters.GREYSCALE());
      }
      osdViewerRef.current.setFilterOptions({
        filters: {
          processors: processors.filter(p => p), // Remove nulls
        },
      });
    }
  };

  const handleBrightnessMouseUp = () => {
    if (brightnessRef.current) {
      const value = +brightnessRef.current.value;
      setBrightness(value);
      applyFilters(value);
    }
  };

  const handleContrastMouseUp = () => {
    if (contrastRef.current) {
      const value = +contrastRef.current.value;
      setContrast(value);
      applyFilters(brightness, value);
    }
  };

  const hideFilterPanel = () => {
    setShowFilterPanel(false);
  }

  const FilterOptionsPanel = () => (
    <Draggable
      handle=".dragHandle"
      position={position}
      onStart={(e) => {
        try {
          let el: (HTMLElement | null) = (e.target as HTMLElement).parentElement;
          while (el) {
            if (el.className.includes(styles.closeButton)) {
              return false;
            }
            el = el.parentElement;
          }
        } catch (e) {
        }
      }}
      onStop={handleDrag}
    >
      <div className={styles.popup}>
        <div className={`${styles.popupHeader} dragHandle`}>
          <span>{t("zoom_filter_options")}</span>
          <div className={styles.closeButton}>
            <GenericIconButton icon='light/times.svg' width='18px' height='18px' onClick={() => setShowFilterPanel(false)} />
          </div>
        </div>
        <div className={styles.popupContent}>
          <div className={styles.filterContainer}>
            <label>{t("zoom_brightness")}:
              <input
                ref={brightnessRef}
                type="range"
                min={-180}
                max={180}
                defaultValue={brightness}
                onMouseUp={handleBrightnessMouseUp}
              />
            </label>
          </div>
          <div className={styles.filterContainer}>
            <label>{t("zoom_contrast")}:
              <input
                ref={contrastRef}
                type="range"
                min={4}
                max={30}
                defaultValue={contrast}
                onMouseUp={handleContrastMouseUp}
              />
            </label>
          </div>
          <div className={styles.grayscaleFilterContainer}>
            <input
              className={styles.grayscaleCheckbox}
              type="checkbox"
              id="grayscaleInput"
              checked={greyscale}
              onChange={(e) => {
                const value = e.target.checked;
                setGreyscale(value);
                applyFilters(brightness, contrast, value);
              }}
            />
            <label htmlFor="grayscaleInput" className={styles.grayscale}>{t("zoom_grayscale")}</label>
          </div>
          <div className={styles.resetFilterContainer}>
            <button className={genericStyles.GenericButton} onClick={handleReset}>
              {t("zoom_reset")}
            </button>
          </div>
        </div>
      </div>
    </Draggable>
  );

  const handleReset = () => {
    resetFilters();
    applyFilters(0, 10, false);
  }

  const resetFilters = () => {
    setBrightness(0);
    setContrast(10);
    setGreyscale(false);
    if (brightnessRef.current) brightnessRef.current.value = '0';
    if (contrastRef.current) contrastRef.current.value = '10';
  };

  useEffect(() => {
    resetFilters();
    setIsInverted(false);
    if (viewerRef.current) {
      const OpenSeadragon = (window as any).OpenSeadragon;
      if (viewerRef.current) {
        if (osdViewerRef.current) {
          osdViewerRef.current.destroy();
        }

        setErrorLoading(false);
        retryCountRef.current = 0; // Reset retry count on new image load
        osdViewerRef.current = new OpenSeadragon({
          id: 'viewer',
          crossOriginPolicy: 'Anonymous',
          prefixUrl: 'https://openseadragon.github.io/openseadragon/images/',
          tileSources: dzi,
          showRotationControl: true,
          toolbar: 'viewerToolbar',
          zoomInButton: 'hiddenButton',
          zoomOutButton: 'hiddenButton',
          homeButton: 'hiddenButton',
          fullPageButton: 'hiddenButton',
          rotateLeftButton: 'hiddenButton',
          rotateRightButton: 'hiddenButton',
        });
        osdViewerRef.current.addHandler('open-failed', function (event: any) {
          if (retryCountRef.current < retryLimit) {
            retryCountRef.current += 1;
            osdViewerRef.current.open(dzi); // Retry opening the same image
          } else {
            setErrorLoading(true);
          }
        });
        osdViewerRef.current.addHandler('open', function (event: any) {
          setTimeout(() => {
            osdViewerRef.current.viewport.goHome();
          }, 250);
        });

        window.osdViewer = osdViewerRef.current;

      }
    }

    return () => {
      if (osdViewerRef.current) {
        osdViewerRef.current.destroy();
      }
    };
  }, [dzi]);

  useEffect(() => {
    if (images.length > 0 && images[initialIndex]?.imagesmore) {
      const newImageFilename = replaceExtensionWithDzi(images[initialIndex].imagesmore);
      const newImageUrl = `ccImageProxy.ashx?a=4&server=localhost&port=15012&filename=${newImageFilename}`;
      osdViewerRef.current.open(newImageUrl);
      currentIndexRef.current = initialIndex;
    }
  }, [initialIndex, images]);

  const handleHomeClick = () => {
    osdViewerRef.current.viewport.goHome();
  };

  const handleZoomInClick = () => {
    osdViewerRef.current.viewport.zoomBy(1.4);
  };

  const handleZoomOutClick = () => {
    osdViewerRef.current.viewport.zoomBy(1 / 1.4);
  };

  const handleFullscreenClick = () => {
    const target = document.getElementById('zoomedImage') as HTMLDivElement;

    if (document.fullscreenElement) {
      document.exitFullscreen();
      setIsFullScreen(false);
    } else {
      if (target.requestFullscreen) {
        target.requestFullscreen();
      } else if ((target as any).mozRequestFullScreen) { // Firefox
        (target as any).mozRequestFullScreen();
      } else if ((target as any).webkitRequestFullscreen) { // Chrome, Safari and Opera
        (target as any).webkitRequestFullscreen();
      } else if ((target as any).msRequestFullscreen) { // IE/Edge
        (target as any).msRequestFullscreen();
      }
      setIsFullScreen(true);
    }
  };

  const handleRotateLeftClick = () => {
    osdViewerRef.current.viewport.rotateBy(-90);
  };

  const handleRotateRightClick = () => {
    osdViewerRef.current.viewport.rotateBy(90);
  };

  const handleInvertClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsInverted(!isInverted);
    applyFilters(brightness, contrast, greyscale, !isInverted);
  };

  const handleFiltersClick = () => {
    setShowFilterPanel(!showFilterPanel);
  };

  const handleDownloadClick = () => {
    osdViewerRef.current.viewport.goHome();
  };

  function replaceExtensionWithDzi(imageUrl: string): string {
    const urlWithoutExtension = imageUrl.substring(0, imageUrl.lastIndexOf('.'));
    return `${urlWithoutExtension}.dzi`;
  }

  const handlePrev = () => {
    if (currentIndexRef.current > 0) {
      currentIndexRef.current -= 1;
      const newImageFilename = replaceExtensionWithDzi(images[currentIndexRef.current].imagesmore);
      const newImageUrl = `ccImageProxy.ashx?a=5&filename=images/${newImageFilename}`;
      osdViewerRef.current.open(newImageUrl);
    }
  };

  const handleNext = () => {
    if (currentIndexRef.current < images.length - 1) {
      currentIndexRef.current += 1;
      const newImageFilename = replaceExtensionWithDzi(images[currentIndexRef.current].imagesmore);
      const newImageUrl = `ccImageProxy.ashx?a=6&filename=images/${newImageFilename}`;
      osdViewerRef.current.open(newImageUrl);
    }
  };

  return (
    <div id="zoomedImage" className={styles.ZoomedImage} >
      {!errorLoading && <div ref={viewerRef} id="viewer" className={styles.viewerBody} />}
      {!errorLoading && images[currentIndexRef.current]?.copyright && <div className={styles.copyright}>{images[currentIndexRef.current].copyright}</div>}
      {!errorLoading && (
        <div id='viewerToolbar' className={`${styles.viewerToolbar} screenOnly`} >
          <div className={styles.toolbarItem} id='zoombutton_home' onClick={handleHomeClick}>
            <FontAwesomeIcon icon={faHome} />
          </div>
          <div className={styles.toolbarItem} id='zoombutton_zoom-in' onClick={handleZoomInClick}>
            <FontAwesomeIcon icon={faSearchPlus} />
          </div>
          <div className={styles.toolbarItem} id='zoombutton_zoom-out' onClick={handleZoomOutClick}>
            <FontAwesomeIcon icon={faSearchMinus} />
          </div>
          <div className={styles.toolbarItem} id='zoombutton_full-page' onClick={handleFullscreenClick}>
            <FontAwesomeIcon icon={faExpand} />
          </div>
          <div className={styles.toolbarItem} id='zoombutton_rotate_left' onClick={handleRotateLeftClick}>
            <FontAwesomeIcon icon={faUndoAlt} />
          </div>
          <div className={styles.toolbarItem} id='zoombutton_rotate_right' onClick={handleRotateRightClick}>
            <FontAwesomeIcon icon={faRedoAlt} />
          </div>
          <div className={styles.toolbarItem} id='zoombutton_invert' onClick={handleInvertClick} >
            <FontAwesomeIcon icon={faAdjust} />
            {isInverted && <div className={styles.redDot}></div>}
          </div>
          <div className={styles.toolbarItem} id='zoombutton_filters' onClick={handleFiltersClick}>
            <FontAwesomeIcon icon={faSlidersH} />
            {hasActiveFilters() && <div className={styles.redDot}></div>}
          </div>
          {downloadAllowed && (
            <div className={styles.toolbarItem} id='downloadButton' onClick={() => {
              var img = osdViewerRef.current.drawer.canvas.toDataURL("image/png");
              var link = document.createElement('a');
              link.href = img;
              link.download = 'image.png';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}>
              <FontAwesomeIcon icon={faDownload} />
            </div>
          )}
          <div className={`${styles.toolbarItem} ${images.length <= 1 ? styles.hidden : ''}`} id='prevButton' onClick={handlePrev}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
          <div className={`${styles.toolbarItem} ${images.length <= 1 ? styles.hidden : ''}`} id='nextButton' onClick={handleNext}>
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
        </div>
      )}
      {showFilterPanel && <FilterOptionsPanel />}
      {errorLoading && <div><img src="ccImageProxy.ashx?a=7&filename=images/noimage.cci&width=180&_height=160&borderwidth=0&borderheight=0&bordercolor=e8e8e8&bg=f8f8f8&passepartoutwidth=0&passepartoutheight=0&passepartoutcolor=f8f8f8&cache=yes" /></div>}
    </div>
  );
};

export default OpenSeadragonViewer;
