import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import GenericIcon from '../GenericComponents/GenericIcon';
import GenericIconButton from '../GenericComponents/GenericIconButton';
import styles from './Menu.module.css';
import { CCProvider, useCCContext } from '../CC/CCContext';

type MenuProps = {
    mainY: number;
    setAndKeepMainY: (newMainY: number, prevMainY: number) => void;
    scrollBack: () => void;
};

const Menu: React.FC<MenuProps> = ({ mainY, setAndKeepMainY, scrollBack }) => {
    const topRef = useRef<HTMLDivElement | null>(null);
    const { t, i18n } = useTranslation();
    const { cc, setCc, first, setFirst, recordsPerPage, setRecordsPerPage, viewMode, setViewMode, browseMode, setBrowseMode, data, setData, isReady } = useCCContext();

    return (
        <div className={styles.Menu}>
            <div className={styles.menucontainer}>
                <div ref={topRef} className={styles.header}>
                    <div className={styles.header_logo}
                        onClick={(e: any) => {
                            e.preventDefault();
                            setViewMode('homepage');
                        }}>
                        <div>
                            <div className={styles.header_logo_text}>Collections</div>
                            <div className={styles.header_logo_svg}>
                                <img src="header_logo.svg" />
                            </div>
                        </div>
                    </div>
                    <div className={styles.topRightButton}>
                        <GenericIconButton
                            icon='light/times.svg'
                            hoverEnabled={true}
                            width='28px'
                            height='28px'
                            onClick={(e: any) => {
                                scrollBack();
                            }}
                        />
                    </div>
                </div>
                <div className={styles.menubody}>
                    <footer className={styles.footer}>
                        <div className={styles.topSection}>
                            <div className={styles.title_and_subscribe}>
                                <h2 className={styles.title}>{t('footer_stay_connected')}</h2>
                                <a href="https://www.monuments-nationaux.fr/abonnement-a-l-infolettre" target="_blank" rel="noopener noreferrer">
                                    <span className={styles.subscribe}>{t('footer_subscribe')}</span>
                                </a>
                            </div>
                            <div className={styles.socialIcons}>
                                <a href="https://www.linkedin.com/company/centre-des-monuments-nationaux/" target="_blank" rel="noopener noreferrer"><img style={{ width: '70px' }} src="Linkedin.png" alt="LinkedIn" /></a>
                                <a href="https://www.instagram.com/lecmn" target="_blank" rel="noopener noreferrer"><img style={{ width: '60px' }} src="Insta.png" alt="Instagram" /></a>
                                <a href="https://www.facebook.com/leCMN" target="_blank" rel="noopener noreferrer"><img style={{ width: '60px' }} src="Facebook.png" alt="Facebook" /></a>
                                <a href="https://www.x.com/leCMN" target="_blank" rel="noopener noreferrer"><img style={{ width: '60px' }} src="X.png" alt="X" /></a>
                                <a href="https://www.youtube.com/@LeCMN" target="_blank" rel="noopener noreferrer"><img style={{ width: '60px' }} src="Youtube.png" alt="YouTube" /></a>
                                <a href="https://www.tiktok.com/@le_cmn" target="_blank" rel="noopener noreferrer"><img style={{ width: '60px' }} src="TikTok.png" alt="TikTok" /></a>
                            </div>
                        </div>
                        <hr className={styles.divider} />
                        <div className={styles.linksSectionWrapper}>
                            <div className={styles.linksSection}>
                                <div className={styles.column}>
                                    <a href="https://www.monuments-nationaux.fr/Contactez-nous" target="_blank" rel="noopener noreferrer">{t('footer_contact_us')} <div className={styles.hyperlinkIconParent}><GenericIcon icon="regular/arrow-up.svg" className={styles.hyperlinkIcon}></GenericIcon></div></a>
                                    <a href="https://www.monuments-nationaux.fr/" target="_blank" rel="noopener noreferrer">{t('footer_cmn_website')} <div className={styles.hyperlinkIconParent}><GenericIcon icon="regular/arrow-up.svg" className={styles.hyperlinkIcon}></GenericIcon></div></a>
                                    <a href="https://www.monuments-nationaux.fr/Trouver-un-monument" target="_blank" rel="noopener noreferrer">{t('footer_find_monument')} <div className={styles.hyperlinkIconParent}><GenericIcon icon="regular/arrow-up.svg" className={styles.hyperlinkIcon}></GenericIcon></div></a>
                                    <a href="https://tickets.monuments-nationaux.fr/fr-FR/accueil" target="_blank" rel="noopener noreferrer">{t('footer_ticketing')} <div className={styles.hyperlinkIconParent}><GenericIcon icon="regular/arrow-up.svg" className={styles.hyperlinkIcon}></GenericIcon></div></a>
                                </div>
                                <div className={styles.column}>
                                    <h3>{t('footer_support_us')}</h3>
                                    <a href="https://www.monuments-nationaux.fr/faire-un-don" target="_blank" rel="noopener noreferrer">{t('footer_donate')} <div className={styles.hyperlinkIconParent}><GenericIcon icon="regular/arrow-up.svg" className={styles.hyperlinkIcon}></GenericIcon></div></a>
                                    <a href="https://www.monuments-nationaux.fr/entreprises-et-fondations" target="_blank" rel="noopener noreferrer">{t('footer_companies_foundations')} <div className={styles.hyperlinkIconParent}><GenericIcon icon="regular/arrow-up.svg" className={styles.hyperlinkIcon}></GenericIcon></div></a>
                                </div>
                                <div className={styles.column}>
                                    <h3>{t('footer_go_further')}</h3>
                                    <a href="https://www.monuments-nationaux.fr/editions-du-patrimoine" target="_blank" rel="noopener noreferrer">{t('footer_publishing_house')} <div className={styles.hyperlinkIconParent}><GenericIcon icon="regular/arrow-up.svg" className={styles.hyperlinkIcon}></GenericIcon></div></a>
                                    <a href="https://regards.monuments-nationaux.fr/fr/" target="_blank" rel="noopener noreferrer">{t('footer_image_bank')} <div className={styles.hyperlinkIconParent}><GenericIcon icon="regular/arrow-up.svg" className={styles.hyperlinkIcon}></GenericIcon></div></a>
                                    <a href="https://references.monuments-nationaux.fr/" target="_blank" rel="noopener noreferrer">{t('footer_documentary_resources')} <div className={styles.hyperlinkIconParent}><GenericIcon icon="regular/arrow-up.svg" className={styles.hyperlinkIcon}></GenericIcon></div></a>
                                </div>
                            </div>
                        </div>
                        <div className={styles.logoSection}>
                            <img src="RepublFrance_noir.png" alt="Logo République Française" className={styles.logo} />
                            <a href="https://www.monuments-nationaux.fr/" target="_blank" rel="noopener noreferrer"><img src="CMN-Logo-petit-noir.png" alt="Logo CMN" className={styles.logo} /></a>
                        </div>
                        <hr className={styles.divider} />
                        <div className={styles.bottomSection}>
                            <a href="https://www.monuments-nationaux.fr/mentions-legales" target="_blank" rel="noopener noreferrer">{t('footer_legal_mentions')}</a>
                            <span className={styles.separator}>|</span>
                            <a href="https://www.monuments-nationaux.fr/magazine/nos-collections/dans-les-coulisses-de-nos-collections/la-base-de-donnees-collectio" target="_blank" rel="noopener noreferrer">{t('footer_about')}</a>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    )
};

export default Menu;
