import React from 'react';
import GenericIconButton from '../GenericComponents/GenericIconButton';
import styles from './ViewMode.module.css';
import { CCProvider, useCCContext } from '../CC/CCContext';

function ViewMode() {
  const { cc, setCc, first, setFirst, recordsPerPage, setRecordsPerPage, viewMode, setViewMode, browseMode, setBrowseMode, data, setData } = useCCContext();
  
  const changeModeToIcons = () => {
    setViewMode('icons');
    setCc(cc);
  }
  
  const changeModeToList = () => {
    setViewMode('list');
    setCc(cc);
  }

  const changeModeToMap = () => {
    setViewMode('map');
    setCc(cc);
  }
  
  return (
    <div className={styles.ViewMode}>
      <GenericIconButton isActive={viewMode === 'icons'} icon='light/th.svg' onClick={ () => changeModeToIcons() }/>
      <GenericIconButton isActive={viewMode === 'list'} icon='light/list.svg' onClick={ () => changeModeToList() }/>
    </div>
  );
}

export default ViewMode;
