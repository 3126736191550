import React, { useState, useEffect } from 'react';
import styles from './ResultSetSlider.module.css';
import { CCProvider, useCCContext } from '../CC/CCContext';

function ResultSetSlider() {
  const { cc, setCc, first, setFirst, recordsPerPage, setRecordsPerPage, viewMode, setViewMode, browseMode, setBrowseMode, data, setData } = useCCContext();
  const totalRecords = data.request?.count || 0;
  const page = Math.floor((first - 1) / recordsPerPage) + 1;
  const totalPages = Math.floor((totalRecords - 1) / recordsPerPage) + 1;
  const [isInput, setIsInput] = useState(false);
  const [inputPage, setInputPage] = useState(page);
  const [sliderPage, setSliderPage] = useState(page); // Local copy for the slider

  const updateFirstRecord = (newFirst: number) => {
    setFirst(newFirst);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputPage(parseInt(e.target.value, 10));
  };

  const handleBlur = () => {
    if (inputPage >= 1 && inputPage <= totalPages) {
      updateFirstRecord((inputPage - 1) * recordsPerPage + 1);
    }
    setIsInput(false);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleBlur();
    }
  };

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPage = parseInt(e.target.value, 10);
    setSliderPage(newPage); // Update the local copy only
  };

  const handleSliderMouseUp = () => {
    updateFirstRecord((sliderPage - 1) * recordsPerPage + 1); // Update the actual value on mouse release
  };

  useEffect(() => {
    // This effect will run whenever the value of 'first' changes
    const newPage = Math.floor((first - 1) / recordsPerPage) + 1;
    setSliderPage(newPage);
  }, [first, recordsPerPage]);

  return (
    <div className={styles.ResultSetSlider}>
      <input 
        type="range" 
        min="1" 
        max={totalPages} 
        value={sliderPage} // Use the local copy for rendering
        onChange={handleSliderChange} 
        onMouseUp={handleSliderMouseUp} // Handle mouse release
        onTouchEnd={handleSliderMouseUp}
        style={{width: '100%'}}
      />
    </div>
  );
}

export default ResultSetSlider;
