import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CopyHyperlinkButton.module.css';
import { CC } from '../CC/CC';
import { useCCContext } from '../CC/CCContext';
import * as ccUtils from '../CC/utils';
import GenericIconButton from '../GenericComponents/GenericIconButton';

type CopyHyperlinkButtonProps = {};

const CopyHyperlinkButton: React.FC<CopyHyperlinkButtonProps> = ({}) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState<string | null>(null);
  const [messageVisible, setMessageVisible] = useState(false); // <-- New state for message visibility
  const { cc } = useCCContext();

  const handleButtonClick = () => {
    const hyperlink = ccUtils.GenerateStateHyperlink(cc);

    // Copy to clipboard
    navigator.clipboard.writeText(hyperlink).then(() => {
      setMessage(t('copy_hyperlink_ok'));
      setMessageVisible(true); // <-- Set message to visible

      // Clear the message after 3 seconds and set visibility to false
      setTimeout(() => {
        //setMessage(null);
        setMessageVisible(false);
      }, 3000);
    }).catch(err => {
      console.error('Failed to copy text: ', err);
      setMessage(t("copy_hyperlink_failed"));
    });
  };

  return (
    <div className={styles.CopyHyperlinkButton}> 
        <GenericIconButton 
            icon='light/link.svg'
            hoverEnabled={false}
            width='16px' 
            height='16px'
            onClick={() => {handleButtonClick()}}
        />
        <div className={`${styles.message} ${messageVisible ? styles.fadeIn : styles.fadeOut}`}>
          {message}
        </div>
    </div>
  );
};

export default CopyHyperlinkButton;
