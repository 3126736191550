import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ccConfig, CC } from '../CC/CC';
import { CCProvider, useCCContext } from '../CC/CCContext';
import styles from './Filter.module.css';

interface FilterProps {
  filterName: string; // Filter name
}

function Filter({ filterName }: FilterProps) {
  const { t } = useTranslation();
  const { cc, setCc, first, setFirst, recordsPerPage, setRecordsPerPage, viewMode, setViewMode, browseMode, setBrowseMode, data, setData } = useCCContext();

  useEffect(() => {
    return () => {
      setCc({
        ...cc,
        filters: cc.filters.filter((filter) => filter !== filterName),
      });
    };
  }, []);

  const handleFilterClick = () => {
    setFirst(1);
    if (viewMode == "homepage") {
      setViewMode('list');
    }
    if (cc.filters.includes(filterName)) {
      setCc({ ...cc, filters: cc.filters.filter((filter) => filter !== filterName) });
    } else {
      setCc({ ...cc, filters: [...cc.filters, filterName] });
    }
  };

  return (
    <div className={`${styles.filterContainer} ${cc.filters.includes(filterName) ? styles.filterContainerSelected : ''}`} onClick={handleFilterClick}>
      <span className={cc.filters.includes(filterName) ? styles.activeDot : ''}></span>
      {t(ccConfig.filters[filterName as keyof typeof ccConfig.filters]?.label)}
    </div>      
  );
}

export default Filter;
