import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './List.module.css';
import { useCCContext } from '../CC/CCContext';
import RecordPopup from './RecordPopup';
import RecordList from '../Record/RecordList';

function List() {
    const { t } = useTranslation();
    const [selectedRecord, setSelectedRecord] = useState<any | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const ctx = useCCContext();
  
    useEffect(() => {
        setIsModalOpen(false);
    }, [ctx.cc, ctx.first]);

    if (!ctx.data.records || !ctx.data.records.record) {
        return <div>{t("no_records_found")}</div>;
    }

    const records = Array.isArray(ctx.data.records.record) ? ctx.data.records.record : [ctx.data.records.record];

    const selectRecord = (recordNumber: number) => {
        setSelectedRecord(recordNumber);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleLinkClick = (event: React.MouseEvent, index: number) => {
        event.preventDefault();
        selectRecord(ctx.first + index);
    };

    return (
        <div>
            {records.map((record: any, index: number) => (
                <RecordList key={index} record={record} index={index} handleLinkClick={handleLinkClick} />
            ))}
            {isModalOpen && <RecordPopup recordNumber={selectedRecord} onClose={closeModal} />}
        </div>
    );
}

export default List;
