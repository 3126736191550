import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, XIcon, LinkedinShareButton, LinkedinIcon, PinterestShareButton, PinterestIcon} from 'react-share';
import styles from './RecordPopup.module.css';
import { generateQuery } from '../CC/utils';
import { ccConfig, CC } from '../CC/CC';
import { CCProvider, useCCContext } from '../CC/CCContext';
import Record from '../Record/Record';
import GenericIconButton from '../GenericComponents/GenericIconButton';
import * as ccUtils from '../CC/utils';
import genericStyles from '../GenericComponents/GenericStyles.module.css';

interface RecordPopupProps {
  recordNumber: number;
  onClose: () => void;
}

function copyCanvasContent(sourceCanvas: HTMLCanvasElement, targetCanvas: HTMLCanvasElement) {
  const ctx = targetCanvas.getContext('2d');
  ctx?.drawImage(sourceCanvas, 0, 0);
}

const generatePdf = () => {

  if (window.osdViewer) {
    window.osdViewer.viewport.goHome();
  }
  setTimeout(() => {
    // Step 1: Create a deep clone of the original element.
    const originalElement = document.getElementById("pdf-container") as HTMLElement;
    const clonedElement = originalElement.cloneNode(true) as HTMLElement;

    // Copy canvas contents
    const originalCanvases = originalElement.querySelectorAll('canvas');
    const clonedCanvases = clonedElement.querySelectorAll('canvas');

    originalCanvases.forEach((canvas, index) => {
      copyCanvasContent(canvas, clonedCanvases[index] as HTMLCanvasElement);
    });

    clonedElement.style.top = '-10000px'; // move it far off-screen
    clonedElement.style.display = 'block'; // keep it hidden
    document.body.appendChild(clonedElement);

    setTimeout(() => {
      (clonedElement.querySelectorAll('.pdfOnly') as NodeListOf<HTMLElement>).forEach(el => el.style.display = 'block');
      (clonedElement.querySelectorAll('.screenOnly') as NodeListOf<HTMLElement>).forEach(el => el.style.display = 'none');

      // Step 3: Send the modified cloned element to html3pdf.
      (window as any).html3pdf(clonedElement, {
        margin: 10,
        filename: 'monumentsNotice.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      });
      document.body.removeChild(clonedElement);
    });
  }, 1000);

}

const RecordPopup: React.FC<RecordPopupProps> = ({ recordNumber, onClose }) => {
  const { t } = useTranslation();
  const instanceRef = useRef({});
  const { cc, setCc, sort, first, setFirst, recordsPerPage, setRecordsPerPage, viewMode, setViewMode, browseMode, setBrowseMode, data, setData } = useCCContext();
  const [selectedRecord, setSelectedRecord] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(recordNumber);
  const [isInput, setIsInput] = useState(false);
  const [inputPage, setInputPage] = useState(currentPage);
  const [sliderPage, setSliderPage] = useState(currentPage); // Local copy for the slider

  const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPage = parseInt(e.target.value, 10);
    setSliderPage(newPage); // Update the local copy only
  };

  const handleSliderMouseUp = () => {
    updateCurrentPage(sliderPage); // Update the actual value on mouse release
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputPage(parseInt(e.target.value, 10));
  };

  const handleBlur = () => {
    if (inputPage >= 1 && inputPage <= totalRecords) {
      updateCurrentPage(inputPage);
    }
    setIsInput(false);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleBlur();
    }
  };

  const updateCurrentPage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const goToPreviousPage = () => updateCurrentPage(Math.max(1, Number(currentPage) - 1));
  const goToNextPage = () => updateCurrentPage(Math.min(totalRecords, Number(currentPage) + 1));
  const goToFirstPage = () => updateCurrentPage(1);
  const goToLastPage = () => updateCurrentPage(Number(totalRecords));

  useEffect(() => {
    const fetchData = async () => {
      const query = generateQuery(cc);
      const rangeStart = currentPage;

      const postData = {
        action: "get",
        command: "search",
        query: query,
        fields: "*",
        range: `${rangeStart}-${rangeStart}`,
        sort: ccConfig.sort[sort].field,
        responseformat: "json"
      };

      try {
        const data = await ccUtils.postWithCache(process.env.REACT_APP_BASE_URL as string, postData, instanceRef);
        console.log("payload", data);

        setTotalRecords(data.request.count);
        const records = Array.isArray(data?.records?.record) ? data?.records?.record : [data?.records?.record];
        setSelectedRecord(records[0] ?? null);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentPage]);

  function ShareButtons() {

    const invNo = selectedRecord?.data?.record?.objectnumber;
    const encodedInvNo = encodeURIComponent(invNo);
    //http://88.99.164.61/pod23/ccExternal.ashx?id=71.1881.21.28.1-11
    const url = `${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}${window.location.pathname}?id=${encodedInvNo}`;
    const recordTitle = selectedRecord?.data?.record?.['titleorobjectname'];
    let title = 'Centre des monuments nationaux';
    if(recordTitle) {
      title = title + ' - ' + recordTitle;
    }

    return (
      <div className={styles.socialButtons}>
        <div className={styles.socialButtonWrapper}>
          <FacebookShareButton url={url}>
            <FacebookIcon size={31} borderRadius={4} />
          </FacebookShareButton>
        </div>
        <div className={styles.socialButtonWrapper}>
          <TwitterShareButton url={url} title={title}>
            <XIcon size={31} borderRadius={4} />
          </TwitterShareButton>
        </div>
        <div className={styles.socialButtonWrapper}>
          <LinkedinShareButton url={url} title={title}>
            <LinkedinIcon size={31} borderRadius={4} />
          </LinkedinShareButton>
        </div>
        <div className={styles.socialButtonWrapper}>
          <PinterestShareButton url={url} media={url} description={title}>
            <PinterestIcon size={31} borderRadius={4} />
          </PinterestShareButton>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.popupoverlay} onClick={handleClose}>
      <div className={styles.RecordPopup} >
        <div className={styles.popupheader}>
          <div className={styles.Navigator} >
            <div className={styles.Paginator}>
              <GenericIconButton icon='light/angle-double-left.svg' width='22px' height='20px' onClick={() => goToFirstPage()} />
              <GenericIconButton icon='light/angle-left.svg' width='22px' height='20px' onClick={() => goToPreviousPage()} />
              <GenericIconButton icon='light/angle-right.svg' width='22px' height='20px' onClick={() => goToNextPage()} />
              <GenericIconButton icon='light/angle-double-right.svg' width='22px' height='20px' onClick={() => goToLastPage()} />
            </div>
            <div className={styles.CurPage}>
              {t("curpage_page")}&nbsp;
              {isInput ? (
                <input
                  type="number"
                  value={inputPage}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyPress}
                  style={{ width: '50px' }}
                  autoFocus
                />
              ) : (
                <span onClick={() => { setIsInput(true); setInputPage(currentPage); }}>{currentPage.toLocaleString()}</span>
              )}&nbsp;{t("curpage_of")} {Number(totalRecords).toLocaleString()}
            </div>
            <div className={styles.ResultSetSlider}>
              <input
                type="range"
                min="1"
                max={totalRecords}
                value={sliderPage} // Use the local copy for rendering
                onChange={handleSliderChange}
                onMouseUp={handleSliderMouseUp} // Handle mouse release
                onTouchEnd={handleSliderMouseUp}
                style={{ width: '100%' }}
              />
            </div>
          </div>
          <div className={styles.closeButtonContainer}>
            <div className={styles.socials}>
              {ShareButtons()}
            </div>
            <div className={styles.generatePdfButton}>
              <button className={genericStyles.GenericButton} onClick={generatePdf}>{t("record_generate_pdf")}</button>
            </div>
            <div className={styles.closeButton}>
              <GenericIconButton icon='light/times.svg' width='18px' height='18px' onClick={() => onClose()} />
            </div>
          </div>
        </div>
        <div className={styles.popupcontent}>
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <Record record={selectedRecord} />
          )}
        </div>
      </div>
    </div>
  );
};

export default RecordPopup;
