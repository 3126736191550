import React, { useRef, useState, useEffect } from 'react';
import { CC } from '../CC/CC';
import * as ccUtils from '../CC/utils';
import styles from './EmbedCount.module.css';
import { ICCContext, CCProvider, CCContext, useCCContext } from '../CC/CCContext';

interface EmbedCountProps {
  hyperlink?: string;
  state?: string | null;
  ccContext: ICCContext;
}

const deepMerge = (target: any, source: any): any => {
  if (typeof target !== 'object' || target === null) {
    return source;
  }

  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      if (typeof source[key] === 'object' && source[key] !== null && target.hasOwnProperty(key)) {
        target[key] = deepMerge(target[key], source[key]);
      } else {
        target[key] = source[key];
      }
    }
  }
  return target;
};

const search: any = async (cc: CC, instanceRef: any) => {
  const payload = ccUtils.generatePayload(cc, "relevance", 1, 1);
  try {
    const responseData = await ccUtils.postWithCache(process.env.REACT_APP_BASE_URL as string, payload, instanceRef);
    return responseData;
  } catch (error) {
    console.error(error);
  }
};

const EmbedCount: React.FC<EmbedCountProps> = ({ hyperlink, state, ccContext }) => {
  const instanceRef = useRef({});
  const [count, setCount] = useState<number>(0); // Introducing state for count

  useEffect(() => {
    let ccObj = new CC();

    if (state) {
      console.log('article', 'parsed state in count', JSON.parse(state));
      ccObj = deepMerge(ccObj, JSON.parse(state));
    } else {
      console.log('article', 'no state');
    }

    // Call the search function and set the count state when the data is received
  search(ccObj, instanceRef)
    .then((data: { request?: { count?: number } }) => { // Here's the type
      console.log('article', 'data', data);
      setCount(data?.request?.count ?? 0);
    })
    .catch((error: Error) => { // And here's the type
      console.error(error);
    });



  }, [state]); // useEffect dependency on state

  const handleClick = () => {
    let ccObj = new CC();
    if (state) {
      console.log('article', 'parsed state in count', JSON.parse(state));
      ccObj = deepMerge(ccObj, JSON.parse(state));
    } else {
      console.log('article', 'no state');
    }
    ccContext.setCc(ccObj);
  }

  return (
    <span className={styles.EmbedCount} onClick={handleClick}>
      {count}
    </span>
  );
}

export default EmbedCount;
