import React, { useState, useEffect, useRef, useCallback, useReducer } from 'react';
import genericStyles from '../GenericComponents/GenericStyles.module.css';
import styles from './EmbedFacet.module.css';
import { CC, ccConfig } from '../CC/CC';
import { generateQuery } from '../CC/utils'; 
import GenericIconButton from '../GenericComponents/GenericIconButton';
import GenericCheckbox from '../GenericComponents/GenericCheckbox';
import * as ccUtils from '../CC/utils';

interface FacetProps {
  facetName?: string;
  field?: string;
  contentHeight?: string;
  sort?: 'alpha' | 'count'; 
  onSelect?: (values: string[]) => void;
  showSelectedAbove?: boolean;
}

interface FacetDataItem {
  value: string;
  count: string;
}

const Facet: React.FC<FacetProps> = ({ facetName = "", field = "", contentHeight, sort = 'count', onSelect = undefined, showSelectedAbove = true }) => {
  //console.log("initial facetName : "+JSON.stringify(facetName)+":"+JSON.stringify(field));
  const [cc, setCc] = useState<CC>(new CC());
  const instanceRef = useRef({});
  const [currentQuery, setCurrentQuery] = useState("");
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [renderCount, setRenderCount] = useState(0); // Add this line at the beginning of your component
  const [facetData, setFacetData] = useState<FacetDataItem[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const observer = useRef<IntersectionObserver | null>(null);
  const [userInput, setUserInput] = useState('');
  const [currentSort, setCurrentSort] = useState(sort);
  const [facetCount, setFacetCount] = useState(0);
  const facetRootRef = useRef<HTMLDivElement>(null);
  const lastFetchedInputRef = useRef('');

  const lastElementRef = useCallback((node: HTMLDivElement | null) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        console.log('setting page: '+page);
        setPage(prevPage => prevPage + 1);
        forceUpdate();
      }
    });
    if (node) observer.current.observe(node);
  }, [hasMore]);

  const fetchData = async (page: number) => {

    if(facetName=="" && field == "") {
      return;
    }
    
    if(facetName!="") {
      field = ccConfig?.facets[facetName]?.query;
    }

    if(field=="") {
      return;
    }
 
    lastFetchedInputRef.current = userInput;
    const pageSize = 100;
    const query = generateQuery(cc, { excludeFacet: facetName }); 
    console.log('article','getting facet data',field);
    console.log('article','query',query);
    setCurrentQuery(query);
  
    const postData = {
      action: "get",
      command: "facets",
      fields: `${field}:${pageSize * (page - 1) + 1}-${pageSize * page}:*${userInput}*`,
      query: query,
      sort: currentSort,
      responseformat: "json"
    };
  
    try {
      const data = await ccUtils.postWithCache(process.env.REACT_APP_BASE_URL as string, postData, instanceRef);

      console.log('article','facetdata',data);
      let newData: FacetDataItem[] = data?.facets?.facet ? Array.isArray(data?.facets?.facet?.value) ? data?.facets?.facet?.value : [data?.facets?.facet?.value] : [];
       
      if (userInput === lastFetchedInputRef.current) {
        setFacetData(prev => {
            const updatedData = [...prev];
            newData.forEach(item => {
                if (!updatedData.find(i => i.value === item.value)) {
                    updatedData.push(item);
                }
            });
            return updatedData;
        });
        setHasMore(data?.facets?.facet && data.facets.facet.count > pageSize * page);
        setFacetCount(data?.facets?.facet ? data.facets.facet.count : 0);
      } else {
        console.log('Skipped data update');
      }
        
    } catch (error) {
      //setFacetCount(0);
      console.error(error);
    }
  };
  
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setUserInput(e.target.value);
    setPage(1);
    setFacetData([]);
    forceUpdate();
  };

  const clearInput = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setUserInput('');
    setFacetData([]);
    setPage(1);
    forceUpdate();
  };

  const handleAlphaSort = () => {
    setCurrentSort('alpha');
    setFacetData([]);
    setPage(1);
    forceUpdate();
  };

  const handleCountSort = () => {
    setCurrentSort('count');
    setFacetData([]);
    setPage(1);
    forceUpdate();
  };

  const handleCheckboxChange = (checked: boolean, value: string) => {
    //
  };
  
  const forceUpdate = () => setRenderCount(renderCount + 1);

  useEffect(() => {
    //if (page > 1) {
      console.log("In renderCont: ",page)
      fetchData(page);
    //}
  }, [page, renderCount]);
 
  useEffect(() => {
    const query = generateQuery(cc, { excludeFacet: facetName }); 
    if(currentQuery != query) {
        setFacetData([]);
        setPage(1);
        forceUpdate();
        fetchData(1);
    }
  }, [cc]);

  return (
    <div className={styles.facetContainer} ref={facetRootRef}>
      <div className={styles.facetName}>
        <span className={styles.facetNameLabel}>
          {ccConfig.facets[facetName] ? ccConfig.facets[facetName].label : "No label"}
        </span>
        <span className={styles.facetNameCount}>
          ({facetCount} value{facetCount!=1 ? 's' : ''})
        </span>
      </div>
      <div className={styles.header}>
          <div className={styles.inputContainer}>
            <input className={styles.mask} type="text" value={userInput} placeholder="filter" onChange={handleInput} />
          </div>
          <GenericIconButton icon='light/times.svg' width='15px' height='15px' fill='#f8f8f8' hoverBackgroundColor='#56ce67' onClick={ clearInput }/>
          <div className={styles.sortButtons}>
              <GenericIconButton isActive={currentSort === 'alpha'} activeBorderColor='#ffffff' icon='regular/sort-alpha-down.svg' width='15px' height='15px' fill='#f8f8f8' hoverFill = '#0000ff' hoverBackgroundColor='#56ce67' onClick={handleAlphaSort} />
              <GenericIconButton isActive={currentSort === 'count'} activeBorderColor='#ffffff' icon='regular/sort-amount-down.svg' width='15px' height='15px' fill='#f8f8f8' hoverFill = '#0000ff' hoverBackgroundColor='#56ce67' onClick={handleCountSort} />
          </div>
      </div>
      <div className={styles.selectedItems}>
        {showSelectedAbove && cc.facets[facetName]?.length > 0 && (
            <div className={styles.clearAllContainer} onClick={() => {}}>
              <div className={styles.clearAllIconContainer}>
                <div className={styles.clearAll} />
                <div className={styles.clearAll} />
                <div className={styles.clearAll} />
              </div>
              <div className={styles.clearAllText}>
                Clear selected
              </div>
            </div>
        )}

      </div>
  
      <div className={styles.content} style={{height: contentHeight}}>
        {facetData.filter(item => (!showSelectedAbove) || !(cc.facets[facetName]?.includes(item.value))).map((item) => (
          <div key={item.value} ref={facetData.indexOf(item) === facetData.length - 1 ? lastElementRef : null}>
            <GenericCheckbox
                id={`facet_${field}_${item.value}`}
                name={`facet_${field}_${item.value}`}
                value={item.value} 
                checked={false} 
                onChange={handleCheckboxChange} 
                label = {`${item.value} (${item.count})`}
            />
         </div>
        ))}
      </div>
    </div>
  )
}

export default Facet;
