import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ccConfig, CC } from '../CC/CC';
import { CCProvider, useCCContext } from '../CC/CCContext';
import { generateQuery } from '../CC/utils';
import styles from './AdvancedSearch.module.css';
import genericStyles from '../GenericComponents/GenericStyles.module.css';
import GenericCheckbox from '../GenericComponents/GenericCheckbox';
import GenericIconButton from '../GenericComponents/GenericIconButton';
import SearchBox from './SearchBox';
import * as ccUtils from '../CC/utils';

interface Item {
  word: string;
}

interface AdvancedSearchProps {
  prevCc: CC;
  setPrevCc: React.Dispatch<React.SetStateAction<CC>>;
  isOpen?: boolean;
  onClose?: () => void;
}

function AdvancedSearch({ prevCc, setPrevCc, isOpen = false, onClose = () => { } }: AdvancedSearchProps) {
  const { t } = useTranslation();
  const instanceRef = useRef({});
  const { cc, setCc, first, setFirst, recordsPerPage, setRecordsPerPage, viewMode, setViewMode, browseMode, setBrowseMode, data, setData } = useCCContext();
  const [searchCriteria, setSearchCriteria] = useState<{ [field: string]: string }>({});
  const [results, setResults] = useState<Item[]>([]);
  const [showingFields, setShowingFields] = useState<{ [field: string]: boolean }>({});
  const [activeFields, setActiveFields] = useState<{ [field: string]: boolean }>({});
  const [searchTriggered, setSearchTriggered] = useState(false);

  const handleCriteriaChange = async (field: string, value: string) => {
    setSearchCriteria({
      ...searchCriteria,
      [field]: value,
    });

    const advancedSearchField = ccConfig.advancedSearchFields[field].field;
    let lastValue = value.split('",').pop();
    let adaptedSearchValue = lastValue ? lastValue.trim() : '';
    const query = generateQuery(cc);

    const postData = {
      action: "get",
      command: "scanconcepts",
      fields: advancedSearchField,
      query: query,
      mask: "regexp(.*"+adaptedSearchValue+".*)",
      range: "1-10",
      responseformat: "json"
    };

    try {
      const data = await ccUtils.postWithCache(process.env.REACT_APP_BASE_URL as string, postData, instanceRef);

      if (data.word.word && Array.isArray(data.word.word)) {
        setResults(data.word.word);
        setShowingFields({
          ...showingFields,
          [field]: true,
        });
      } else {
        setResults([]);
        setShowingFields({
          ...showingFields,
          [field]: false,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setResults([]);
      setShowingFields({
        ...showingFields,
        [field]: false,
      });
    }
  };

  const handleMatchAllChange = (checked: boolean, value: string) => {
    //setMatchAll(checked);
    setCc({
      ...cc,
      matchAllCriteria: checked,
    });
  };

  const handleSearch = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    // Trigger all the SearchBox components to check their input values
    setSearchTriggered(true);

    // Reset the trigger
    setTimeout(() => {
      setSearchTriggered(false);
      setCc({
        ...cc
      });
      setFirst(1);
      onClose();
    }, 50);

  };

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setCc(prevCc);
    setPrevCc(new CC());
    onClose();
  };

  const handleSelectItem = (event: React.MouseEvent<HTMLDivElement>, word: string) => {
    event.preventDefault();
    const activeField = Object.keys(activeFields).find(field => activeFields[field]);
    if (activeField) {
      let previousSearchValue = (searchCriteria[activeField] || "").split(',').slice(0, -1).filter(value => value.trim() !== "").join(',').trim();

      if (previousSearchValue.length > 0) {
        previousSearchValue = previousSearchValue + ", ";
      }

      setSearchCriteria({
        ...searchCriteria,
        [activeField]: `${previousSearchValue}"${word}", `,
      });

      setShowingFields({
        ...showingFields,
        [activeField]: false,
      });

      setActiveFields({
        ...activeFields,
        [activeField]: false,
      });
    }
  };

  const renderResults = () => (
    <div className={styles.results}>
      {results.map((item: Item, index: number) => (
        <div
          key={index}
          onClick={(event) => handleSelectItem(event, item.word)}
          style={{
            padding: '10px',
            cursor: 'pointer',
            backgroundColor: '#fff'
          }}
        >
          {item.word}
        </div>
      ))}
    </div>
  );

  return (
    <div className={`${styles.AdvancedSearch}`}>
      {isOpen && (
        <>
          <div className={styles.popupoverlay} ></div>
          <div className={`${styles.popup} parentModalClass`}>
            <div className={styles.popupcontent}>

              <div className={styles.container}>
                <div className={styles.header}>
                  <span>{t("advanced_advanced_search")}</span>
                </div>
                <div className={styles.searchstyle}>
                  <div className={styles.row}>
                    <GenericCheckbox
                      id={'advancedSearchConformToAllCriteria'}
                      name={'advancedSearchConformToAllCriteria'}
                      value={'1'}
                      checked={cc.matchAllCriteria}
                      onChange={handleMatchAllChange}
                      label={t("advanced_all_criteria")}
                    />
                  </div>
                </div>
                <div className={styles.main}>
                  <div className={styles.simpleSearchBox}>
                    <SearchBox Fields="simple" id="simpleSearch" showSearchButton={false} searchTriggered={searchTriggered}/>
                  </div>
                  {
                    Object.entries(ccConfig.advancedSearchFields).map(([field, config], index) => (
                      <div key={field} className={styles.row}>
                        <div className={styles.fieldLabel}>{t(field)}</div>
                        <SearchBox Field={field} id={`advancedSearch${index}`} showSearchButton={false} searchTriggered={searchTriggered} />
                      </div>
                    ))
                  }
                </div>
                <div className={styles.footer}>
                  <button className={genericStyles.GenericButton} onClick={handleSearch}>
                    {t("advanced_button_search")}
                  </button>
                  <button className={genericStyles.GenericButton} onClick={handleCancel}>{t("advanced_button_cancel")}</button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default AdvancedSearch;
