import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CurPage.module.css';
import { CCProvider, useCCContext } from '../CC/CCContext';

function CurPage() {
  const { t } = useTranslation();
  const { cc, setCc, first, setFirst, recordsPerPage, setRecordsPerPage, viewMode, setViewMode, browseMode, setBrowseMode, data, setData } = useCCContext();
  const totalRecords = data.request?.count || 0;
  const page = Math.floor((first - 1) / recordsPerPage) + 1;
  const totalPages = Math.floor((totalRecords - 1) / recordsPerPage) + 1;
  const [isInput, setIsInput] = useState(false);
  const [inputPage, setInputPage] = useState(page);

  const updateFirstRecord = (newFirst: number) => {
    setFirst(newFirst); // Update first
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputPage(parseInt(e.target.value, 10));
  };

  const handleBlur = () => {
    if (inputPage >= 1 && inputPage <= totalPages) {
      updateFirstRecord((inputPage - 1) * recordsPerPage + 1);
    }
    setIsInput(false);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleBlur();
    }
  };

  useEffect(() => {
    // This effect will run whenever the value of 'first' changes
    const newPage = Math.floor((first - 1) / recordsPerPage) + 1;
    setInputPage(newPage);
  }, [first, recordsPerPage]);

  return (
    <div className={styles.CurPage}>
      {t("curpage_page")}&nbsp;
      {isInput ? (
        <input 
          type="number" 
          value={inputPage} 
          onChange={handleInputChange} 
          onBlur={handleBlur} 
          onKeyDown={handleKeyPress}
          style={{width: '50px'}}
          autoFocus 
        />
      ) : (
        <span className={styles.currentPage} onClick={() => {setIsInput(true); setInputPage(page);}}>{page.toLocaleString()}</span>
      )} 
      &nbsp;{t("curpage_of")} {totalPages.toLocaleString()}&nbsp;
    </div>
  );
}

export default CurPage;
